import React, {useEffect, useState} from 'react';
import {Form, InputGroup, Button} from 'react-bootstrap';
import Styles from "./StockTab.module.scss";
import TextField from "@mui/material/TextField";
import axios from "axios";
import appStore from "../../store/AppStore";
import Stack from '@mui/material/Stack';
import Skeleton from "@mui/material/Skeleton";
import Box from '@mui/material/Box';
import Autocomplete from "@mui/material/Autocomplete";
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import {FiSearch} from "react-icons/fi";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {REACT_APP_PRODUCT_SEARCH_LAMBDA_API} from "../../const/AppConst";

const collator = new Intl.Collator('kr-KR');

const COLUMNS = [
    { 
        field: 'item_id', 
        headerName: 'SKU 코드', 
        align:'center' 
    },
    {
      field: 'sku_nm',
      headerName: '상품명',
      align:'center',
    },
    {
      field: 'stock_amt',
      headerName: '재고',
      sortable: true,
      align:'center',
    },
    {
      field: 'price',
      headerName: '가격',
      sortable: true,
      align:'center',
    },
    {
      field: 'event_info',
      headerName: '행사 정보',
      sortable: true,
      align:'center',
    },
    {
      field: 'prdt_ven_nm',
      headerName: '제조사',
      sortable: true,
      align:'center',
    },
];

const StockTab = ({branchId, onClose, userId, ticketId, inflowChannelId}) => {
    const ROWS_PER_PAGE = 10;


    const [store, setStore] = useState({});
    const [keyword, setKeyword] = useState('');
    const [productList, setProductList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [order, setOrder] = useState('asc');
    const [isAsc, setIsAsc] = useState(true);
    const [orderBy, setOrderBy] = useState('item_id');

    useEffect(() => {
        const selectedStore = appStore.branches.find(branch => branch.id == branchId) || appStore.branches[0];

        setStore({
            value: selectedStore.id,
            label: `${selectedStore.brand.name} ${selectedStore.name}`
        });
        setKeyword('');
        setProductList([]);
        setTotalCount(0);
        setOrderBy('item_id');
    }, [branchId]);

    useEffect(() => {
        setPage(1);
        fetchProductList(1);
    }, [store]);

    const fetchProductList = (selectedPage) => {
        if (keyword?.length < 2) {
            return;
        }
        setLoading(true);
        setOrderBy('item_id');
        const offset = typeof selectedPage === 'number' ? (selectedPage - 1) * ROWS_PER_PAGE : 0;
        const biztp = store?.value?.startsWith('1') ? '1100' : '1200';
        axios.post(
            `/search/products/meta`,
            {
                store_id: store?.value,
                search_term: keyword,
                offset: offset,
                limit: ROWS_PER_PAGE,
                biztp: biztp,
            },
            {
                baseURL: REACT_APP_PRODUCT_SEARCH_LAMBDA_API,
                withCredentials: false
            }
        ).
        then(response => {
            const {data} = response;
            setProductList(data.data);
            setTotalCount(data.total);
            setLoading(false);
        }).
        catch(error => {
            setLoading(false);
            setError(true);
            setProductList([]);
            setTotalCount(0);
        })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchProductList(value);
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            setPage(1);
            fetchProductList(1);
        }
    }

    const handleRequestSort = (event) => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setIsAsc(!isAsc);
        setOrderBy(event);

        const sortedProductList = [...productList];
        sortedProductList.sort((a, b) => {
            let val1 = a, val2 = b;
            
            // array 
            if (event === 'event_info') {
                val1 = a[event][0];
                val2 = b[event][0];
                return isAsc ? collator.compare(val2, val1) : collator.compare(val1, val2);
            }
            
            // string 
            if (typeof val1[event] === 'string' && typeof val2[event] === 'string') {
                return isAsc ? collator.compare(val2[event], val1[event]) : collator.compare(val1[event], val2[event]);
            }

            // number
            return isAsc ? val1[event] - val2[event] : val2[event] - val1[event];
        });
        setProductList(sortedProductList);
    };

    function cleanseData(data) {
        return data?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ');
    }

    function formatCurrency(data) {
        const formattedPrice = data?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${(formattedPrice || 0)} 원`;
    }

    return (
        <div className={Styles.StockTabWrap}>
            <div className={Styles.Title}>
                점포별 재고 조회
            </div>
            <div className={`d-flex`}>
                <Autocomplete
                    className={`w-100`}
                    disablePortal
                    id="select-str-nm"
                    value={store}
                    onChange={(_, newValue) => {
                        setStore(newValue);
                    }}
                    noOptionsText={'검색 결과가 없습니다.'}
                    options={appStore.branchesOptions}
                    getOptionLabel={(option) => option?.label || ''}
                    renderInput={(params) => 
                    <TextField 
                        sx={{
                            "&.MuiTextField-root": {
                              "&:hover fieldset": {
                                borderColor: "#009688",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#009688",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "#009688",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#009688",
                              },
                            },
                        }}
                        {...params} size="small"/>
                    }
                />
            </div>

            <div className={`d-flex py-2`}>
                <InputGroup>
                    <Form.Control 
                        placeholder={'상품명 또는 SKU 코드를 입력해주세요.'}
                        value={keyword}
                        onChange={e=>{
                            setKeyword(e.target.value);
                        }}
                        onKeyDown={onKeyDown}
                    />
                    <Button variant={'outline-primary'} className={`d-flex justify-content-center align-items-center`}
                        onClick={fetchProductList}
                    >
                        <FiSearch/>
                    </Button>
                </InputGroup>
            </div>
            {
                loading ? (
                <Box
                    sx={{
                      marginTop: 3
                    }}
                  >
                    {[...Array(11)].map((_, idx) => (
                      <Skeleton key={idx} variant="rounded" height={50} sx={{ my: 1, mx: 1 }} animation="wave" />
                    ))}
                  </Box>
                ) : (
                    <>
                        <div style={{ height: '610px', width: '100%', margin: '1rem 0', minWidth: '400px' }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ width: '100%' }} aria-label="product-search-table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                COLUMNS.map(col => (
                                                    <TableCell 
                                                        sx={{ padding: '16px 0px' }}
                                                        key={col.field} 
                                                        align={col.align} 
                                                        sortDirection={orderBy === col.field ? order : false}>
                                                            <TableSortLabel
                                                                active={orderBy === col.field}
                                                                direction={isAsc ? 'asc' : 'desc'}
                                                                onClick={() => handleRequestSort(col.field)}
                                                                >
                                                                {col.headerName}
                                                                <Box component="span" sx={visuallyHidden}>
                                                                </Box>
                                                            </TableSortLabel>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productList?.map((row, idx) => (
                                            <TableRow
                                                key={row.item_id}
                                                sx={{ 
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: (idx % 2 == 0 && '#F8F9FA')
                                                }}
                                            >
                                                <TableCell component="th" scope="row" align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={<span className={Styles.Event}>{row.item_id}</span>} >
                                                        <span className={Styles.tableCellTrucate} style={{ maxWidth: '110px' }}>{row.item_id}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={<span className={Styles.Event}>{row.sku_nm}</span>} >
                                                        <span className={Styles.tableCellTrucate} style={{ maxWidth: '90px' }}>{row.sku_nm}</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={<span className={Styles.Event}>{row.stock_status || ''}</span>} >
                                                        <span className={Styles.tableCellTrucate} style={{ maxWidth: '100px' }}>
                                                            {row?.stock_amt >= 0 ? `${row?.stock_amt} ${row?.mark_unit_nm}` : "0 개"}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={<span className={Styles.Event}>{formatCurrency(row.price)}</span>} >
                                                        <span className={Styles.tableCellTrucate} style={{ maxWidth: '100px' }}>
                                                            {formatCurrency(row.price)}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={
                                                        <span className={`${Styles.Event}`}>
                                                            {
                                                                row.event_info?.map(info => {
                                                                    return (
                                                                        <div className={Styles.Event}>
                                                                            {cleanseData(info)}
                                                                            <div>--------------------------------------------</div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </span>}>
                                                        <div className={`${Styles.tableCellTrucate}`} style={{ maxWidth: '140px' }}>
                                                            {row?.event_info.length > 0 && cleanseData(row?.event_info[0])}
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '16px 1px' }}>
                                                    <Tooltip title={<span className={Styles.Event}>{row.prdt_ven_nm}</span>} >
                                                        <span className={Styles.tableCellTrucate} style={{ maxWidth: '80px' }}>
                                                            {row.prdt_ven_nm}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                productList?.length === 0 && 
                                (<div className={Styles.NoRowsWrap}>
                                    <div className={Styles.NoRows}>
                                        {
                                            error ? '상품 조회 중 오류가 발생했습니다.' : '조회 결과가 없습니다.'
                                        }
                                    </div>
                                </div>)
                            }
                            <Stack spacing={5} sx={{ justifyContent: 'center', padding: '1rem 1rem', display: 'flex' }}>
                                <Pagination 
                                    sx={{ 
                                        [`& .Mui-selected`]: { backgroundColor: '#009688 !important', color: '#fff !important' },
                                        justifyContent: 'center', display: 'flex'
                                    }} 
                                    size="small" 
                                    count={Math.ceil(totalCount / ROWS_PER_PAGE)} 
                                    page={page}
                                    variant="outlined" 
                                    shape="rounded"
                                    onChange={handlePageChange} />
                            </Stack>
                        </div>
                    </>
                )
            }
        </div>
    );
};

StockTab.propTypes = {};

export default StockTab;
