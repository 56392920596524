import React, { useEffect, useState } from 'react';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { navigate } from '../../helper/HistoryHelper';
import { DataGrid, DataGridStore, Header, Sort } from '@qbot-chat/qbot-uikit/grid';
import { Badge, Button, Col, Form, FormGroup, InputGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';

const store = new DataGridStore({
  fetchUrl: `/scenario/list`,
  selectFnFromResponse: (res) => ({ items: res.data.items, rowsCount: res.data.count }),
  sort: new Sort(`udpDate`, Sort.SORT_DIRECTION.DESC),
});

const ScenarioListView = () => {
  const [searchKeyword, setSearchKeyword] = useState(null);

  useEffect(() => {
    store.refresh();

    return () => {
      store.searchCondition.clear();
    };
  }, []);

  const search = () => {
    store.searchCondition.set('keyword', searchKeyword);
    store.fetch();
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      store.page = 1;
      search();
    }
  };

  return (
    <FrameWrap className='w-100 overflow-y-auto'>
      <TitlePanel>
        <FormGroup>
          <Col className='justify-content-between align-items-center d-flex'>
            <TitlePanelLabel label='시나리오 목록' />
            <div>
              <Button variant='outline-primary' onClick={() => navigate(`/main/scenario/new`)}>
                + 등록하기
              </Button>
            </div>
          </Col>
        </FormGroup>
      </TitlePanel>
      <Panel>
        <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
          <FormGroup>
            <Col className='d-flex flex-column'>
              <div className='d-flex'>
                <Col md={{ span: 12, offset: 0 }}>
                  <InputGroup>
                    <InputGroup.Text id='basic-addon1'>시나리오명, 시나리오ID</InputGroup.Text>
                    <Form.Control placeholder='시나리오명 또는 시나리오ID로 검색' onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={onKeyDown} />
                    <Button variant='outline-secondary' onClick={search}>
                      검색
                    </Button>
                  </InputGroup>
                </Col>
              </div>
            </Col>
          </FormGroup>
        </Panel>
      </Panel>
      <Panel className='flex-default'>
        <DataGrid
          store={store}
          keyColumn='sid'
          columns={[
            <Header
              id='sid'
              name='시나리오ID'
              width={{ min: 70, current: 80, max: 100 }}
              printFunction={(id) => (
                <Badge pill bg='secondary'>
                  {id}
                </Badge>
              )}
            />,
            <Header key='scenarioName' id='scenarioName' name='시나리오명' width={{ min: 150, current: 200, max: 400 }} />,
            <Header key='description' id='description' name='내용' width={{ min: 150, current: 200, max: 500 }} />,
            <Header key='useYn' id='useYn' name='실행 상태' width={{ min: 70, current: 80, max: 100 }} printFunction={(isUsed) => <Badge bg={isUsed === 'Y' ? 'success' : 'danger'}>{isUsed === 'Y' ? '활성화' : '비활성화'}</Badge>} />,
            <Header key='rootIntentGroup' id='rootIntentGroup' name='최상위 노드 인덴트명' width={{ min: 80, current: 150, max: 200 }} />,
            <Header id='udpDate' sortable={true} name='최종 수정일' className='justify-content-end' width={{ min: 100, current: 150, max: 220 }} type='date' />,
            <Header key='udpId' id='udpId' name='최종 수정자' width={{ min: 80, current: 100, max: 150 }} />,
            <Header id='regDate' sortable={true} name='최초 등록일' className='justify-content-end' width={{ min: 100, current: 150, max: 220 }} type='date' />,
            <Header key='regId' id='regId' name='최초 등록자 ' width={{ min: 80, current: 100, max: 150 }} />,
          ]}
          onRowClick={(rowData) => {
            navigate(`/main/scenario/detail/${rowData.sid}`);
          }}
        />
      </Panel>
    </FrameWrap>
  );
};

export default ScenarioListView;
