import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {
    Accordion,
    Badge,
    Button,
    Col,
    Dropdown,
    FormGroup,
    OverlayTrigger,
    Popover,
    Row,
    Spinner
} from "react-bootstrap";
import {FormStore} from "../../components/validation";
import axios from "axios";
import {goBack} from "../../helper/HistoryHelper";
import {Chat} from "@qbot-chat/qbot-uikit/chat";
import {ticketDetailChatStore, ticketStore} from "../../store/AdminChatStore";
import {makeObservable, observable} from "mobx";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import Styles from "./TicketManager.module.scss";
import {Copy, ProgrammingArrow, SmsTracking} from "iconsax-react";
import moment from "moment/moment";
import TextToolTip from "../../components/tootip/TextToolTip";
import {fromNow} from "../../util/Utils";
import TransferDialog from "../../components/dialog/TransferDialog";
import {CustomButton} from "@qbot-chat/qbot-uikit/component";
import SplitPane from "react-split-pane";
import TicketInfoView from "./TicketInfoView";
import CommonHelper from "../../helper/CommonHelper";
import {NotificationManager} from "react-notifications";
import {confirmDialogStore} from "@qbot-chat/qbot-uikit";
import {CSSTransition} from "react-transition-group";

const form = new FormStore();

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref}
         onClick={(e) => {
             e.preventDefault();
             onClick(e);
         }}
    >
            {children}
    </div>
));

const statusBadge = {
    PENDING: <Badge bg="info">대기</Badge>,
    ACTIVE : <Badge bg="success">상담중</Badge>,
    IDLE   : <Badge bg="warning">유휴</Badge>,
    CLOSED : <Badge bg="secondary">완료</Badge>,
};

const TicketInfoCard = (props) => {

    return <Accordion>
        <Accordion.Item eventKey={props.key}>
            <Accordion.Header>
                {props.header}
            </Accordion.Header>
            <Accordion.Body>
                {props.body}
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

@observer
class TicketManageDetailView extends Component {

    @observable
    customerMemos;

    @observable
    isFetching;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.id) {
            this.fetchDetail(params.id);
        } else {
            form.clear()
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if(params.id != prevParams.id){
            if (params && params.id ) {
                this.fetchDetail(params.id);
            }else if(!params.id){
                form.clear()
            }
        }
    }

    fetchDetail(id){
        axios.post(
            `/ticket/detail/${id}`,
            {}
        ).then(res=>{
            form.values = res.data;
            ticketDetailChatStore.setChannel(res.data);
            this.loadCustomerMemos(form.values?.customer.id);
        });
    }

    handleCopyClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText (text);
            NotificationManager.info('클립보드에 복사되었습니다.');
        } catch (e) {
            NotificationManager.info('복사에 실패하였습니다');
        }
    };

    loadCustomerMemos = (customerId) => {
        axios.post(`/customer/memos`, {userId: customerId})
            .then(({data: memoResponse}) => {
                this.customerMemos = memoResponse;
            });
    };

    delayFetch(id) {
        this.isFetching = true;
        setTimeout(() => {
            this.fetchDetail(id);
            this.isFetching = false;
        }, 500);
    }


    render() {
        let ticket = form.values;
        let assignmentLog = ticket.assignmentLog || []
        let agentAssignmentLog = assignmentLog.filter(log => log.agent.type !== 'AGENT_BOT')
        return (
            <div className={`d-flex flex-column h-100 ms-2 me-2`}>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={'티켓 상세정보'}  />
                            <div className={'d-flex gap-2'}>
                                {
                                    ticket.status !== 'CLOSED' &&
                                    <>
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle}>
                                                <Button variant={"outline-primary"}>
                                                    티켓 이관
                                                </Button>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <CustomButton className={Styles.DropDownButton}
                                                              onClick={()=> ticketDetailChatStore.openAgentTransfer(() => this.delayFetch(ticket.id))}
                                                >
                                                    <SmsTracking size={24}/>티켓 이관 - 상담사
                                                </CustomButton>
                                                <CustomButton className={Styles.DropDownButton}
                                                              onClick={()=> ticketDetailChatStore.openTeamTransfer(() => this.delayFetch(ticket.id))}
                                                >
                                                    <ProgrammingArrow size={24}/>티켓 이관 - 팀
                                                </CustomButton>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Button variant={"outline-danger"}
                                                onClick={()=>{
                                                    confirmDialogStore.open({
                                                        title:'종료',
                                                        summary:'상담을 종료하시겠습니까?',
                                                        onOk:()=>{
                                                            ticketStore.close(ticket.id)
                                                                .then(() =>  this.delayFetch(ticket.id))
                                                        }
                                                    })
                                                }}
                                        >
                                            티켓 종료
                                        </Button>
                                    </>
                                }
                                <Button variant={"outline-secondary"}
                                        onClick={()=>{
                                            goBack();
                                        }}
                                >
                                    돌아가기
                                </Button>

                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>

                <div className={Styles.Container}>
                    <SplitPane  split="vertical" primary="second" allowResize={true}>
                        <SplitPane initialSize="550px" minSize={"150px"}>
                            <div className={Styles.ChatContainer}>
                                <div className={`d-flex flex-column`} style={{flex:'1 1 0', minHeight:0}}>
                                    <div className={`overflow-hidden`} style={{minHeight:0, flex:'1 1 0'}}>
                                        <Chat store={ticketDetailChatStore} readOnly={true}
                                              ref={ticketDetailChatStore.chatRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SplitPane>
                        <SplitPane initialSize="70%" minSize={"550px"} allowResize={true}>
                            <TicketInfoView>
                                <TicketInfoCard
                                    header={"티켓 정보"}
                                    body={<div className={'d-flex flex-column gap-2'}>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>티켓상태 :</div>
                                            <div className={Styles.InfoContent}>{statusBadge[ticket.status]}</div>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>티켓ID :</div>
                                            <div className={Styles.InfoContent}>{ticket.id}</div>
                                            <button className={Styles.CopyButton} onClick={() => this.handleCopyClipBoard(ticket.id)} >
                                                <Copy size="16" color="#6C757D"/>
                                            </button>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>발행시간 :</div>
                                            <div className={Styles.InfoContent}>{moment(ticket.createdAt).format('YYYY-MM-DD A hh:mm')}</div>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>상담원 :</div>
                                            <div className={Styles.InfoContent}>{agentAssignmentLog[agentAssignmentLog.length-1]?.team?.name}-{agentAssignmentLog[agentAssignmentLog.length-1]?.agent?.name}</div>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>최종 상담원 :</div>
                                            <div className={Styles.InfoContent}>{agentAssignmentLog[0]?.team?.name}-{agentAssignmentLog[0]?.agent?.name}</div>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>상담종료시간 :</div>
                                            <div className={Styles.InfoContent}>{moment(ticket.closedAt).format('YYYY-MM-DD A hh:mm')}</div>
                                        </div>
                                    </div>}
                                />
                                <TicketInfoCard
                                    header={"고객 정보"}
                                    body={<div className={'d-flex flex-column gap-2'}>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>고객ID :</div>
                                            <div className={Styles.InfoContent}>{ticket?.customer?.key}</div>
                                            <button className={Styles.CopyButton} onClick={() => this.handleCopyClipBoard(ticket?.customer?.key)} >
                                                <Copy size="16" color="#6C757D"/>
                                            </button>
                                        </div>
                                        <div className={Styles.InfoItem}>
                                            <div className={Styles.InfoTitle}>고객명 :</div>
                                            <div className={Styles.InfoContent}>{ticket?.customer?.name}</div>
                                            <button className={Styles.CopyButton} onClick={() => this.handleCopyClipBoard(ticket?.customer?.name)} >
                                                <Copy size="16" color="#6C757D"/>
                                            </button>
                                        </div>
                                        {
                                            this.customerMemos && this.customerMemos.length > 0 &&
                                            <div className={'d-flex flex-column overflow-y-scroll'} style={{height: '5rem'}}>
                                                {
                                                    this.customerMemos?.map(({id, notes, createdAt, creator}) =>
                                                            <span key={id} className={Styles.Memo}>
                                                    <div>
                                                        <TextToolTip text={notes} lines={3}/>
                                                        <span className={Styles.MemoInfo}>
                                                            <span style={{color: '#2C8DFF'}} className={`pe-2`}>{fromNow(createdAt)}</span>
                                                            <span style={{color: '#17A2B8'}}>{creator?.name}</span>
                                                        </span>
                                                    </div>
                                                </span>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>}
                                />
                                <TicketInfoCard
                                    header={"티켓 필드"}
                                    body={<div className={'d-flex flex-column gap-2'}>
                                        {
                                            ticket.properties &&
                                            Object.entries(ticket.properties).map(([k, v]) => {
                                                return <div className={Styles.InfoItem}>
                                                    <div className={Styles.InfoTitle}>{k} :</div>
                                                    <div className={Styles.InfoContent}>{
                                                        CommonHelper.isIsoDate(v) ?
                                                            moment(v).format('YYYY-MM-DD A hh:mm') : v
                                                    }</div>
                                                </div>
                                            })
                                        }
                                    </div>}
                                />
                                <TicketInfoCard
                                    header={"배정이력"}
                                    body={<div className={Styles.Table}>
                                        <Row className={Styles.Thead}>
                                            <Col md={4} className={Styles.Th}>상담사</Col>
                                            <Col md={4} className={Styles.Th}>팀</Col>
                                            <Col md={4} className={Styles.Th}>시간</Col>
                                        </Row>
                                        <div className={Styles.Tbody}>
                                            {
                                                assignmentLog?.map(log => {
                                                    return <Row className={Styles.Tr}>
                                                        <Col md={4} >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(props)=>
                                                                    <Popover {...props}>
                                                                        <Popover.Body>
                                                                            {log.agent.name}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <div className={Styles.Td}>{log.agent.name}</div>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={4} >
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(props)=>
                                                                    <Popover {...props}>
                                                                        <Popover.Body>
                                                                            {log.team.name}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <div className={Styles.Td}>{log.team.name}</div>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={4}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(props)=>
                                                                    <Popover {...props}>
                                                                        <Popover.Body>
                                                                            {moment(log.assignedAt).format('YYYY-MM-DD A hh:mm')}
                                                                        </Popover.Body>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <div className={Styles.Td}>{moment(log.assignedAt).format('YYYY-MM-DD A hh:mm')}</div>
                                                            </OverlayTrigger>
                                                        </Col>
                                                    </Row>
                                                })
                                            }
                                        </div>
                                    </div>}
                                />
                            </TicketInfoView>
                        </SplitPane>
                    </SplitPane>

                </div>
                <TransferDialog ticket={ticketDetailChatStore.channel}/>
                <CSSTransition
                    in={this.isFetching}
                    timeout={500}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className={Styles.BackDrop}>
                        <Spinner animation="border" size="sm" variant="success"/>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default TicketManageDetailView;