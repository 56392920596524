import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Badge, Button, Col, Form, FormGroup, FormLabel, InputGroup, Row} from 'react-bootstrap';
import {FormStore, Input, Validation} from "../../components/validation";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {goBack, navigateReplace} from "../../helper/HistoryHelper";
import Panel from "../../components/layout/Panel";
import ContentAddButton from "../../components/message_template/ContentAddButton";
import {chatStore} from "../../store/AdminChatStore";
import MessageTemplateEditor from "../../components/message_template/MessageTemplateEditor";
import {arrayMoveImmutable} from 'array-move';
import {BUTTON_TYPE, CONTENT_TYPE, Message, MessageContent} from "@qbot-chat/qbot-uikit/chat";
import {botMessageTemplateDataGridStore} from "./BotMessageTemplateManageListView";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import {v4 as uuid} from 'uuid';

const form = new FormStore();

@observer
class BotMessageTemplateManageDetailView extends Component {

    constructor(props) {
        super(props);
    }

    reset(){
        form.clear({
            'content.isBubble' : true,
            isActive: true
        });
        this.addContent({type:CONTENT_TYPE.TEXT, value:''})
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.id) {
            this.fetchDetail(params.id);
        } else {
            this.reset();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if(params.id != prevParams.id){
            if (params && params.id ) {
                this.fetchDetail(params.id);
            }else if(!params.id){
                this.reset();
            }
        }
    }

    fetchDetail(id){
        axios.post(
            `/bot/message/template/detail/${id}`,
            {}
        ).then(res=>{
            form.values = res.data;
        });
    }

    save(){
        if(!form.doCheckValid()){
            NotificationManager.warning(form.firstErrorMessage)
            return;
        }
        let messageTemplate = form.values;
        messageTemplate.content.type = CONTENT_TYPE.CUSTOM;
        let values = messageTemplate?.content?.value;
        if(values.filter(value=>value.isInValid === false).length > 0){
            return;
        }
        if(!!this.props.match.params.id){
            axios.post(
                `/bot/message/template/update`,
                {
                    ...messageTemplate,
                }
            ).then(res=>{
                let user = res.data;
                botMessageTemplateDataGridStore.fetch()
                NotificationManager.success('템플릿이 저장되었습니다.')
            })
        }else{
            axios.post(
                `/bot/message/template/insert`,
                {
                    ...messageTemplate,
                }
            ).then(res=>{
                let id = res.data;
                NotificationManager.success('템플릿이 등록되었습니다.')
                botMessageTemplateDataGridStore.fetch()
                navigateReplace(`/main/bot/message/template/detail/${id}`)
            })
        }
    }

    addContent(content){
        let value = form.valueMap.get('content.value')||[];
        value.splice(value.length, 0, content);
        form.setValue('content.value', [...value])
    }

    render() {
        let isNew = !form.valueMap.get('id');
        let messageTemplate = form.values;
        let {content} = messageTemplate;
        let values = content?.value || []
        let isBubble = content?.isBubble === undefined ? true : content?.isBubble
        return (
            <div className={'h-100 d-flex flex-column'}>
                <Form className={`d-flex flex-column d-flex h-100`}>
                    <TitlePanel>
                        <FormGroup>
                            <Col className={`justify-content-between align-items-center d-flex`}>
                                {isNew?
                                <TitlePanelLabel label={'챗봇 메시지 등록'} />
                                    :
                                    <TitlePanelLabel label={'챗봇 메시지 수정'} />
                                }
                                <div>
                                    <Button variant={"outline-secondary"}
                                            size={`md`}
                                            className={`ms-2`}
                                            onClick={()=>{
                                                goBack();
                                            }}
                                    >
                                        취소
                                    </Button>
                                    <Button variant={"outline-primary"}
                                            size={`md`}
                                            className={`ms-2`}
                                            disabled={values.filter(value=>value.isInvalid === true).length > 0}
                                            onClick={()=>{
                                                this.save();
                                            }}
                                    > {isNew? '등록' : '수정'}
                                    </Button>

                                </div>
                            </Col>
                        </FormGroup>
                    </TitlePanel>
                    <Panel>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={1} className={`d-flex align-items-center`}>
                                <FormLabel>제목</FormLabel>
                            </Col>
                            <Col>
                                <Input store={form} name={`title`}
                                       validations={[
                                           new Validation(/^.+$/, '제목을 입력해주세요.')
                                       ]}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={1} className={`d-flex align-items-center`}>
                                <FormLabel>인텐트ID</FormLabel>
                            </Col>
                            <Col>
                                <Input store={form} name={`intentId`}
                                       validations={[
                                           new Validation(/^.+$/, '인텐트ID를 입력해주세요.')
                                       ]}
                                />
                            </Col>
                            <Col md={1}></Col>
                            <Col md={1} className={`d-flex align-items-center`}>
                                <FormLabel>브랜드ID</FormLabel>
                            </Col>
                            <Col>
                                <Input store={form} name={`brandId`}
                                       validations={[
                                           new Validation(/^.+$/, '브랜드ID를 입력해주세요.')
                                       ]}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={1} className={`d-flex align-items-center`}>
                                <FormLabel>부모 버블</FormLabel>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    onChange={e=>{
                                        form.setValue('content.isBubble', e.target.checked)
                                    }}
                                    checked={isBubble}
                                />
                            </Col>
                            <Col md={1}></Col>
                            <Col md={1} className={`d-flex align-items-center`}>
                                <FormLabel>사용</FormLabel>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    onChange={e=>{
                                        form.setValue('isActive', e.target.checked)
                                    }}
                                    checked={form.valueMap.get('isActive')}
                                />
                            </Col>
                        </FormGroup>
                    </Panel>
                    <FormGroup as={Row} className={`flex-grow-1 ms-2 me-2 mb-2`}>
                        <Col md={2}>
                            <Panel className={'p-0'} style={{borderRadius:'.5rem', border:'1px solid #E8E8E8'}}>
                                <ContentAddButton title={`텍스트`}
                                                  onClick={()=>{
                                                      this.addContent({type:CONTENT_TYPE.TEXT, value:'', isInvalid:true});
                                                  }}
                                />
                                <ContentAddButton title={`이미지`}
                                                  onClick={()=>{
                                                      this.addContent({type:CONTENT_TYPE.IMAGE, value:{} , isInvalid:true});
                                                  }}
                                />
                                <ContentAddButton title={`오디오`}
                                                  onClick={()=>{
                                                      this.addContent({type:CONTENT_TYPE.AUDIO, value:{}, isInvalid:true });
                                                  }}
                                />
                                <ContentAddButton title={`비디오`}
                                                  onClick={()=>{
                                                      this.addContent({type:CONTENT_TYPE.VIDEO, value:{}, isInvalid:true});
                                                  }}
                                />
                                <ContentAddButton title={`파일`}
                                                  onClick={()=>{
                                                      this.addContent({type:CONTENT_TYPE.FILE, value:{}, isInvalid:true});
                                                  }}
                                />
                                <ContentAddButton title={`버튼그룹`}
                                                  onClick={()=>{
                                                      this.addContent(
                                                          {
                                                              type:CONTENT_TYPE.BUTTON,
                                                              value: {
                                                                  type : BUTTON_TYPE.NORMAL,
                                                                  buttons: [
                                                                      {
                                                                          label: `라벨`,
                                                                          type: BUTTON_TYPE.OUTER_LINK,
                                                                          value: ``
                                                                      },
                                                                  ]
                                                              }
                                                          }
                                                      );
                                                  }}
                                />
                                <ContentAddButton title={`구분선`}
                                                  onClick={()=>{
                                                      this.addContent(
                                                          {
                                                              type: CONTENT_TYPE.LINE,
                                                              value: {
                                                                  thickness: 5,
                                                                  colorCode: `#000000`,
                                                              }
                                                          }
                                                      )
                                                  }}
                                />
                            </Panel>
                        </Col>
                        <Col md={5}>
                            <Panel className={'flex-default h-100 p-0 overflow-hidden'} style={{borderRadius:'.5rem', border:'1px solid #E8E8E8'}}>
                                <div className={`d-flex flex-column align-items-center h-100`}>
                                    <div className={`d-flex flex-column w-100`} style={{flex:'1 1 0', minHeight:0}}>
                                        <div className={`overflow-auto`} >
                                            {
                                                content &&
                                                <MessageTemplateEditor content={content}
                                                                       onChangeItem={(content, index)=>{
                                                                           let value = form.valueMap.get('content.value');
                                                                           value.splice(index, 1, content)
                                                                           form.setValue('content.value', [...value])
                                                                       }}
                                                                       onSortEnd={({oldIndex,newIndex})=>{
                                                                           let value = form.valueMap.get('content.value');
                                                                           form.setValue('content.value', arrayMoveImmutable(value, oldIndex, newIndex))
                                                                       }}
                                                                       onDeleteItem={(idx)=>{
                                                                           let value = form.valueMap.get('content.value');
                                                                           value.splice(idx, 1)
                                                                           form.setValue('content.value', [...value])
                                                                       }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </Col>
                        <Col md={5}>
                            <Panel className={'flex-default h-100'} style={{borderRadius:'.5rem', border:'1px solid #E8E8E8'}}>
                                <div className={`d-flex flex-column align-items-center h-100`}>
                                    <div className={`d-flex flex-column w-100`} style={{flex:'1 1 0', minHeight:0}}>
                                        <div className={`p-2`}>
                                            <span className={`fw-bold`}>미리보기</span>
                                        </div>
                                        <div className={`overflow-auto p-2`}>
                                            <Message >
                                                <MessageContent content={{...content, type:CONTENT_TYPE.CUSTOM}}
                                                                store={chatStore}
                                                                messageKey={uuid()}
                                                                isIncoming={true}
                                                />
                                            </Message>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </Col>
                    </FormGroup>

                </Form>
            </div>
        );
    }
}

export default BotMessageTemplateManageDetailView;