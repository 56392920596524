import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button, FormControl, Modal, Nav, Tab, Image} from 'react-bootstrap';
import {FormStore} from '../validation';
import axios from 'axios';
import Styles from './UploadDialog.module.scss';
import {action, computed, makeObservable, observable} from 'mobx';
import {CommonHelper} from '@qbot-chat/qbot-uikit';
import {getPreviewUrl} from '../../util/Utils';
import {NotificationManager} from "react-notifications";


const form = new FormStore();

class UploadStore {
    @observable
    show = false;

    @observable
    active = false;

    callback;

    @observable
    image = {};

    @observable
    useUrl = true;

    @observable
    fileName = '';

    @observable
    useFile = true;

    constructor() {
        makeObservable(this);
    }

    @computed
    get existImage() {
        return this.image?.url;
    }

    @observable
    clearPath = () => {
        this.image = {};
    };

    modalOpen = (callback, useUrl, useFile) => {
        this.show = true;
        this.callback = callback;
        this.useUrl = useUrl;
        this.useFile = useFile;
    };

    modalClose = () => {
        this.show = false;
        this.image = {};
        this.fileName = '';
        this.active = false;
    };

    @action.bound
    handleDraftImage(url) {
        this.image = {
            type: 'IMAGE',
            url,
            fullUrl : getPreviewUrl(url)
        };
    }

    @action.bound
    handleUrlImage(url) {
        this.image = {
            type: 'URL',
            url,
            fullUrl:url
        };
    }

    @action.bound
    clearImage() {
        this.image = {};
    }
}

const uploadStore = new UploadStore();
window.uploadStroe = uploadStore;

@observer
class UploadDialog extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {
        let {store} = this.props;
        return (
            <Modal
                show={store.show}
                onHide={store.modalClose}
                backdrop={true}
                keyboard={false}
            >
                <Modal.Body>
                    <div className={Styles.Title}>
                        이미지 업로드
                    </div>

                    <div className={`d-flex flex-column justify-center w-100 mb-3 gap-2 align-items-center`}>
                        <div className={Styles.ImagePreview}>
                            {
                                store.existImage ?
                                    <Image className={Styles.PreviewAvatar}
                                         src={store.image?.fullUrl}
                                    />
                                    :
                                    <div className={Styles.PreviewAvatar}>
                                        이미지를 등록해 주세요.
                                    </div>
                            }
                        </div>
                        <Tab.Container defaultActiveKey={store.useFile ? 'file' : 'url'}>
                            <Nav fill variant="tabs" className={`w-100`}>
                                {
                                    store.useUrl ?
                                        <Nav.Item>
                                            <Nav.Link eventKey={'url'}
                                                      onClick={() => {
                                                          form.clear();
                                                          store.clearImage();
                                                      }}
                                            >
                                                이미지경로
                                            </Nav.Link>
                                        </Nav.Item>
                                        : ''

                                }
                                {
                                    store.useFile ?
                                        <Nav.Item>
                                            <Nav.Link eventKey={'file'}
                                                      onClick={() => {
                                                          form.clear();
                                                          store.clearImage();
                                                          store.fileName = '';
                                                      }}
                                            >
                                                내컴퓨터
                                            </Nav.Link>
                                        </Nav.Item>
                                        : ''
                                }
                            </Nav>

                            <Tab.Content className={`w-100`}>
                                <Tab.Pane eventKey="url"
                                          title="인터넷경로"
                                >
                                    <div className={Styles.UrlUpload}>
                                        <FormControl
                                            as={'input'}
                                            readOnly={false}
                                            placeholder={'이미지 주소를 붙여넣어 주세요.'}
                                            disabled={false}
                                            value={store.image?.url}
                                            onChange={(e) => {
                                                if(e.target.value) {
                                                    if (e.target.value.length > 249) {
                                                        NotificationManager.warning('주소가 너무 길어요.');
                                                        store.clearImage();
                                                    } else if (!CommonHelper.checkOuterUrl(e.target.value)) {
                                                        NotificationManager.warning('주소가 올바르지 않습니다.');
                                                        store.clearImage();
                                                    } else {
                                                        store.handleUrlImage(e.target.value);
                                                    }
                                                }

                                            }}
                                            className={`w-100 d-flex`}
                                        />
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="file"
                                          title="내컴퓨터"
                                >
                                    <div className={Styles.FileUpload}>
                                        <input
                                            type={'file'}
                                            label={`innerUrl`}
                                            name={`avatar`}
                                            placeholder={``}
                                            accept={"image/*"}
                                            disabled={false}
                                            className={'d-none'}
                                            ref={(ref) => {
                                                this.fileInput = ref;
                                            }}
                                            onChange={(e) => {
                                                if(e.target.files.length <= 0 )
                                                    return;
                                                store.fileName = e.target.files[0]?.name;
                                                let formData = new FormData();

                                                Array.from(e.target.files).forEach((file, idx) =>
                                                    formData.append('file', file)
                                                );
                                                axios.post(`/upload/draft`,
                                                    formData,
                                                    {
                                                        headers         : {
                                                            'Content-Type': 'multipart/form-data'
                                                        },
                                                        onUploadProgress: (e) => {
                                                            console.log(e);
                                                        }
                                                    }).then(r => {
                                                        if(r.data?.url)
                                                            store.handleDraftImage(r.data?.url);
                                                });

                                            }}
                                        />
                                        <FormControl
                                            as={'input'}
                                            readOnly={true}
                                            placeholder={''}
                                            disabled={true}
                                            value={store.fileName}
                                            onChange={(e) => {
                                                store.fileName = e.target.value;
                                            }}
                                        />
                                        <Button className={Styles.ButtonWhiteSpace} variant={'outline-primary'}
                                                onClick={() =>
                                                    this.fileInput.click()
                                                }>

                                            파일 선택

                                        </Button>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                    </div>

                    <div className={Styles.Footer}>
                        <Button variant="outline-secondary"
                                onClick={() => {
                                    store.modalClose();
                                }}
                        >
                            취소
                        </Button>
                        <Button variant="outline-primary"
                                onClick={() => {
                                    store.callback && store.callback(store.image);
                                    store.modalClose();
                                }}
                                disabled={!store.existImage}
                        >확인</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export {UploadDialog as default, uploadStore};
