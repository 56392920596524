import React, {Component} from 'react';
import {observer} from "mobx-react";
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import Panel from "../../components/layout/Panel";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import RandomColor from "../../components/avatar/RandomColor";
import {Image} from 'react-bootstrap'
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import {computed, makeObservable, observable, values} from "mobx";
import dayjs from "dayjs";
import CommonHelper from "../../helper/CommonHelper";
import InfoReport from "./InfoReport";
import Styles from "./Report.module.scss";
import Avatar from "../../components/avatar/Avatar";

const agentReportDataGridStore = new DataGridStore({
    fetchUrl: `/report/agent/list`,
    selectFnFromResponse: (res) => ({items: res.data.items, rowsCount: res.data.count}),
    sort: new Sort(`agent`, Sort.SORT_DIRECTION.ASC),
    noHeader: true
})

@observer
class AgentReport extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @observable
    datas = [];

    @observable
    hourDatas = [];

    @observable
    agentMap = new Map();

    @computed
    get agents(){
        return values(this.agentMap)
    }

    @computed
    get dayCategories(){
        return this.datas?.map(data => dayjs(data.createdAt).format('MM월 DD일'));
    }

    timeCategories(){
        let arr = [];
        for(let i = 0; i <= 12; i++){
            arr.push(`오전:${i}:00`);
        }
        for(let i = 1; i < 12; i++){
            arr.push(`오후:${i}:00`);
        }
        return arr;
    }

    @computed
    get closeTicket() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "종료된 티켓",
                    data: this.datas?.map(data => data.closeTicketCount)
                }
            ]
        }
    }

    @computed
    get assignTicket() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "배정받은 횟수",
                    data: this.datas?.map(data => data.assignmentTicketCount)
                }
            ]
        }
    }

    @computed
    get avgResponse() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                yaxis: {
                    labels: {
                        formatter: CommonHelper.timeFormat
                    },
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "평균 최초 응답 시간",
                    data: this.datas?.map(data => data.avgResponseTime)
                }
            ]
        }
    }

    @computed
    get hoursCloseMsg() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.timeCategories()
                }
            },
            series: [
                {
                    name: "평균 티켓 수",
                    data: this.hourDatas.map(data => data.closeTicketCount)
                }
            ]
        }
    }

    @computed
    get ratingCount() {

        return {
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                    toolbar: {
                        show: true,
                    }
                },
                labels: ['5점','4점','3점','2점','1점'],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: "총 응답 수"
                                }
                            }
                        }
                    }
                }
            },
            series: [
                this.datas?.map(data => data.ratingFivePointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingFourPointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingThreePointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingTwoPointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingOnePointCount).reduce((x,y) => x+y, 0)
            ]
        }
    }


    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {startDate, endDate, selectedAgent} = this.props;
        if(prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevProps.selectedAgent !== selectedAgent) {
            this.fetch();
        }
    }

    fetch = () => {
        const {startDate, endDate, selectedAgent} = this.props;
        agentReportDataGridStore.page = 1;
        agentReportDataGridStore.searchCondition.clear();
        agentReportDataGridStore.searchCondition.set("startDate", startDate);
        agentReportDataGridStore.searchCondition.set("endDate", endDate);
        agentReportDataGridStore.searchCondition.set("agentId", selectedAgent ? selectedAgent.id : null);
        agentReportDataGridStore.fetch();
        this.agentReport();
    }

    agentReport = () => {
        const {startDate, endDate, selectedAgent} = this.props;
        axios.post(
            `/report/agent`,
            {
                startDate,
                endDate,
                agentId: selectedAgent ? selectedAgent.id : null
            }
        ).then(res=>{
            this.datas = res.data.agentReports;
            this.hourDatas = res.data.agentHourReports;
        });
    }

    avatar = (agent) => {
        return <>
            {
                agent.avatar ?
                    <Image className={'rounded-circle'} style={{width: "2rem"}} src={agent.avatar} />
                    :
                    <div style={{width: "2rem"}}>
                        <RandomColor  seed={agent.id}>
                            <DefaultAvatar className={'rounded-circle'}/>
                        </RandomColor>
                    </div>
            }
            <div className={'ms-1'}> {agent.name} </div>
        </>;
    }

    viewReturn = () => {
        const {viewMode} = this.props;
        return {
            table : <Panel className={'flex-grow-1'}>
                <DataGrid store={agentReportDataGridStore}
                          keyColumn={`id`}
                          columns={[
                              <Header id={`agent`}  name={`이름`} printFunction={agent => {
                                  return (
                                      <div className={'w-100 d-flex flex-row gap-2'}>
                                          <Avatar type={'agent'} seed={agent.id} width={'1.5rem'} src={agent.avatar?.fullUrl}/>
                                          <span>{agent.name}</span>
                                      </div>
                                  )
                              }} sortable />,
                              <Header id={`assignmentTicketCount`} width={{min: 130, current: 130}} name={`배정받은 횟수`} sortable
                                      info={`상담원이 티켓을 배정받은 횟수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받으면 횟수는 누적하여 계산됩니다.`}/>,
                              <Header id={`receiveTicketCount`} width={{min: 110, current: 110}} name={`받은 티켓`} sortable
                                      info={`상담원이 한 번이라도 배정받았던 총 티켓 수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받아도 1개의 티켓으로 계산됩니다.`}/>,
                              <Header id={`closeTicketCount`} width={{min: 120, current: 120}} name={`종료된 티켓`} sortable
                                      info={`상담원이 종료한 총 티켓 수입니다.`}/>,
                              <Header id={`avgRating`} width={{min: 150, current: 150}} name={`평균 고객 만족도`} printFunction={avgRating =>
                                  avgRating || "-" } sortable info={`상담원이 받은 평균 고객 만족도 점수입니다.`}/>,
                              <Header id={`avgResponseTime`} width={{min: 160, current: 160}} name={`평균 최초 응답 시간`} printFunction={avgResponseTime =>
                                  avgResponseTime ? CommonHelper.timeFormat(avgResponseTime) : "-"} sortable info={`최초 응답 시간의 총합을 최초 응답을 받은 메시지 수로 나눈 수입니다.`}/>
                          ]}
                />
            </Panel>,
            graph : <div className={Styles.AgentContainer}>
                <div className={Styles.Panel}>
                    <InfoReport title={"종료된 티켓"} summary={"모든 혹은 선택한 상담원이 종료한 총 티켓 수입니다."} />
                    <ReactApexChart
                        options={this.closeTicket.options}
                        series={this.closeTicket.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"배정받은 횟수"} summary={"모든 혹은 선택한 상담원이 티켓을 배정받은 횟수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받으면 횟수는 누적하여 계산됩니다."} />
                    <ReactApexChart
                        options={this.assignTicket.options}
                        series={this.assignTicket.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"평균 최초 응답 시간"} summary={"배정 시간 부터 최초 응답 시간 까지의 평균 시간 입니다."} />
                    <ReactApexChart
                        options={this.avgResponse.options}
                        series={this.avgResponse.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"시간당 종료한 티켓"} summary={"모든 혹은 선택한 상담원이 시간당 종료한 평균 티켓 수입니다."} />
                    <ReactApexChart
                        options={this.hoursCloseMsg.options}
                        series={this.hoursCloseMsg.series}
                        type={"bar"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"고객 만족도"} summary={"모든 혹은 선택한 상담원이 받은 고객 만족도 정보입니다."} />
                    <div className={'d-flex justify-center'}>
                        <ReactApexChart
                            options={this.ratingCount.options}
                            series={this.ratingCount.series}
                            type={"donut"}
                            width={380}
                        />
                    </div>
                </div>
            </div>
        }[viewMode]
    }
    render() {
        return (
            <div className={'h-100 d-flex flex-column'}>
                {this.viewReturn()}
            </div>
        );
    }
}
export {AgentReport as default, agentReportDataGridStore};