import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Panel from "../../components/layout/Panel";
import { ConfirmDialog, confirmDialogStore } from "@qbot-chat/qbot-uikit";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import { makeObservable, observable } from "mobx";
import FrameWrap from "../../components/layout/FrameWrap";
import appStore from "../../store/AppStore";
import { PDS_CONTEXT_PATH } from "../../const/AppConst";
import { Checkbox, Select, TimePicker } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import {
  timeFormat,
  EXEC,
  CALL,
  USEYN,
  TRANSFERYN,
  OPERATING_ARR,
  OPERATING_ARR_KOR,
} from "./ObSettingDefaultValue";
import Styles from "./ObSettingView.module.scss";

const axiosInstancePDS = axios.create({
  baseURL: `${PDS_CONTEXT_PATH}`,
  withCredentials: true,
});

@observer
class ObSettingView extends Component {
  @observable
  isFetching = false;

  @observable
  existingData = {};

  @observable
  isDisabled = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.state = {
      settingData: {},
      outbound_exec: false,
      outbound_call: false,
      outbound_operating_useYn: false,
      outbound_transferYn: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    this.isFetching = true;
    await axiosInstancePDS
      .get(`/setting/list/${appStore.companyId}`)
      .then((res) => {
        let result = res.data?.data;
        if (!result[EXEC]) {
          result = {
            outbound_exec: {
              fieldMainKey: "outbound_exec",
              value: "false",
              valueExtra1: null,
              valueExtra2: null,
            },
          };
        } else {
          this.isDisabled = true;
        }
        this.existingData = { ...result };
        this.setState({
          settingData: { ...result },
        });
      })
      .catch((e) => {
        NotificationManager.error("데이터를 불러올 수 없습니다.");
        console.log("ObSettingView/fetch(): ", e);
      })
      .finally(() => (this.isFetching = false));
  }

  valueChanger(key, data) {
    this.isFetching = true;
    const newSettingState = { ...this.state };
    // 취소/저장 여부 state 변경
    const checkKey = OPERATING_ARR.find((date) => date === key);
    if (checkKey) {
      newSettingState[USEYN] = true;
    } else {
      newSettingState[key] = true;
    }
    // 설정 value 데이터 변경
    newSettingState.settingData[key].value = data;
    this.setState({
      ...newSettingState,
    });
    this.isFetching = false;
  }

  timeUpdater(key, idx, time) {
    let setTime = "";
    if (!time) {
      setTime = "00:00";
    } else {
      setTime = dayjs(time).format(timeFormat);
      const newSettingState = { ...this.state.settingData };
      if (idx === 1) {
        newSettingState[key].valueExtra1 = setTime;
      } else {
        newSettingState[key].valueExtra2 = setTime;
      }
      this.setState({
        settingData: { ...newSettingState },
        outbound_operating_useYn: true,
      });
    }
  }

  cancel(key) {
    this.isFetching = true;
    const returnState = { ...this.state };
    const checkKey = OPERATING_ARR.find((date) => date === key);
    if (checkKey || key === USEYN) {
      returnState[USEYN] = false;
      returnState.settingData[USEYN] = { ...this.existingData[USEYN] };
      OPERATING_ARR.map((day) => {
        returnState.settingData[day] = { ...this.existingData[day] };
      });
    } else {
      returnState[key] = false;
      returnState.settingData[key] = { ...this.existingData[key] };
    }
    this.setState({
      ...returnState,
    });
    this.isFetching = false;
  }

  save(key) {
    const dataConfig = [this.state.settingData[key]];
    if (key === USEYN) {
      OPERATING_ARR.map((day) => {
        const timeState = { ...this.state.settingData[day] };
        dataConfig.push(timeState);
      });
    }
    const resetState = { ...this.state };
    resetState[key] = false;
    axiosInstancePDS
      .post(`/setting/saveSetting/${appStore.companyId}`, dataConfig)
      .then((res) => {
        this.setState({
          ...resetState,
          settingData: {},
        });
        NotificationManager.success("변경된 설정이 저장되었습니다.");
      })
      .catch((e) => {
        NotificationManager.error("데이터 저장에 실패하였습니다.");
        console.log("ObSettingView/save(): ", e);
      })
      .finally(() => this.fetch());
  }

  render() {
    const {
      settingData,
      outbound_exec,
      outbound_call,
      outbound_operating_useYn,
      outbound_transferYn,
    } = this.state;
    return (
      <FrameWrap className={`d-flex flex-column flex-default overflow-y-auto`}>
        <TitlePanel>
          <FormGroup>
            <Col className={`my-2`}>
              <TitlePanelLabel label={"아웃바운드 설정"} />
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className="pt-4">
                <div className="mx-4 pb-4 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4 pb-3">
                    <FormLabel className="fw-bold">실행 상태</FormLabel>
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <Form.Check
                        type="switch"
                        checked={
                          settingData[EXEC]?.value === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.valueChanger(EXEC, `${e.target.checked}`)
                        }
                      />
                    </div>
                  </div>
                  <Collapse in={outbound_exec}>
                    <div className={Styles.button_wrapper}>
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.cancel(EXEC)}
                      >
                        취소
                      </Button>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => this.save(EXEC)}
                      >
                        저장
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-4 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="fw-bold">유형 구분</FormLabel>
                    <div className="d-flex justify-content-between align-items-center w-75">
                      <span>콜 유형</span>
                      <div className="w-75">
                        <Select
                          style={{ width: "100%", height: 38 }}
                          size="large"
                          value={
                            settingData[CALL]?.value === "02" ? "멀티" : "싱글"
                          }
                          options={[
                            { value: "01", label: "싱글" },
                            { value: "02", label: "멀티" },
                          ]}
                          onChange={(e) => this.valueChanger(CALL, e)}
                          disabled={!this.isDisabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-3 mx-4 pb-4">
                    <div className="d-flex justify-content-between align-items-center w-75 gap-5">
                      <span>동시 콜 수</span>
                      <div className="w-75">
                        <Form.Control
                          value={
                            settingData[CALL]?.valueExtra1 === "1"
                              ? ""
                              : settingData[CALL]?.valueExtra1
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <Collapse in={outbound_call}>
                    <div className={Styles.button_wrapper}>
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.cancel(CALL)}
                      >
                        취소
                      </Button>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => this.save(CALL)}
                      >
                        저장
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-4 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4">
                    <FormLabel className="pb-5 fw-bold">운영 시간</FormLabel>
                    <div className="d-flex justify-content-between align-items-center pb-5 w-75 gap-5 border-bottom">
                      <Form.Check
                        type="switch"
                        checked={
                          settingData[USEYN]?.value === "true" ? true : false
                        }
                        onChange={(e) =>
                          this.valueChanger(USEYN, `${e.target.checked}`)
                        }
                        disabled={!this.isDisabled}
                      />
                    </div>
                  </div>
                  <div className="py-4">
                    {!this.isFetching &&
                      OPERATING_ARR.map((day, idx) => {
                        const startTime = settingData[day]?.valueExtra1;
                        const endTime = settingData[day]?.valueExtra2;
                        return (
                          <div
                            className="d-flex justify-content-end mt-3 mx-4"
                            key={idx}
                          >
                            <div className="d-flex justify-content-between align-items-center w-75">
                              <div className="d-flex align-items-center gap-2">
                                <Checkbox
                                  checked={
                                    settingData[day]?.value === "true"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    this.valueChanger(
                                      day,
                                      `${e.target.checked}`
                                    )
                                  }
                                  disabled={
                                    settingData[USEYN]?.value === "true"
                                      ? false
                                      : true
                                  }
                                />
                                <span>{OPERATING_ARR_KOR[idx]}요일</span>
                              </div>
                              <div className="d-flex align-items-center w-75 gap-3">
                                <TimePicker
                                  className="w-50"
                                  minuteStep={5}
                                  defaultValue={dayjs(startTime, timeFormat)}
                                  value={dayjs(startTime, timeFormat)}
                                  disabled={
                                    settingData[USEYN]?.value === "true"
                                      ? settingData[day]?.value === "true"
                                        ? false
                                        : true
                                      : true
                                  }
                                  style={{ height: 38 }}
                                  size="large"
                                  format={timeFormat}
                                  onChange={(time) =>
                                    this.timeUpdater(day, 1, time)
                                  }
                                />
                                <TimePicker
                                  className="w-50"
                                  minuteStep={5}
                                  defaultValue={dayjs(endTime, timeFormat)}
                                  value={dayjs(endTime, timeFormat)}
                                  disabled={
                                    settingData[USEYN]?.value === "true"
                                      ? settingData[day]?.value === "true"
                                        ? false
                                        : true
                                      : true
                                  }
                                  style={{ height: 38 }}
                                  size="large"
                                  format={timeFormat}
                                  onChange={(time) =>
                                    this.timeUpdater(day, 2, time)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <Collapse in={outbound_operating_useYn}>
                    <div className={Styles.button_wrapper}>
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.cancel(USEYN)}
                      >
                        취소
                      </Button>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => this.save(USEYN)}
                      >
                        저장
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </FormGroup>
            <Form.Group as={Row}>
              <Col className="pt-5">
                <div className="mx-4 pb-3 border-bottom">
                  <div className="d-flex justify-content-between align-items-center mx-4 pb-4">
                    <FormLabel className="fw-bold">이관 여부</FormLabel>
                    <div className="w-75">
                      <Form.Check
                        type="switch"
                        checked={
                          settingData[TRANSFERYN]?.value === "true"
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          this.valueChanger(TRANSFERYN, `${e.target.checked}`)
                        }
                        disabled={!this.isDisabled}
                      />
                    </div>
                  </div>
                  <Collapse in={outbound_transferYn}>
                    <div className={Styles.button_wrapper}>
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.cancel(TRANSFERYN)}
                      >
                        취소
                      </Button>
                      <Button
                        variant={"outline-primary"}
                        onClick={() => this.save(TRANSFERYN)}
                      >
                        저장
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </Form.Group>
          </Form>
        </Panel>
        <ConfirmDialog />
      </FrameWrap>
    );
  }
}

export default ObSettingView;
