import React, { Component } from 'react';
import '@exytab/react-sortable-tree/dist/styles.css';
import SortableTree, {toggleExpandedForAll, map} from '@exytab/react-sortable-tree';
import Styles from './Tree.module.scss';
import {observer} from "mobx-react";
import {toJS} from 'mobx';
import {Button} from "react-bootstrap";

@observer
class Tree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            treeData:[]
        }

    }

    componentDidMount() {
        let {treeData} = this.props;
        this.setState({treeData});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    updateTreeData(treeData){
        this.setState({treeData:treeData})
    }

    render() {
        let {renderNode, onClick} = this.props
        let store = this;
        let treeData = this.state.treeData;
        return (
            <div className={Styles.Container}>
                <SortableTree
                    scaffoldBlockPxWidth ={24}
                    rowHeight ={32}
                    canDrag={false}
                    treeData={treeData}
                    onChange={treeData => {
                        this.setState({treeData})
                    }}
                    generateNodeProps={({node})=>(
                        {
                            title:
                                <div style={{cursor:'pointer'}}
                                     onClick={()=>{
                                         if(node.children && node.children.length > 0){
                                             this.setState({treeData:map(
                                                     {
                                                         treeData,
                                                         getNodeKey:({node, treeIndex})=>treeIndex,
                                                         callback:({node:callbackNode})=> {
                                                             console.log(node, callbackNode)
                                                             return ({
                                                                 ...callbackNode,
                                                                 expanded: node.id == callbackNode.id ? !node.expanded : callbackNode.expanded
                                                             })
                                                         },

                                                         ignoreCollapsed:false
                                                     }
                                                 )})
                                         }


                                         onClick && onClick(node)
                                     }}
                                >
                                    {renderNode(node)}
                                </div>
                    })}
                    onVisibilityToggle={({treeData, node, expanded})=>{
                        console.log(treeData, node, expanded)
                    }}
                />

            </div>
        );
    }
}



export default Tree;
