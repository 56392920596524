import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import ModalBase from '../modal/ModalBase';

class AlertDialogStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    show = false;

    @observable
    title = '';

    @observable
    content = '';

    @observable
    onExit = undefined;

    @action.bound
    clear() {
        this.title = '';
        this.content = '';
        this.onExit = undefined;
    }

    @action.bound
    open(title, content = '', onExit) {
        this.clear();
        this.title = title;
        this.content = content;
        this.show = true;
        this.onExit = onExit;
    }

    @action.bound
    close() {
        this.show = false;
        this.onExit && this.onExit();
    }
}

const alertDialogStore = new AlertDialogStore();

@observer
class AlertDialog extends Component {

    render() {
        return (
            <ModalBase 
                centered
                show={alertDialogStore.show}
                onHide={()=>alertDialogStore.close()}
                backdrop
            >
                <Modal.Header closeButton>
                    <Modal.Title as={'h6'}>
                        {alertDialogStore.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">
                        {alertDialogStore.content}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={() => {alertDialogStore.close();}}>
                        확인
                    </Button>
                </Modal.Footer>
            </ModalBase>
        );
    }
}

export {AlertDialog, alertDialogStore};