import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {identity} from 'lodash';
import {styles} from './SelectBox';
import {FormStore, Validation} from '../validation';
import {observer} from 'mobx-react';

@observer
class MultiFormSelect extends React.Component {


    constructor(props, context) {
        super(props, context);
        let {store, name, value, validations} = this.props;
        store.validationMap.set(name, validations);
        if (!store.valueMap.get(name))
            store.valueMap.set(name, value || '');

    }

    render() {
        const {name, store, options, getTransform, setTransform, validations, placeholder = '선택 ...', onBlur, validOnBlur, readOnly, isLoading = false, onFocus, validOnFocus, isDisabled} = this.props;
        const value = (store.valueMap.get(name) || []).map(getTransform);

        return (
            <div>
                <Select isMulti
                        ref={ref => store.refs.set(name, ref)}
                        options={options}
                        placeholder={placeholder}
                        onChange={(data) => {
                            store.valueMap.set(name, (data || []).map(setTransform));
                        }}
                        styles={styles}
                        value={value}
                        onBlur={e => {
                            if (validOnBlur)
                                store.hasTried.set(name, true);
                            onBlur && onBlur(e);
                        }}
                        onFocus={e => {
                            if (validOnFocus)
                                store.hasTried.set(name, true);
                            onFocus && onFocus(e);
                        }}
                        isDisabled={isDisabled}
                />
                {
                    store.hasTried.get(name) && store.getErrorMessages(name)[0] &&
                    <div className={`position-absolute`} style={{color: 'red'}}>
                        * {store.getErrorMessages(name)[0]}
                    </div>
                }
            </div>
        );
    }
}

MultiFormSelect.propTypes = {
    options     : PropTypes.arrayOf(PropTypes.shape({value: PropTypes.string, label: PropTypes.any})).isRequired,
    store       : PropTypes.instanceOf(FormStore).isRequired,
    name        : PropTypes.string.isRequired,
    getTransform: PropTypes.func,
    setTransform: PropTypes.func,
    validations : PropTypes.arrayOf(PropTypes.instanceOf(Validation)),
    validOnBlur : PropTypes.bool,
    isDisabled  : PropTypes.bool,
};

MultiFormSelect.defaultProps = {
    getTransform: identity,
    setTransform: identity
};

export default MultiFormSelect;