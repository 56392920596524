import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import {navigate} from "../../helper/HistoryHelper";
import {Badge, Button, Col, Form, FormGroup, InputGroup} from "react-bootstrap";
import Panel from "../../components/layout/Panel";
import TitlePanel from "../../components/layout/TitlePanel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import FrameWrap from "../../components/layout/FrameWrap";
import Avatar from "../../components/avatar/Avatar";
import TeamSelect from "../../components/select/TeamSelect";
import {makeObservable, observable} from "mobx";
import {FaStar} from "react-icons/fa6";
import {ACCESS_RIGHTS} from "../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";
import appStore from "../../store/AppStore";

const agentDataGridStore = new DataGridStore({
    fetchUrl: `/agent/list`,
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    sort: new Sort(`createdAt`, Sort.SORT_DIRECTION.DESC)
})

const agentStatusBadge = {
    ACTIVE : <Badge bg="success">온라인</Badge>,
    OFFLINE : <Badge bg="danger">오프라인</Badge>,
    AWAY : <Badge bg="warning">자리비움</Badge>,
}

@observer
class AgentManageListView extends Component {

    @observable
    selectedTeam = null;

    @observable
    searchKeyword = null;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        if(!ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type)){
            this.selectedTeam = appStore.team;
        }
        agentDataGridStore.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pathname &&
            prevProps.pathname.startsWith('/main/agent/detail') &&
            this.props.pathname === '/main/agent/list') {
            agentDataGridStore.fetch();
        }
    }

    componentWillUnmount() {
        agentDataGridStore.searchCondition.clear();
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            agentDataGridStore.page = 1;
            this.search();
        }
    }

    search = () => {

        let obj = Object.fromEntries(agentDataGridStore.searchCondition);
        agentDataGridStore.searchCondition.set("team", this.selectedTeam);
        agentDataGridStore.searchCondition.set("keyword", this.searchKeyword);

        if(!_.isEqual(obj, Object.fromEntries(agentDataGridStore.searchCondition))){
            agentDataGridStore.refresh();
        }else{
            agentDataGridStore.fetch();
        }
    }

    render() {
        return (
            <FrameWrap>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={'상담사'} />
                            <div>
                                <Button variant={"outline-primary"}
                                        onClick={()=>{
                                            navigate(`/main/agent/new`)
                                        }}
                                >
                                   + 상담사 추가
                                </Button>
                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel>
                    <Panel className={`rounded-3`} style={{border:'1px solid #E8E8E8'}}>
                        <FormGroup>
                            <Col className={`d-flex flex-column`}>
                                <div className={'d-flex'}>
                                    <Col md={3}>
                                        {
                                            ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) ?
                                                <TeamSelect
                                                    placeholder="팀 선택"
                                                    onChange={(e) => {
                                                        if(e && e.object) {
                                                            this.selectedTeam = e.object;
                                                        }else{
                                                            this.selectedTeam = null;
                                                        }
                                                        this.search();
                                                    }}
                                                />
                                                :
                                                <div className={'d-flex flex-row'}>
                                                    <Avatar src={appStore.team.avatar.fullUrl} seed={appStore.team.id} type={'team'} width={'2rem'}/>
                                                    <div className={'ms-2 align-self-center'}> {appStore.team.name} </div>
                                                </div>
                                        }
                                    </Col>
                                    <Col md={{span:8, offset:1}}>
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1">이름, 로그인ID</InputGroup.Text>
                                            <Form.Control
                                                placeholder="이름 또는 로그인ID으로 검색"
                                                value={this.searchKeyword}
                                                onChange={(e) => {
                                                    this.searchKeyword = e.target.value;
                                                }}
                                                onKeyDown={(e) => this.onKeyDown(e)}
                                            />
                                            <Button variant={"outline-secondary"}
                                                    onClick={()=> this.search()}
                                            >
                                                검색
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </div>
                            </Col>
                        </FormGroup>
                    </Panel>
                </Panel>
                <Panel className={'flex-default'}>
                    <DataGrid store={agentDataGridStore}
                              keyColumn={`id`}
                              columns={[
                                  <Header id={`index`} name={`번호`} width={{min: 40, current: 50, max: 60}}/>,

                                  <Header id={`loginId`} name={`로그인아이디`}
                                          width={{min: 100, current: 125, max: 275}}
                                  />,
                                  <Header id={`name`} name={`이름`}
                                          width={{min: 36, current: 160, max: 200}}
                                          printFunction={(name, agent)=>{
                                          return (
                                              <div className={'w-100 d-flex flex-row gap-2'}>
                                                  <Avatar type={'agent'} seed={agent.id} width={'1.5rem'} src={agent?.avatar?.fullUrl}/>
                                                  <span>{name}</span>
                                              </div>
                                          )

                                          }}
                                  />,
                                  <Header id={`isLeader`} name={`유형`}
                                          width={{min: 70, current: 70, max: 100}}
                                          printFunction={isLeader=>(
                                              isLeader?
                                                  (<div className={'d-flex align-items-center gap-1'}><FaStar color={'var(--bs-warning)'} size={'1rem'}/>팀장</div>)
                                                  :
                                                  (<div className={'d-flex align-items-center gap-1'}><FaStar color={'var(--bs-gray-200)'} size={'1rem'}/>팀원</div>)
                                              )}
                                  />,
                                  <Header id={`status`} sortable={true} name={`상태`}
                                          width={{min: 50, current: 80, max: 100}}
                                          printFunction={status =>
                                            agentStatusBadge[status]
                                          }
                                  />,

                                  <Header id="createdAt" sortable={true} name={`생성 일시`}
                                          className={`justify-content-end`}
                                          width={{min: 100, current: 220, max: 250}} type="date"/>,

                                  <Header id={`updater`} name={`수정자`}
                                          width={{min: 36, current: 160, max: 200}}
                                          printFunction={updater=>{
                                              return (
                                                  updater?.id || '' ?
                                                    <div className={'w-100 d-flex flex-row gap-2'}>
                                                        <Avatar type={'agent'} seed={updater?.id} width={'1.5rem'} src={updater?.avatar?.fullUrl}/>
                                                        <span>{updater?.name}</span>
                                                    </div>
                                                    : '-'
                                              )
                                          }}/>,
                              ]}
                              onRowClick={rowData=>{
                                  navigate(`/main/agent/detail/${rowData.id}`)
                              }}
                    />
                </Panel>
            </FrameWrap>
        );
    }
}

export { AgentManageListView as default, agentDataGridStore};
