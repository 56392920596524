import React, {useRef, useState} from 'react';
import {Overlay, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import Styles from './TextToolTip.module.scss'
import LinesEllipsis from 'react-lines-ellipsis'
import PropTypes from 'prop-types';
import {MdClear} from "react-icons/md";

const TextToolTip = ({text, lines}) => {

    const [focus, setFocus] = useState(false);

    const ref = useRef(false);

    const onFocus = () => {
        if (ref.current.isClamped())
            setFocus(true);
    };

    const onBlur = e => {
        setFocus(false);
    };
    return (
        <div className={`d-flex flex-grow-1 w-100`} style={{flex:1}}>
            <OverlayTrigger
                key={`bottom`}
                placement={`auto`}
                trigger={['hover','focus']}
                show={focus}
                overlay={(props)=>
                    <Popover {...props}
                        container={this}
                    >
                        <Popover.Body className={`p-1`}>
                            <div className={`d-flex flex-column`}>
                                <div className={`d-flex justify-content-end align-items-center`} style={{cursor:'pointer'}}><span onClick={()=>onBlur()}><MdClear size={20}/></span></div>
                                <div style={{overflowX:'hidden', maxHeight:500, whiteSpace: 'pre-wrap', wordBreak:'word-all'}} className={`overflow-y-auto`}>
                                    {text}
                                </div>
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <span className={Styles.TextEllipse}>
                    <LinesEllipsis
                        text={text}
                        maxLine={lines}
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                        ref={ref}
                    />
                    {
                        ref.current.clamped &&
                            <span onClick={()=>onFocus()} style={{cursor:'pointer', color:'var(--bs-primary)'}}>전체보기</span>
                    }
                </span>
            </OverlayTrigger>
        </div>

    );
};

TextToolTip.propTypes = {
    lines: PropTypes.number,
};

TextToolTip.defaultProps = {
    lines: 1
}

export default TextToolTip;
