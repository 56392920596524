import React from 'react';
import {observer} from "mobx-react";
import {User} from "iconsax-react";
import {ProgressBar} from "react-bootstrap";
import Styles from './Stats.module.scss'
import {rootStyles, STATS_COLOR} from "../../const/AppConst";

@observer
class CountStats extends React.Component{

    color = {
        warning : Styles.WarningColor,
        primary : Styles.PrimaryColor,
        success : Styles.SuccessColor,
        danger : Styles.DangerColor,
        info : Styles.InfoColor
    }
    render() {
        let {total = 0, active = 0, away = 0, offline = 0, children, variant = 'primary', icon, isReverse = false} = this.props;
        return(
            <div className={`${Styles.CountStatus} ${this.color[variant]} ${isReverse?Styles.Reverse:''}`}>
                <div className={Styles.Icon}>
                    {icon}
                </div>
                <div>
                    {children}
                </div>
            </div>
        )
    }
}

export default CountStats;
