import React, {Component} from 'react';
import {action, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Container, Form, Modal, Row, Spinner} from 'react-bootstrap';
import Styles from './TransferDialog.module.scss';
import axios from 'axios';
import appStore from '../../store/AppStore';
import {QUICK_REPLY_DIVISION} from '../../const/AppConst';
import Required from '../../components/layout/Required';
import {FormStore, Input, Validation} from '../../components/validation';
import RadioGroup from '../../components/input/RadioGroup';
import ModalBase from "../../components/modal/ModalBase";
import {NotificationManager} from "react-notifications";

class StoreMemoStore {

    @observable
    show = false;

    callback = undefined;

    @observable
    store = undefined;

    @observable
    isSubmitting = false;

    formStore = new FormStore({
        division: QUICK_REPLY_DIVISION.PERSONAL
    });

    constructor() {
        makeObservable(this);
    }

    @action.bound
    open(store, callback) {
        if (store?.memo) {
            this.formStore.clear(store.memo);
        } else {
            this.formStore.clear({});
        }
        this.store = store;
        this.show = true;
        this.callback = callback;

    }

    @action.bound
    close() {
        this.store = undefined;
        this.onSave = undefined;
        this.show = false;
    }

    handleSave = async () => {
        if (!this.formStore.doCheckValid()) {
            return;
        }

        this.isSubmitting = true;

        const param = {
            ...this.store,
            memo: {...this.formStore.values},
        };

        try {
            let result = await axios.post(
                '/branch/store/memo/add',
                param,
            );
            NotificationManager.info('메모를 저장했습니다.');
            this.callback?.({
                ...this.store,
                memo:result.data
            });
            this.close();

        } catch (e) {
            console.log(e);
            NotificationManager.error('메모를 저장하는데 실패하였습니다. 잠시 후 다시 시도해 주세요.');
        } finally {
            this.isSubmitting = false;
        }
    };

    handleDelete = async () => {
        const param = {
            ...this.store,
            memo: {...this.formStore.values},
        };

        try {
            await axios.post(
                '/branch/store/memo/delete',
                param,
            );
            NotificationManager.info('메모를 삭제했습니다.');
            this.callback?.({
                ...this.store,
                memo:null
            });
            this.close();

        } catch (e) {
            console.log(e);
            NotificationManager.error('메모를 삭제하는데 실패하였습니다. 잠시 후 다시 시도해 주세요.');
        } finally {
            this.isSubmitting = false;
        }
    }
}

const storeMemoStore = new StoreMemoStore();


@observer
class StoreMemoDialog extends Component {


    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    handleSave() {
        storeMemoStore.handleSave().then();
    }

    @action.bound
    handleDelete() {
        storeMemoStore.handleDelete().then();
    }

    render() {
        const formStore = storeMemoStore.formStore;
        const id = formStore.valueMap.get('id');
        return (
            <ModalBase
                   size={'lg'}
                   show={storeMemoStore.show}
                   onHide={storeMemoStore.close}
                   keyboard={false}
            >
                <Modal.Header closeButton>
                    <div className={Styles.Header}>메모</div>
                </Modal.Header>
                <Modal.Body className={Styles.Body}>
                    <Container>
                        <Form.Group as={Row} className="mb-2">
                            <Col md={12}>
                                <Form.Label>내용</Form.Label>
                                <Required/>
                            </Col>
                            <Col md={12}>
                                <Input as="textarea" rows={10} size="sm" store={formStore} name="notes" validOnBlur validations={[new Validation(value=>value, '내용을 입력해 주세요.')]}/>
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer className={`d-flex justify-content-between align-items-center`}>
                    <div>
                        {
                            id &&
                            <Button variant="outline-danger" onClick={this.handleDelete}>삭제</Button>
                        }
                    </div>
                    <div>
                        <Button variant="outline-secondary" className={`me-2`} onClick={storeMemoStore.close}>취소</Button>
                        <Button onClick={this.handleSave} variant="primary" disabled={storeMemoStore.isSubmitting} style={{color:'#fff'}}>
                            {
                                storeMemoStore.isSubmitting ?
                                    <Spinner animation="border" variant="secondary" size={'sm'}/>
                                    :
                                    <span>저장</span>
                            }
                        </Button>
                    </div>
                </Modal.Footer>
            </ModalBase>
        );
    }
}

export {StoreMemoDialog as default, storeMemoStore};
