import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Badge, Button, Card, Col, Form, Image, InputGroup, Row} from 'react-bootstrap';
import appStore from '../../store/AppStore';
import Select from "react-select";
import Styles from "./StoreTab.module.scss";
import axios from "axios";
import {DataGrid, DataGridStore, Header} from "@qbot-chat/qbot-uikit/grid";
import moment from "moment";
import {FiSearch} from "react-icons/fi";
import {AiOutlineSend} from "react-icons/ai";
import {MdEditNote} from "react-icons/md";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContentLoader from "react-content-loader";
import {storeMemoStore} from "./StoreMemoDialog";

const storeFloorDataGridStore = new DataGridStore({
    fetchUrl      : '/branch/floor/info',
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    noHeader      : true,
    noPaging      : true,
    rowsPerPage   : 10,
    paginationSize: 10,
});

const Loader = () =>
    <ContentLoader
        speed={2}
        width={'100%'}
        height={100}
        backgroundColor="#FFFfff"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="3" ry="3" width="80" height="12"/>
        <rect x="83" y="0" rx="3" ry="3" width="30" height="12"/>
        <rect x="116" y="0" rx="3" ry="3" width="50" height="12"/>
        <rect x="0" y="20" rx="3" ry="3" width="200" height="12"/>
        <rect x="210" y="20" rx="3" ry="3" width="100" height="12"/>
        <rect x="0" y="37" rx="3" ry="3" width="120" height="12"/>
        <rect x="130" y="37" rx="3" ry="3" width="100" height="12"/>
        <rect x="0" y="58" rx="3" ry="3" width="80" height="12"/>
        <rect x="0" y="75" rx="3" ry="3" width="100%" height="30"/>
    </ContentLoader>;

const StoreCard = ({branch}) => {
    const branchHolidays = branch?.branchHolidays?.reduce((acc, holidays) => {
        const {holiday_day1, holiday_day2, holiday_day3, holiday_month, holiday_year} = holidays;
        const days = [holiday_day1, holiday_day2, holiday_day3].filter(v => !!v).sort((a, b) => parseInt(a) - parseInt(b)).map(day =>
            moment(`${holiday_year}${holiday_month}${day}`).format('MM/DD(dd)')
        );
        return acc.concat(days);
    }, [])?.join(',');
    return (
        <div className={Styles.StoreCard}>
            <div className={Styles.Name}>
                <Image src={`/asset/img/emart-icon.svg`}/> {branch.name}
            </div>
            <div className={Styles.Address}>
                {branch?.address1} / T.{branch?.tel}
            </div>
            <div className={Styles.Info}>
                영업시간 {branch.shopping_time} / <span className={Styles.Holiday}>{branchHolidays ? branchHolidays : '-'}</span>
            </div>
        </div>
    )
}

const StoreMemoCard = ({store, currentTextareaChange, storeMemoMap, setStoreMemoMap, ticket}) => {
    const days = [store?.holiday_day1, store?.holiday_day2].filter(v => !!v).sort((a, b) => parseInt(a) - parseInt(b)).map(day =>
        moment(`${moment().format('YYYY')}${store?.holiday_month}${day}`).format('MM.DD(dd)')
    );
    const holiday = days.join(', ');
    return (
        <div className={Styles.StoreMemoCard}>
            <div className={Styles.Name}>
                {
                    store.type == 'store' ?
                        <div>
                            <Image src={`/asset/img/emart-icon.svg`}/> {store?.store_name}
                        </div>
                        :
                        <div className={`d-flex align-items-center`}>
                            <span className={Styles.LocationBadge}>
                                <span>{store?.store_name}·{store?.store_id}</span>
                            </span>
                                {
                                    store?.floor &&
                                    <Badge bg={"primary"} className={`me-1`}>{store?.floor}층</Badge>
                                }
                                <span>
                                {store?.name}
                            </span>
                        </div>
                }
                <div>
                    <Button variant={"primary"} size={'sm'} className={`p-1 d-flex justify-content-center align-items-center`}
                            onClick={()=>{
                                if(store.type == 'store')
                                    axios.post(
                                        '/desk/send/card/store',
                                        {
                                            ticket,
                                            storeInfo: {
                                                name: store?.store_name,
                                                floor: store?.address,
                                                tel: store?.tel,
                                                business_hours: store?.business_hours
                                            }
                                        }
                                    ).then( () => {
                                        console.log('메시지 전송.');
                                    });
                                else {
                                    axios.post(
                                        '/desk/send/card/store',
                                        {ticket, storeInfo: store}
                                    ).then( () => {
                                        console.log('메시지 전송.');
                                    });
                                }
                            }}
                    >
                        <AiOutlineSend size={`1.2rem`} color={'#fff'}/>
                    </Button>
                </div>
            </div>
            <div className={Styles.Address}>
                {store?.address || '-'} / T.{store?.tel || '-'}
            </div>
            <div className={Styles.Info}>
                영업시간 {store?.business_hours || '-'} / <span className={Styles.Holiday}>휴점일 {holiday || '-'}</span>
            </div>
            <div className={Styles.MemoTitle}>
                <Button variant={"primary"} size={'sm'} style={{color:'#fff'}} className={`d-flex justify-content-center align-items-center`}
                        onClick={()=>{
                            storeMemoStore.open(store, (storeMemo)=>{
                                setStoreMemoMap(prev => new Map(prev).set(storeMemo.store_seq, storeMemo))
                            })
                        }}
                >
                    <MdEditNote size={'1.1rem'}/>&nbsp;메모
                </Button>
            </div>
            {
                store?.memo && store?.memo?.id &&
                <div className={Styles.Memo}>
                    {store?.memo?.notes}
                    <div className={Styles.Created}>
                        {store?.memo?.created_at && moment.utc(store?.memo?.created_at).local().format('YYYY.MM.DD HH:mm:ss')}
                        <span className={`ps-1 pe-1`} style={{color:'#17A2B8'}}>{store?.memo?.user_name}</span>
                        {store?.memo?.org_name}
                    </div>
                </div>
            }
        </div>
    )
}

const StoreTab = ({branchId, currentTextareaChange, ticket}) => {
    const storeRef = useRef();
    const storeInfoRef = useRef();

    const [store1, setStore1] = useState({});
    const [store2, setStore2] = useState({});
    const [scrollRef, setScrollRef] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [storeId, setStoreId] = useState(null);
    const [branch, setBranch] = useState(null);
    const [storeMemoMap, setStoreMemoMap] = useState(new Map());
    const [page, setPage] = useState(1);
    const [rowsCount, setRowsCount] = useState(undefined);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(()=>{

        const selectedStore = appStore.branches.find(branch=> branch.id == branchId) || appStore.branches[0];
        const initOption = {
            value: selectedStore.id,
            label: `${selectedStore.brand.name} ${selectedStore.name}`
        }
        setStore1(initOption);
        setStore2(initOption);
        setStoreId(branchId)
        // storeRef?.current?.setValue({value:selectedStore?.id, label : `${selectedStore?.type} ${selectedStore?.name}`})
        // storeInfoRef?.current?.setValue({value:selectedStore?.id, label : `${selectedStore?.type} ${selectedStore?.name}`})
        if(branchId){
            axios.post(`/branch/info/${branchId}`)
                .then(({data: branchInfoResponse}) => {
                    setBranch(branchInfoResponse);
                });
            storeFloorDataGridStore.searchCondition.set('id', branchId)
            storeFloorDataGridStore.refresh();
            fetchStoreMemo(true, branchId);
        }
    }, [branchId])

    const fetchStoreMemo = (reset = true, store) =>{
        if(!reset)
            setPage(page+1);
        else{
            if(scrollRef)
                scrollRef.scrollTop = 0;
            setPage(1);
        }
        setIsFetching(true);
        axios.post('/branch/search/memo', {
            keyword,
            storeId: store || '0000',
            pagingOption : {
                page,
                rowsPerPage : 10
            }
        })
        .then((response) => {
            let {data: {items, rowsCount}} = response;
            if(reset){
                setStoreMemoMap(new Map(items.map(store=>([store.store_seq, store]))));
            }else{
                setStoreMemoMap(new Map(storeMemos.map(store=>([store.store_seq, store])).concat(items.map(store=>([store.store_seq, store])))));
            }
            setRowsCount(rowsCount);
        })
        .catch(e=> console.log(e))
        .finally(() => setIsFetching(false))
    }

    const storeMemos = Array.from(storeMemoMap.values())
    return (
        <div>
            <Form.Group as={Row}>
                <Col xl={8} lg={8} md={8} sm={8}>
                    <div className={Styles.Title}>
                        점포/매장 검색
                    </div>
                    <div className={`d-flex`}>
                        <div style={{flex:1}}>
                            <InputGroup>
                                <div className={`pe-3`} style={{minWidth:200}}>
                                    <Select
                                        menuPortalTarget={document.body}
                                        menuShouldBlockScroll={true}
                                        ref={storeRef}
                                        isSearchable={true}
                                        isClearable={true}
                                        name="color"
                                        value={store1}
                                        options={appStore.branchesOptions}
                                        onChange={option=>{

                                            if(option){
                                                setStoreId(option.value)
                                                setStore1(option);
                                            }else{
                                                setStoreId('0000')
                                            }
                                            fetchStoreMemo(true, option?.value)
                                        }}
                                        placeholder={'전체'}

                                    />
                                </div>
                                <Form.Control value={keyword}
                                              onChange={e=>{
                                                  setKeyword(e.target.value)
                                              }}
                                              onKeyDown={e=>{
                                                  if(e.key == 'Enter'){
                                                      fetchStoreMemo(true, storeId);
                                                  }
                                              }}
                                />
                                <Button variant={'outline-primary'} className={`d-flex justify-content-center align-items-center`}
                                        onClick={e=>{
                                            fetchStoreMemo(true ,storeId);
                                        }}
                                >
                                    <FiSearch/>
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                    <div className={`overflow-auto flex-default d-flex flex-column pt-2`} style={{height: 450, flex:'1 1 0'}}>
                        <PerfectScrollbar containerRef={ref=>setScrollRef(ref)}
                            onScrollY={container=>{
                            if(container.scrollHeight - container.offsetHeight - 20 < container.scrollTop){
                                if(rowsCount != (storeMemoMap.size || 0))
                                    fetchStoreMemo(false, storeId);
                            }
                        }}>
                            {
                                storeMemos.map(store=>
                                    <StoreMemoCard key={store?.store_seq} store={store} currentTextareaChange={currentTextareaChange}
                                                   storeMemoMap={storeMemoMap}
                                                   setStoreMemoMap={setStoreMemoMap}
                                                   ticket={ticket}
                                    />
                                )
                            }
                            {
                                isFetching &&
                                    <div className={Styles.StoreMemoCard}>
                                        <Loader/>
                                    </div>
                            }
                        </PerfectScrollbar>
                    </div>
                </Col>
                <Col xl={4} lg={4} md={4} sm={4}>
                    <div className={Styles.Title}>
                        점포 정보
                    </div>
                    <Select
                        menuPortalTarget={document.body}
                        menuShouldBlockScroll={true}
                        ref={storeInfoRef}
                        isSearchable={true}
                        isClearable={true}
                        name="color"
                        value={store2}
                        options={appStore.branchesOptions}
                        onChange={option=>{
                            if(option){
                                setStore2(option);
                                axios.post(`/branch/info/${option.value}`)
                                    .then(({data: branchInfoResponse}) => {
                                        setBranch(branchInfoResponse);
                                });
                                storeFloorDataGridStore.searchCondition.set('id', option.value)
                                storeFloorDataGridStore.refresh()
                            }else{
                                setBranch(null);
                                storeFloorDataGridStore.clear();
                            }
                        }}
                        placeholder={'점포를 선택 해주세요.'}
                    />
                    {
                        branch &&
                        <StoreCard branch={branch}/>
                    }

                    <div className={`${Styles.Title} pt-3`}>
                        층별 안내
                    </div>
                    <div>
                        <DataGrid store={storeFloorDataGridStore}
                                  columns={[
                                      <Header key="floor" id="floor" name="층"  style={{textAlign: 'center'}} />,
                                      <Header key="category_name" id="category_name" name="카테고리"  style={{textAlign: 'left'}}/>,
                                  ]}
                                  isCheckable={false}
                                  keyColumn="id"
                                  highlightClass="table-primary"
                                  onRowClick={rowData => {

                                  }}
                        />
                    </div>
                </Col>

            </Form.Group>
        </div>
    );
};

StoreTab.propTypes = {};

export default StoreTab;
