import React, {useEffect, useState} from 'react';
import {Badge, Card, Col, Form, Row} from 'react-bootstrap';
import Styles from './PointTab.module.scss';
import {Collapse} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ExpandLess} from '@mui/icons-material';
import {DataGrid, DataGridStore, Header} from "@qbot-chat/qbot-uikit/grid";
import axios from 'axios';
import appStore from '../../store/AppStore';
import {toJS} from 'mobx';
import {NotificationManager} from "react-notifications";

const pointDataGridStore = new DataGridStore({
    fetchUrl      : '/customer/point/history',
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    noHeader      : true,
    rowsPerPage   : 5,
    paginationSize: 5,
});

const PointTab = ({customer}) => {

    const [isPointOpen, setIsPointOpen] = useState(true);
    const [summary, setSummary] = useState({});

    useEffect(() => {
        if (customer?.cust_id) {
            // axios.post('/customer/point/summary', {custId: customer?.cust_id})
            //     .then(r => setSummary(r.data))
            //     .catch(e => console.log(e));
            // pointDataGridStore.searchCondition.set('custId', customer?.cust_id);
            // pointDataGridStore.refresh();
        } else {
            setSummary(null);
            pointDataGridStore.clear();
        }
    }, [customer]);

    const receiptModals = toJS(appStore.receiptModals);
    return (
        <div>
            <Form.Group as={Row}>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={Styles.Title}>
                        포인트
                    </div>
                    <div className={Styles.Summary}>
                        <div className={Styles.Content}>
                            <div className={Styles.Label}>
                                사용가능 포인트
                            </div>
                            <div className={Styles.Amount}>
                                {summary?.useful != null ? summary?.useful.toLocaleString() : '-'}P
                            </div>
                        </div>
                        <div className={Styles.Content}>
                            <div className={Styles.Label}>
                                익일 적립 포인트
                            </div>
                            <div className={Styles.Amount}>
                                {summary?.accml != null ? summary?.accml.toLocaleString() : '-'}P
                            </div>
                        </div>
                        <div className={Styles.Content}>
                            <div className={Styles.Label}>
                                소멸 예정 포인트
                            </div>
                            <div className={Styles.Amount}>
                                {summary?.extsh != null ? summary?.extsh.toLocaleString() : '-'}P
                            </div>
                        </div>
                    </div>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className={`pt-4`}>
                <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={`d-flex justify-content-between`}>
                        <div className={Styles.Title}>
                            포인트 적립/사용 내역
                        </div>
                        {
                            isPointOpen ?
                                <ExpandMoreIcon onClick={() => setIsPointOpen(!isPointOpen)}/>
                                :
                                <ExpandLess onClick={() => setIsPointOpen(!isPointOpen)}/>
                        }
                    </div>
                    <Collapse in={isPointOpen}>
                        <DataGrid store={pointDataGridStore}
                                  columns={[
                                      <Header key="index" id="index" name="No" minWidth={30} width={30} style={{textAlign: 'center'}}/>,
                                      <Header key="transDate" id="transDate" name="거래일자" minWidth={55} width={55} style={{textAlign: 'center'}} sortable={true}/>,
                                      <Header key="reciept_number" id="reciept_number" name="영수증번호" minWidth={100} width={100}
                                              style={{textAlign: 'center', wordBreak: 'break-all', whiteSpace: 'break-spaces'}}
                                              printFunction={(number, row) =>
                                                  number &&
                                                  <Card.Link style={{cursor: 'pointer'}} onClick={() => {
                                                      if (!appStore.receiptModalMap.has(number) && appStore.receiptModalMap.size < 5) {
                                                          const position = receiptModals?.length > 0 && receiptModals[receiptModals.length - 1]?.position || {x: window.innerWidth - 300, y: -50};
                                                          const addedModal = {
                                                              position : {x: position.x, y: position.y + 50},
                                                              receiptNo: number,
                                                              title: `${row?.transDate}|${row?.branchName}`
                                                          };

                                                          appStore.receiptModalMap.set(
                                                              number,
                                                              addedModal
                                                          );

                                                          axios.post(
                                                              '/customer/receipt/detail',
                                                              {
                                                                  'receipt_no': number,
                                                                  'str_nm'    : row.branchName,
                                                              }
                                                          )
                                                              .then(({data}) => {
                                                                  if (data?.jornal) {
                                                                      appStore.receiptModalMap.set(
                                                                          number,
                                                                          {
                                                                              ...addedModal,
                                                                              message  : data.jornal
                                                                          }
                                                                      );
                                                                  }

                                                              });
                                                      }
                                                  }}>
                                                      {number}
                                                      {
                                                          appStore.receiptModalMap.has(number) &&
                                                          <Badge className="ms-2">{appStore.receiptModals.findIndex(receipt => receipt?.receiptNo === number) + 1}</Badge>
                                                      }
                                                  </Card.Link>

                                              }
                                      />,
                                      <Header key="branchName" id="branchName" name="가맹점명" minWidth={60} width={60} style={{textAlign: 'center'}}/>,
                                      <Header key="addPoint" id="addPoint" name="적립" minWidth={30} width={30} style={{textAlign: 'right'}}/>,
                                      <Header key="usePoint" id="usePoint" name="사용" minWidth={30} width={30} style={{textAlign: 'right'}}/>,
                                      <Header key="paddType" id="paddType" name="유형" minWidth={70} width={70} style={{textAlign: 'center'}}/>,
                                  ]}
                                  isCheckable={false}
                                  isModifiable={false}
                                  keyColumn="id"
                                  highlightClass="table-primary"
                                  onRowClick={rowData => {

                                  }}
                        />
                    </Collapse>
                </Col>
            </Form.Group>
        </div>
    );
};

PointTab.propTypes = {};

export default PointTab;
