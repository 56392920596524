import React from 'react';
import Select from "react-select";
import {observer} from "mobx-react";

@observer
class OptionSelect extends React.Component{
    render() {
        let {options, onChange, value, isClearable = true, isSearchable = true, menuShouldBlockScroll = true, placeholder} = this.props;
        return(
            <Select menuPortalTarget={document.body}
                    menuShouldBlockScroll={menuShouldBlockScroll}
                    placeholder={placeholder}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    styles={{
                        clearIndicator: (base, state) => ({
                            ...base,
                            padding:`0 .25rem`,
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            padding:`0 .25rem`,
                        }),
                        indicatorSeparator: (base, state) => ({
                            ...base,
                            marginTop:'.25rem',
                            marginBottom:'.25rem',
                        }),
                        container: (base, state) => ({
                            ...base,
                            marginRight:'.25rem',
                            flex:1,
                            maxHeight:31
                        }),
                        indicatorsContainer: (base, state) => ({
                            ...base,
                            padding:0,
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            padding:'0 .5rem'
                        }),
                        control: (base, state) => ({
                            ...base,
                            minHeight: 31,
                            maxHeight: 31
                        }),
                        input: (base, state) => ({
                            ...base,
                            padding: 0,
                            margin: 0,
                        }),

                        group: (base, state) => ({
                            ...base,
                        }),
                        groupHeading: (base, state) => ({
                            ...base,
                        }),

                        loadingIndicator: (base, state) => ({
                            ...base,
                        }),
                        loadingMessage: (base, state) => ({
                            ...base,
                        }),
                        menu: (base, state) => ({
                            ...base,
                        }),
                        menuList: (base, state) => ({
                            ...base,
                        }),
                        menuPortal: (base, state) => ({
                            ...base,
                        }),
                        multiValue: (base, state) => ({
                            ...base,
                        }),
                        multiValueLabel: (base, state) => ({
                            ...base,
                        }),
                        multiValueRemove: (base, state) => ({
                            ...base,
                        }),
                        noOptionsMessage: (base, state) => ({
                            ...base,
                        }),
                        option: (base, state) => ({
                            ...base,
                        }),
                        placeholder: (base, state) => ({
                            ...base,
                        }),
                        singleValue: (base, state) => ({
                            ...base,
                        }),

                    }}
                    options={options}
                    onChange={({value}) => {
                        onChange && onChange(value)
                    }}
                    value={value}
            >
            </Select>
        )
    }
}

export default OptionSelect;