import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {observer} from 'mobx-react';
import {computed, makeObservable, observable, reaction} from 'mobx';
import _ from 'lodash';
import axios from "axios";
import {CommonHelper} from "@qbot-chat/qbot-uikit";

const styles={
    clearIndicator: (base, state) => ({
        ...base,
        padding:`0 .25rem`,
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding:`0 .25rem`,
    }),
    indicatorSeparator: (base, state) => ({
        ...base,
        marginTop:'.25rem',
        marginBottom:'.25rem',
    }),
    container: (base, state) => ({
        ...base,
        marginRight:'.25rem',
        flex:1,
        maxHeight:31
    }),
    indicatorsContainer: (base, state) => ({
        ...base,
        padding:0,
    }),
    valueContainer: (base, state) => ({
        ...base,
        padding:'0 .5rem'
    }),
    control: (base, state) => ({
        ...base,
        minHeight: 31,
        maxHeight: 31
    }),
    input: (base, state) => ({
        ...base,
        padding: 0,
        margin: 0,
    }),

    group: (base, state) => ({
        ...base,
    }),
    groupHeading: (base, state) => ({
        ...base,
    }),

    loadingIndicator: (base, state) => ({
        ...base,
    }),
    loadingMessage: (base, state) => ({
        ...base,
    }),
    menu: (base, state) => ({
        ...base,
    }),
    menuList: (base, state) => ({
        ...base,
    }),
    menuPortal: (base, state) => ({
        ...base,
    }),
    multiValue: (base, state) => ({
        ...base,
    }),
    multiValueLabel: (base, state) => ({
        ...base,
    }),
    multiValueRemove: (base, state) => ({
        ...base,
    }),
    noOptionsMessage: (base, state) => ({
        ...base,
    }),
    option: (base, state) => ({
        ...base,
    }),
    placeholder: (base, state) => ({
        ...base,
    }),
    singleValue: (base, state) => ({
        ...base,
    }),

}

@observer
class MultipleDataSelect extends Component {

    @observable
    codes = [];

    @observable
    selected = [];

    @observable
    isLoading = false;

    @observable
    isDisabled = true;

    selectRef;

    dispose;

    clear(){
        let store = this;
        store.selectRef.select?.clearValue();
    }

    get isEnoughParams() {
        const {params, requireParamKeys} = this.props;
        if (!requireParamKeys?.length) {
            return true;
        } else {
            if (params) {
                return requireParamKeys.every(key => !!params[key]);
            } else
                return false;
        }
    }

    subscribeSelectedValue() {
        return reaction(
            () => this.isEnoughParams,
            isEnoughParams => {
                if(!isEnoughParams) {
                    this.props.onChange?.(null);
                }
            }
        )
    }

    constructor(props, context) {
        super(props, context);
        this.dispose = this.subscribeSelectedValue();
        makeObservable(this);

    }

    loadOptions() {
        const {url, method, params, labelKey, valueKey} = this.props;
        if (!url) {
            this.isDisabled = true;
            return;
        }

        if (!this.isEnoughParams) {
            this.isDisabled = true;
            return;
        }
        this.isLoading = true
        axios.post(
            url,
            params
        ).then((res)=>{
            let {data} = res;
            if (!data.length > 0 )
                return;
            this.codes.replace(
                data.map(code => ({label: code[labelKey], value: code[valueKey], code}))
            );

            if (!data.find(code => code[valueKey] === this.props.value))
                if (this.selectRef)
                    this.selectRef.select?.clearValue();

            this.isDisabled = false;
        }).finally(()=>{
            this.isLoading = false
        })
    }

    componentDidMount() {
        this.loadOptions();
    }


    componentWillUnmount() {
        this.dispose();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps?.params, this.props?.params)) {
            this.loadOptions();
        }
    }

    render() {
        const {
            onChange,
            placeholder,
            readOnly = false,
            value,
            excepts = [],
            isMulti=true
        } = this.props;

        return (
            <div style={{flex: 1}}>
                <Select
                    size={'sm'}
                    ref={ref => this.selectRef = ref}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    isClearable
                    isSearchable
                    styles={styles}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                    })}
                    onChange={(option, {action}) => {
                        this.selected = option;
                        onChange && onChange(option);
                    }}
                    value={value}
                    options={this.codes.filter(code=>!excepts.find(except=>code.value == except.value))}
                    isLoading={this.isLoading}
                    isDisabled={this.isDisabled || readOnly}
                    {...CommonHelper.omit(
                        this.props,
                        'value',
                        'onChange'
                    )}
                />
            </div>
        );
    }
}

MultipleDataSelect.propTypes = {
    url             : PropTypes.string.isRequired,
    value           : PropTypes.any,
    onChange        : PropTypes.func.isRequired,
    labelKey        : PropTypes.string,
    valueKey        : PropTypes.string,
    params          : PropTypes.object,
    requireParamKeys: PropTypes.arrayOf(PropTypes.string),
    method          : PropTypes.string,
    placeholder     : PropTypes.string,
};

MultipleDataSelect.defaultProps = {
    placeholder: '선택 ... '
};


export default MultipleDataSelect;
