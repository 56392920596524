import React from "react";
import Panel from "../layout/Panel";

export const DragItem = React.forwardRef((props, ref) => {
    const {className, children, title, shouldScroll, layouts, id, ...defaultPanelProps} = props;
    return (
        <div ref={ref} {...defaultPanelProps} className={`d-flex flex-column ${className}`} >
            <div className={`d-flex flex-column w-100 h-100`}>
                <Panel className={`d-flex flex-column flex-grow-1 p-0 m-0`} style={{minHeight:0, flex:`1 1 1`}}>
                    {
                        React.Children.map(props.children,
                            child => {
                                return (React.cloneElement(child, {
                                    layout : layouts?.find(layout=>layout.i == id),
                                        ...child.props
                                    })
                                );
                            })
                    }
                </Panel>
            </div>
        </div>
    );
})