import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FrameWrap from '../../components/layout/FrameWrap';
import TitlePanel from '../../components/layout/TitlePanel';
import TitlePanelLabel from '../../components/label/TitlePanelLabel';
import { DataGrid, DataGridStore, Sort } from '@qbot-chat/qbot-uikit/grid';
import { Badge, Button, Col, Form, FormGroup, InputGroup } from 'react-bootstrap';
import Panel from '../../components/layout/Panel';
import { FormStore } from '../../components/validation';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import HistoryContent from '../../components/history/HistoryContent';
import { INTENT_ID_RULE } from '../../const/rules';
import Styles from '../standard_scenario/StandardScenarioList.module.scss';
import { ANSWER_TYPE_OPTIONS, CALL_TYPE_OPTIONS, USE_YN_OPTIONS } from '../../const/options';
import { FormPrint, headerPrint, headerWidthPrint } from '../../util/dataUtils';
import { TbRefresh } from 'react-icons/tb';

const store = new DataGridStore({
  fetchUrl: `/standardset/engine/list`,
  selectFnFromResponse: (res) => ({ items: res.data.items, rowsCount: res.data.count }),
  sort: new Sort(`intentId`, Sort.SORT_DIRECTION.DESC),
});

const form = new FormStore();

const StandardChatList = () => {
  const [initData, setInitData] = useState({});
  const [isIdChecked, setIsIdChecked] = useState(false);
  const [isIdChange, setIsIdChange] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState({});

  const CheckButton = () => (
    <Button variant='outline-primary' onClick={onIdCheck}>
      중복체크
    </Button>
  );

  const standardData = useMemo(() => {
    return [
      [
        {
          label: '인텐트 ID',
          name: 'intentId',
          props: {
            disabled: !!initData?.intentId,
            style: isIdChange ? { borderRadius: '0.375rem 0 0 0.375rem' } : {},
            onChange: onChangeId,
            maxLength: 5,
            button: isIdChange ? <CheckButton /> : null,
          },
          headerProps: {
            sort: 1,
            sortable: true,
            width: headerWidthPrint(50, 100, 200),
            printFunction :(id) => (
              <Badge pill bg='secondary'>
                {id}
              </Badge>
            )
          },
        },
        {
          label: '인텐트 그룹',
          name: 'intentGroup',
          headerProps: {
            sort: 2,
            width: headerWidthPrint(100, 120, 200),
          },
        },
      ],
      {
        label: '질문',
        name: 'question',
        as: 'textarea',
        headerProps: {
          sort: 3,
          width: headerWidthPrint(150, 200, 300),
        },
      },
      {
        label: '답변',
        name: 'answer',
        as: 'textarea',
        headerProps: {
          sort: 4,
          width: headerWidthPrint(150, 200, 300),
        },
      },
      [
        {
          label: '답변 유형',
          name: 'answerType',
          options: ANSWER_TYPE_OPTIONS,
        },
        {
          label: '통화 유형',
          name: 'callType',
          options: CALL_TYPE_OPTIONS,
        },
      ],
      [
        {
          label: '통화 정보',
          name: 'callInfo',
          as: 'textarea',
        },
        {
          label: '버튼 라벨',
          name: 'buttonLabel',
          as: 'textarea',
        },
      ],
      [
        {
          label: '작업여부',
          name: 'annoYn',
          options: USE_YN_OPTIONS,
        },
        {
          label: '사용여부',
          name: 'useYn',
          options: USE_YN_OPTIONS,
          headerProps: {
            sort: 6,
            printFunction: (isUsed) => <Badge bg={isUsed === 'Y' ? 'success' : 'danger'}>{isUsed === 'Y' ? '활성화' : '비활성화'}</Badge>,
            width: headerWidthPrint(70, 80, 100),
            className: 'justify-content-center',
          },
        },
      ],
      {
        label: '인텐트 설명',
        name: 'intentDesc',
        headerProps: {
          sort: 5,
          width: headerWidthPrint(80, 120, 200),
        },
      },
      {
        label: '수정일',
        name: 'updDate',
        headerProps: {
          sort: 7,
          type: 'date',
          width: headerWidthPrint(50, 120, 200),
        },
        hidden: true,
      },
    ];
  }, [initData, isIdChange]);

  useEffect(() => {
    store.refresh();
    return () => {
      store.searchCondition.clear();
      onReset();
    };
  }, []);

  const onFetchDetail = useCallback((id) => {
    axios.post(`/standardset/engine/detail/${id}`).then((res) => {
      setInitData(res.data);
      form.values = res.data;
    });
  }, []);

  const onReset = () => {
    form.clear();
    setInitData({});
  };

  const onInsert = () => {
    if (!isIdChecked) return NotificationManager.warning('인텐트 ID 중복 확인을 해주세요.');

    confirmDialogStore.openSave(
      '추가 내역',
      <HistoryContent historyData={standardData} data={form.values} />,
      () => {
        axios.post(`/standardset/engine/insert`, form.values).then((res) => {
          NotificationManager.success('챗봇 표준셋을 추가했습니다.');
          setInitData({ ...form.values, uid: res.data });
          store.refresh();
        });
      },
      null
    );
  };

  const onUpdate = () => {
    if (JSON.stringify(initData) === JSON.stringify(form.values)) return NotificationManager.warning('수정된 내역이 없습니다.');

    confirmDialogStore.openSave(
      `수정 내역 (${form.values.intentId})`,
      <HistoryContent historyData={standardData} prevData={initData} data={form.values} />,
      () => {
        axios.post(`/standardset/engine/update`, form.values).then((res) => {
          NotificationManager.success('챗봇 표준셋을 수정했습니다.');
          setInitData({ ...form.values, uid: res.data });
          store.refresh();
        });
      },
      null
    );
  };

  const onDelete = () => {
    confirmDialogStore.openRemove('삭제 확인', '정말 삭제하시겠습니까?', ()=> {
      axios.post(`/standardset/engine/delete`, { uid: initData.uid }).then((res) => {
        NotificationManager.success('챗봇 표준셋을 삭제했습니다.');
        onReset();
        store.refresh();
      });
    }, null);
  };

  const onIdCheck = () => {
    const inputId = form.valueMap.get('intentId');
    if (!INTENT_ID_RULE.regex.test(inputId)) return NotificationManager.warning(INTENT_ID_RULE.message);

    if (!inputId) return;

    axios.post(`/standardset/engine/check/${inputId}`).then((res) => {
      if (res.data) {
        NotificationManager.warning('이미 사용중인 챗봇 표준셋 아이디 입니다.');
      } else {
        NotificationManager.success('사용 가능한 챗봇 표준셋 아이디 입니다.');
        setIsIdChecked(true);
        setIsIdChange(false);
      }
    });
  };

  function onChangeId(e) {
    setIsIdChange(e.target.value !== (initData?.intentId ?? ''));
    setIsIdChecked(false);
  }


  const onSearch = (e, keyword = {}) => {
    e?.preventDefault();
    const { intentId, question, answer } = keyword;
    store.searchCondition.set('intentId', intentId ?? '');
    store.searchCondition.set('question', question ?? '');
    store.searchCondition.set('answer', answer ?? '');
    store.fetch();
  };

  const onSearchReset = () => {
    setSearchKeyword({});
    onSearch();
  };


  return (
    <FrameWrap className='overflow-x-auto'>
      <TitlePanel>
        <FormGroup>
          <Col className='justify-content-between align-items-center d-flex'>
            <TitlePanelLabel label='챗봇 표준셋 관리' />
            <div className='d-flex gap-2'>
              {!initData?.intentId ? (
                <Button variant='outline-primary' onClick={onInsert}>
                  + 표준셋 추가
                </Button>
              ) : (
                <>
                  <Button variant='outline-danger' onClick={onDelete}>
                    삭제
                  </Button>
                  <Button variant='outline-secondary' onClick={onReset}>
                    초기화
                  </Button>
                  <Button variant='outline-primary' onClick={onUpdate}>
                    수정
                  </Button>
                </>
              )}
            </div>
          </Col>
        </FormGroup>
      </TitlePanel>
      <Panel className='flex-default d-flex gap-4'>
        <div className={Styles.DataGridWrapper}>
          <Panel style={{ border: '1px solid #E8E8E8', borderRadius: '.5rem' }}>
            <Form onSubmit={(e) => onSearch(e, searchKeyword)} className={Styles.SearchForm}>
              <FormGroup className={Styles.SearchContent}>
                <InputGroup style={{ maxWidth: '220px' }}>
                  <InputGroup.Text>인텐트ID</InputGroup.Text>
                  <Form.Control placeholder='ID로 검색' maxLength={5} value={searchKeyword?.intentId ?? ''} onChange={(e) => setSearchKeyword((prev) => ({ ...prev, intentId: e.target.value }))} />
                </InputGroup>
                <InputGroup className='flex-1'>
                  <InputGroup.Text>질문</InputGroup.Text>
                  <Form.Control placeholder='질문으로 검색' value={searchKeyword?.question ?? ''} onChange={(e) => setSearchKeyword((prev) => ({ ...prev, question: e.target.value }))} />
                </InputGroup>
                <InputGroup className='flex-1'>
                  <InputGroup.Text>답변</InputGroup.Text>
                  <Form.Control placeholder='답변으로 검색' value={searchKeyword?.answer ?? ''} onChange={(e) => setSearchKeyword((prev) => ({ ...prev, answer: e.target.value }))} />
                </InputGroup>
                <Button variant='outline-secondary' onClick={onSearchReset}>
                  <TbRefresh />
                </Button>
                <Button variant='outline-secondary' type='submit'>
                  검색
                </Button>
              </FormGroup>
            </Form>
          </Panel>
          <DataGrid
            store={store}
            keyColumn='intentId'
            columns={headerPrint(standardData)}
            isHighlightFn={(rowData) => rowData.uid === initData.uid}
            highlightClass='active-tr'
            onRowClick={(rowData) => {
              onFetchDetail(rowData.uid);
            }}
          />
        </div>
        <div className={Styles.FormWrapper}>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormPrint form={form} data={standardData} />
          </Form>
        </div>
      </Panel>
    </FrameWrap>
  );
};

export default StandardChatList;
