import {components} from "react-select";
import React, {Component} from "react";
import {Image} from "react-bootstrap";
import RandomColor from "../avatar/RandomColor";
import DefaultTeamImage from "../avatar/DefaultTeamImage";
import {AsyncPaginate} from "react-select-async-paginate";
import {computed, makeObservable, observable, values} from "mobx";
import {observer} from "mobx-react";
import axios from "axios";
import Avatar from "../avatar/Avatar";

const TeamInfo = (props) => {
    return <div className={'d-flex flex-row m-2'}>
        <Avatar src={props.team?.avatar?.fullUrl} seed={props.team.id} type={'team'} width={'2rem'}/>
        <div className={'ms-2 align-self-center'}> {props.team.name} </div>
    </div>;
}

const TeamOption = (props) => {
    return (
        <components.Option {...props}  >
            <TeamInfo
                team={props.data.object}
            />
        </components.Option>
    );
}

@observer
class TeamSelect extends Component{

    @observable
    teamMap = new Map();

    @computed
    get teams()  {
        return values(this.teamMap)
    }

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentWillUnmount() {
        this.teamMap.clear();
    }

    teamSearch = async(search, loadedOptions) =>  {
        const res = await axios.post(
            `/team/infinite/list`,
            {
                keyword: search,
                lastRow: this.teams.length
            }
        )
        res.data.items.map(team => this.teamMap.set(team.id, team));
        let options = this.teams.filter(ps => !Array.from(loadedOptions).find(x => x.value == ps.id))
            .map(ps => {
                return ({label: ps.name, value: ps.id, object: ps })
            });

        if(search) {
            options = res.data.items.map(ps => ({label: ps.name, value: ps.id, object: ps }));
        }
        return {
            options: options,
            hasMore: this.teams.length < res.data.count,
        };
    }
    render() {
        const {onChange} = this.props;
        return (
            <AsyncPaginate
                isClearable={true}
                components={{Option : TeamOption}}
                onInputChange={(e) => {
                    this.teamMap.clear();
                }}
                loadOptions={this.teamSearch}
                onChange={onChange}
                {...this.props}
            />
        );
    }
}

export default TeamSelect;