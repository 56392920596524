import {NotificationManager} from "react-notifications";
import {makeObservable, observable} from "mobx";
import {PUSH_TYPE} from "../const/AppConst";
import {NOTIFICATION_TYPE} from "@qbot-chat/qbot-uikit/chat";
import {chatStore, ticketStore} from "./AdminChatStore";

class NotificationStore{

    constructor() {
        makeObservable(this)
    }

    @observable
    test = true;

    pushNotification = async (payload) => {
        let store = this;
        let { notification, data } = payload;
        let { title, body } = notification;
        let type = data?.type;
        switch (type) {
            case PUSH_TYPE.ASSIGNMENT:
                let ticket = JSON.parse(data?.ticket);
                // store.notification(body, title,
                //     ()=>{
                //         ticketStore.enterChannel(ticket)
                //             .then(r=>chatStore.setChannel(ticket));
                //     }
                // );
                break;
        }
    }

    notification(body, title, callback, type= NOTIFICATION_TYPE.INFO){
        let timeout = 3000;
        switch (type) {
            case NOTIFICATION_TYPE.SUCCESS:
                NotificationManager.success(body, title, timeout, ()=>{callback && callback()});
                break;
            case NOTIFICATION_TYPE.WARNING:
                NotificationManager.warning(body, title, timeout, ()=>{callback && callback()});
                break;
            case NOTIFICATION_TYPE.DANGER:
                NotificationManager.error(body, title, timeout, ()=>{callback && callback()});
                break;
            default:
                NotificationManager.info(body, title, timeout, ()=>{callback && callback()});
                break;

        }
    }

    assignmentSound = new Audio(`/asset/sound/assignment_sound.mp3`);

}

const notificationStore = new NotificationStore();
export {notificationStore as default}