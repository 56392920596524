import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Styles from './SideBar.module.scss';
import ListLabel from './ListLabel.js';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import { Chart1, Flashy, People, PresentionChart, SearchNormal1, Setting2, Ticket, User, UserCirlceAdd, UserOctagon, ClipboardText, Book1, Call, CpuSetting } from 'iconsax-react';
import DoubleArrowIcon from './DoubleArrowIcon.js';
import Menu from './Menu';
import ChatBotMessageIcon from '../../icon/ChatBotMessageIcon';
import MonitoringIcon from '../../icon/MonitoringIcon';
import ShopTalk from '../../icon/ShopTalk';
import RoadShow from '../../icon/RoadShow';
import EmartLogo from '../EmartLogo';
import { ACCESS, MENU } from '../../../const/AppConst';

@observer
class SideBar extends Component {
  @observable
  collapsed = false;

  constructor(props, context) {
    super(props, context);
    makeObservable(this);
  }

  @action.bound
  handleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  isAuthorization(menus = []) {
    let result = menus.filter((menu) => ACCESS[appStore?.type]?.includes(menu));
    if (result.length > 0) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className={`${Styles.SideBar} ${this.collapsed ? Styles.Collapse : ''}`}>
        <div className={`${Styles.LogoWrap}`}>
          <div className={'w-100 align-items-center pd-0 d-flex justify-content-between flex-row'} onClick={this.handleCollapsed}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image src={`/asset/img/qt_logo.png`} style={{ width: '1.6rem', marginRight: '0.5rem' }} />
              {!this.collapsed && <span style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>SOONi</span>}
              {/* <EmartLogo
                                size={
                                    !this.collapsed? '4rem' : '4rem'
                                }
                                visibel={
                                    this.collapsed? 'none' : ''
                                }
                            /> */}
            </div>
            <div className={Styles.CollapseButtonWrap}>
              <DoubleArrowIcon collapsed={this.collapsed} />
            </div>
          </div>
        </div>

        <div className={`${Styles.SideMenu}`}>
          {this.isAuthorization([MENU.COMPANY]) && (
            <>
              <ListLabel label='슈퍼관리자' />
              <Menu collapsed={this.collapsed} icon={<SearchNormal1 />} label={'회사 관리'} to={`/main/company`} />
            </>
          )}
          {this.isAuthorization([MENU.ADMIN]) && <Menu collapsed={this.collapsed} icon={<UserOctagon />} label={'관리자'} to={`/main/admin`} />}
          <ListLabel label='티켓' />
          {this.isAuthorization([MENU.CHAT]) && <Menu collapsed={this.collapsed} icon={<Ticket />} label={'나의티켓'} to={`/main/chat`} />}
          {this.isAuthorization([MENU.TICKET]) && <Menu collapsed={this.collapsed} icon={<SearchNormal1 />} label='모든티켓 조회' to={`/main/ticket`} />}
          {this.isAuthorization([MENU.DASH, MENU.MONITOR, MENU.REPORT]) && (
            <>
              <ListLabel label='대시보드' />
              {this.isAuthorization([MENU.DASH]) && <Menu collapsed={this.collapsed} icon={<Chart1 />} label='대시보드' to={`/main/dashboard`} />}
              {this.isAuthorization([MENU.MONITOR]) && <Menu collapsed={this.collapsed} icon={<MonitoringIcon parentColorClassName={'MenuTitle'} />} label={'모니터링'} to={`/main/monitor`} />}
              {this.isAuthorization([MENU.REPORT]) && <Menu collapsed={this.collapsed} icon={<PresentionChart />} label={'리포트'} to={`/main/report`} />}
            </>
          )}

          <ListLabel label='관리' />
          {this.isAuthorization([MENU.TEAM]) && <Menu collapsed={this.collapsed} icon={<People />} label='팀' to={`/main/team`} />}
          {this.isAuthorization([MENU.TEAM_AGENT]) && <Menu collapsed={this.collapsed} icon={<UserCirlceAdd />} label={'팀원 관리'} to={`/main/member`} />}
          {this.isAuthorization([MENU.AGENT]) && <Menu collapsed={this.collapsed} icon={<User />} label={'상담사'} to={`/main/agent`} />}
          {this.isAuthorization([MENU.BOT_MESSAGE]) && <Menu collapsed={this.collapsed} icon={<ChatBotMessageIcon parentColorClassName={'MenuTitle'} />} label={'챗봇 메시지'} to={`/main/bot/message/template`} />}
          {this.isAuthorization([MENU.NOTICE]) && <Menu collapsed={this.collapsed} icon={<ClipboardText parentColorClassName={'MenuTitle'} />} label={'공지사항'} to={`/main/notice`} />}
          {this.isAuthorization([MENU.QUICK]) && <Menu collapsed={this.collapsed} icon={<Flashy />} label={'빠른답변'} to={`/main/quickmsg`} />}
          {this.isAuthorization([MENU.BRANCH]) && <Menu collapsed={this.collapsed} icon={<ShopTalk parentColorClassName={'MenuTitle'} />} label={'지점공감'} to={`/main/branch-memo`} />}
          {/* 추후에 삭제될 메뉴 주석처리 */}
          {/* {
                        this.isAuthorization([MENU.ROAD]) &&
                        <Menu collapsed={this.collapsed} icon={<RoadShow parentColorClassName={'MenuTitle'} />} label={'로드쇼'} to={`/main/roadshow`}/>
                    } */}
          {this.isAuthorization([MENU.SETTING]) && <Menu collapsed={this.collapsed} icon={<Setting2 />} label={'설정'} subMenu={true} to={`/main/setting`} />}
          {this.isAuthorization([MENU.SCENARIO]) && (
            <>
              <ListLabel label={'음성설정'} />
              <Menu collapsed={this.collapsed} icon={<Book1 parentColorClassName={'MenuTitle'} />} label={'시나리오'} to={`/main/scenario`} />
            </>
          )}
          {this.isAuthorization([MENU.OUTBOUND_CALL]) && <Menu collapsed={this.collapsed} icon={<Call parentColorClassName={'MenuTitle'} />} label={'아웃바운드 전화번호'} to={`/main/call`} />}
          {this.isAuthorization([MENU.OUTBOUND_SETTING]) && <Menu collapsed={this.collapsed} icon={<CpuSetting parentColorClassName={'MenuTitle'} />} label={'아웃바운드 설정'} to={`/main/ob-setting`} />}

          {this.isAuthorization([MENU.STANDARD_SCENARIO]) && (
            <>
              <ListLabel label={'챗 로그 자동화'} />
              <Menu collapsed={this.collapsed} label={'시나리오 표준셋'} to={`/main/standard-scenario`} />
            </>
          )}
          {this.isAuthorization([MENU.STANDARD_CHAT]) && <Menu collapsed={this.collapsed} label={'챗봇 표준셋'} to={`/main/standard-chat`} />}
          {this.isAuthorization([MENU.ANNOTATION]) && <Menu collapsed={this.collapsed} label={'어노테이션'} to={`/main/annotation`} />}
          {/* {this.isAuthorization([MENU.VOICE]) && (
            <Menu
              collapsed={this.collapsed}
              label={"녹취"}
              to={`/main/record`}
            />
          )} */}
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.node,
};

export default SideBar;
