import React, {Component} from 'react';
import Select, {components} from 'react-select';
import {BsStarFill} from 'react-icons/bs';
import PropTypes from 'prop-types';
import {toJS} from 'mobx';
import Styles from './FavorSelect.module.scss';

const Option = (props) => {
    const handleFavorClick = (e) => {
        e.stopPropagation();
        const {value} = toJS(props.data);
        props.onFavorToggle(value);
    };

    return (
        <>
            <components.Option {...props}>
                <div
                    style={{
                        display       : 'flex',
                        flexDirection : 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>{props.children}</div>
                    <div>
                        <button className={Styles.FavorButton} onClick={handleFavorClick}>
                            <BsStarFill style={{
                                color      : props.isFavorFn(props.data.value) ? 'yellow' : 'white',
                                stroke     : '#666',
                                strokeWidth: '1px',
                            }}/>
                        </button>
                    </div>
                </div>
            </components.Option>
        </>
    );
};

Option.propsTypes = {
    onFavorToggle: PropTypes.func.isRequired,
    isFavorFn    : PropTypes.func,
};


class FavorSelect extends Component {
    render() {
        const {innerRef, options, placeholder, onChange, value, onFavorToggle, isFavorFn} = this.props;
        return (
            <Select
                components={{Option: (props) => <Option onFavorToggle={onFavorToggle} isFavorFn={isFavorFn} {...props}/>}}
                styles={{menuPortal: base => ({...base, zIndex: 9})}}
                ref={innerRef}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                // isLoading={isLoading}
                isSearchable={true}
                isClearable={true}
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        );
    }
}

const FavorSelectForwardingRef = React.forwardRef((props, ref) => (
    <FavorSelect {...props} innerRef={ref}/>
));

FavorSelectForwardingRef.propTypes = {
    ref          : PropTypes.func,
    options      : PropTypes.arrayOf(PropTypes.object),
    placeholder  : PropTypes.string,
    onChange     : PropTypes.func,
    value        : PropTypes.any,
    onFavorToggle: PropTypes.func,
    isFavorFn    : PropTypes.func,
};

export default FavorSelectForwardingRef;
