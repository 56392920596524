import React from 'react';
import _ from 'lodash';
import TitlePanelLabel from '../label/TitlePanelLabel';
import { ArrowRight2 } from 'iconsax-react';

class FrameWrap extends React.Component {
  render() {
    let { children, className } = this.props;
    return (
      <div className={`d-flex flex-column h-100 ${className ? className : ''}`} {..._.omit(this.props, [`className`])}>
        {children}
      </div>
    );
  }
}

export default FrameWrap;
