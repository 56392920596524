import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from "@qbot-chat/qbot-uikit/grid";
import { navigate } from "../../helper/HistoryHelper";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import Panel from "../../components/layout/Panel";
import TitlePanel from "../../components/layout/TitlePanel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import FrameWrap from "../../components/layout/FrameWrap";
import Avatar from "../../components/avatar/Avatar";
import TeamSelect from "../../components/select/TeamSelect";
import { makeObservable, observable } from "mobx";
import { FaStar } from "react-icons/fa6";
import { ACCESS_RIGHTS } from "../../const/AppConst";
import { USER_TYPE } from "@qbot-chat/qbot-uikit";
import appStore from "../../store/AppStore";

const noticeDataGridStore = new DataGridStore({
  fetchUrl: `/notice/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
});

@observer
class NoticeListView extends Component {
  @observable
  searchKeyword = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    noticeDataGridStore.refresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith("/main/notice/detail") &&
      this.props.pathname === "/main/notice/list"
    ) {
      noticeDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    noticeDataGridStore.searchCondition.clear();
  }

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      noticeDataGridStore.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(noticeDataGridStore.searchCondition);
    noticeDataGridStore.searchCondition.set("title", this.selectedCompany);
    noticeDataGridStore.searchCondition.set("content", this.searchKeyword);

    if (
      !_.isEqual(obj, Object.fromEntries(noticeDataGridStore.searchCondition))
    ) {
      noticeDataGridStore.refresh();
    } else {
      noticeDataGridStore.fetch();
    }
  };

  fileDownload = (file) => {
    const byteCharacter = atob(file);
    const byteNumbers = new Array(byteCharacter.length);
    for (let i = 0; i < byteCharacter.length; i++) {
      byteNumbers[i] = byteCharacter.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    URL.revokeObjectURL(blobUrl);
  };

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={"공지사항"} />
              <div>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    navigate(`/main/notice/new`);
                  }}
                >
                  + 공지사항 추가
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel
            className={`rounded-3`}
            style={{ border: "1px solid #E8E8E8" }}
          >
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={"d-flex"}>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        제목, 내용
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="제목 또는 내용으로 검색"
                        value={this.searchKeyword}
                        onChange={(e) => {
                          this.searchKeyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={"flex-default"}>
          <DataGrid
            store={noticeDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,

              <Header
                id={`title`}
                name={`제목`}
                width={{ min: 100, current: 125, max: 275 }}
              />,
              <Header
                id={`content`}
                name={`내용`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id={`startedAt`}
                name={`시작일`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`endedAt`}
                sortable={true}
                name={`종료일`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`companyName`}
                name={`회사명`}
                width={{ min: 100, current: 100, max: 100 }}
              />,
              <Header
                id={`creator`}
                name={`생성자`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id={`createdAt`}
                name={`생성 일시`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`updater`}
                name={`수정자`}
                width={{ min: 100, current: 220, max: 250 }}
              />,
              <Header
                id={`updatedAt`}
                name={`수정 일시`}
                className={`justify-content-end`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`file1`}
                name={`파일1`}
                width={{ min: 100, current: 220, max: 250 }}
                printFunction={(file) => {
                  return (
                    file && (
                      <Button onClick={() => this.fileDownload(file)}>
                        파일 다운로드
                      </Button>
                    )
                  );
                }}
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/notice/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export { NoticeListView as default, noticeDataGridStore };
