import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import {FormStore} from "../../../components/validation";
import axios from "axios";
import Styles from "../SettingManageView.module.scss";
import {Button, Col, Collapse, Form, FormControl, FormGroup, Row} from "react-bootstrap";
import TitlePanelLabel from "../../../components/label/TitlePanelLabel";
import {ACCESS_RIGHTS} from "../../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";
import appStore from "../../../store/AppStore";
import TeamSelect from "../../../components/select/TeamSelect";
import settingStore from "../../../store/SettingStore";
import Avatar from "../../../components/avatar/Avatar";
import TitlePanel from "../../../components/layout/TitlePanel";
import FrameWrap from "../../../components/layout/FrameWrap";
import Panel from "../../../components/layout/Panel";
import {NotificationManager} from "react-notifications";

@observer
class BadwordManageView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value : '',
            isActive : false
        }
    }

    form = new FormStore();

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        axios.post(`/badword/info`,{})
            .then(res => {
                this.setState({
                    value:  res.data?.words || "",
                    isActive: res.data?.isActive || false
                })
                this.form.values = res.data;
            })
    }

    save() {
        if(!this.form.doCheckValid()){
            return;
        }
        this.form.setValue("isActive", this.state.isActive);
        this.form.setValue("words", this.state.value);
        axios.post(
            `/badword/save`,
            {
                ...this.form.values,
            }
        ).then(r=>{
            NotificationManager.success(`업데이트 완료`);
        })
    }

    cancel() {
        this.setState({
            value:  this.form.valueMap.get('words') || "",
            isActive: this.form.valueMap.get('isActive') || false
        })
    }

    render() {
        let isActive = this.form.valueMap.get('isActive') || false;
        return (
            <div className={`${Styles.Tab}`}>
                <FrameWrap>
                    <TitlePanel>
                        <FormGroup as={Row}>
                            <Col md={8} sm={12} className={`align-items-center d-flex`}>
                                <div className={'mt-2 mb-2'}>
                                    <TitlePanelLabel label={'일반 설정 - 욕설 필터'}/>
                                </div>
                            </Col>
                        </FormGroup>
                    </TitlePanel>
                    <Panel>
                        <Panel className={`${Styles.BorderLine} mt-4`} >
                            <FormGroup as={Row}>
                                <Col lg={3} md={12} sm={12} className={'mt-2 mb-2'}>
                                    <div className={Styles.Label}>
                                        설정 사용
                                    </div>
                                </Col>
                                <Col lg={9} md={12} sm={12} className={`d-flex justify-content-end`}>
                                    <Form.Check type="switch"
                                                checked={this.state.isActive}
                                                onChange={e=>{
                                                    this.setState({
                                                        isActive : e.target.checked
                                                    })
                                                }}
                                    />
                                </Col>
                            </FormGroup>
                            <hr className={Styles.HrColor}/>
                            <Collapse in={this.state.isActive}>
                                <FormGroup as={Row}>
                                    <Col md={3}>
                                        <div className={`${Styles.Title} ${Styles.Label}`}>
                                            <div className={`d-flex`}>
                                                필터 메시지 설정
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl as={'textarea'}
                                                     placeholder={'메시지를 입력하세요.'}
                                                     style={{resize:'none'}}
                                                     rows={20}
                                                     defaultValue={this.form.valueMap.get('words')}
                                                     value={this.state.value || this.form.valueMap.get('words')}
                                                     onChange={e=>{
                                                         this.setState({value:e.target.value})
                                                     }}
                                                     isInvalid={!(this.state.value || this.form.valueMap.get('words'))}
                                        />
                                        {
                                            !(this.state.value || this.form.valueMap.get('words')) &&
                                            <Form.Control.Feedback type="invalid">
                                                메시지를 입력하세요.
                                            </Form.Control.Feedback>
                                        }
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse in={(this.state.value || this.form.valueMap.get('words')) != this.form.valueMap.get('words') || this.state.isActive != this.form.valueMap.get('isActive') }>
                                <div >
                                    <div className={`d-flex justify-content-end pt-3`}>
                                        <Button variant={"outline-secondary"} className={Styles.Button} onClick={()=>this.cancel()}>취소</Button>
                                        <Button variant={"outline-primary"}  className={`${Styles.Button} ms-2`}
                                                onClick={()=> this.save()}>
                                            저장
                                        </Button>
                                    </div>
                                </div>
                            </Collapse>
                        </Panel>
                    </Panel>
                </FrameWrap>
            </div>
        );
    }
}

BadwordManageView.propTypes = {};

export default BadwordManageView;