import {ChannelStore, ChatStore, RSocket, Sort, TicketStore} from '@qbot-chat/qbot-uikit/chat';
import {NotificationManager} from "react-notifications";
import notificationStore from "./NotificationStore";
import {CHAT_CONTEXT_PATH, CHAT_WS_URL} from '../const/AppConst';
import {computed, makeObservable, observable} from "mobx";
import axios from "axios";
import * as moment from "moment/moment";

class SettingStore{
    constructor() {
        makeObservable(this);
    }

    @observable
    isLoadedSetting = false;

    @observable
    ticketEventMap = new Map();

    fetchTicketEvent = () => {
        let store = this;
        axios.post('/ticket/event/all')
            .then(r=> {
                store.ticketEventMap.replace(
                    r.data.map(event=>
                        [event.type, event]
                    )
                )
            })
    }

    @observable
    workSheet;

    @computed
    get weekTimes(){
        return this.workSheet?.weekTimes || [];
    }

    fetchWorkSheet(team){
        let store = this;
        axios.post(
            `/setting/work/sheet`,
            team
        ).then(res=>{
            store.workSheet = res.data
        })
    }

    @observable
    setting = {
        timezone:'Asia/Seoul',
        isTransferableByAgent: false,
        isCloseableByAgent: false,
        isSendableCloseMessage: false,
        isEvaluationableByCustomer: false,
        isCloseMessageNoReactAutoClose: false,
        chatRatingMessage:'',
        chatClosedMessage:'',
        chatRatingClosedMessage:'',
        defaultGreetingMessage:''
    }

    fetchSettingInfo(){
        let store = this;
        axios.post('/setting/info', {})
            .then(res=>{
                let {timezone} = res.data;
                this.setting = res.data;
                moment.tz.setDefault(timezone);
                store.isLoadedSetting = true;
            })
    }

}

const settingStore = new SettingStore();

export {settingStore as default};