import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Col, FormGroup, InputGroup, Row} from 'react-bootstrap';
import Styles from './RoadshowTab.module.scss';
import {Send2} from 'iconsax-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';
import appStore from '../../store/AppStore';
import TextInput from '../../components/TextInput';
import moment from 'moment/moment';
import axios from 'axios';
import {DataGridStore} from "@qbot-chat/qbot-uikit/grid";

const storeFloorDataGridStore = new DataGridStore({
    fetchUrl      : '/branch/floor/info',
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    noHeader      : true,
    noPaging      : true,
    rowsPerPage   : 10,
    paginationSize: 10,
});


const RoadshowTab = ({branchId, currentTextareaChange, ticket}) => {
    const storeRef = useRef();
    const storeInfoRef = useRef();
    const [branch, setBranch] = useState(null);
    const [scrollRef, setScrollRef] = useState(null);
    const [storeId, setStoreId] = useState(branchId);
    const [selectValue, setSelectValue] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [storeMemoMap, setStoreMemoMap] = useState(new Map());
    const [rowsCount, setRowsCount] = useState(undefined);

    const [keyword, setKeyword] = useState('');
    const selectInputRef = useRef(null);
    const [page, setPage] = useState(1);

    const [buttonId, setButtonId] = useState('Progress');

    const [items, setItems] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState('운영중'); // 예정

    useEffect(() => {
        setStoreId(branchId);
        const selectedStore = appStore.branches.find(branch => branch.id == branchId);
        storeRef?.current?.setValue({
            value: selectedStore?.id,
            label: `${selectedStore?.brchTp} ${selectedStore?.name}`
        });
        storeInfoRef?.current?.setValue({value: selectedStore?.id, label: `${selectedStore?.brchTp} ${selectedStore?.name}`});
        if (branchId) {
            axios.post(`/branch/info/${branchId}`)
                .then(({data: branchInfoResponse}) => {
                    setBranch(branchInfoResponse);
                });
            storeFloorDataGridStore.searchCondition.set('id', branchId);
            storeFloorDataGridStore.refresh();
            fetchStoreMemo(true, branchId);
        }
    }, [branchId]);

    const onClearSelect = () => {
        if (selectInputRef.current) {
            selectInputRef.current.clearValue();
        }
    };

    const fetchStoreMemo = (reset = true, store) => {
        if (!reset)
            setPage(page + 1);
        else {
            if (scrollRef)
                scrollRef.scrollTop = 0;
            setPage(1);
        }
        setIsFetching(true);
        axios.post('/branch/search/memo', {
            keyword,
            storeId     : store || '0000',
            pagingOption: {
                page,
                rowsPerPage: 10
            }
        })
            .then((response) => {
                let {data: {items, rowsCount}} = response;
                if (reset) {
                    setStoreMemoMap(new Map(items.map(store => ([store.store_seq, store]))));
                } else {
                    setStoreMemoMap(new Map(storeMemos.map(store => ([store.store_seq, store])).concat(items.map(store => ([store.store_seq, store])))));
                }
                setRowsCount(rowsCount);
            })
            .catch(e => console.log(e))
            .finally(() => setIsFetching(false));
    };

    const ps = new
    PerfectScrollbar('#container', {
        suppressScrollX: true
    });
    const storeMemos = Array.from(storeMemoMap.values());

    const load = () => {
        const {REACT_APP_ROAD_SHOW_URL, REACT_APP_ROAD_SHOW_ACCESS_TOKEN} = process.env;
        axios.create().get(`/traders/roadshow/${storeId}`)
            .then(res => {
                setItems(res.data);
            })
            .catch(e => {
                setItems([]);
            });
    };
    useEffect(() => {
        load();
    }, [storeId]);

    const filteredRoadShow = items.filter( roadShow => {
        return selectedStatus === roadShow.status && (keyword ? roadShow.title.indexOf(keyword) > -1 : true)
    })

    const sendRoadShowMessage = (roadShow) => {
        axios.post(
            `/traders/send/roadshow/message`,
            {
                    ticket,
                    roadShow
                }
        ).then(() => {
            console.log('메시지 전송.');
        })
        // currentTextareaChange(
        //     `${title}\n` +
        //     `테마: ${gcode_name}\n` +
        //     `위치: ${location}\n` +
        //     `기간: ${from_date ? moment(from_date).format('YY-MM-DD') : ''} ~ ${due_date ? moment(due_date).format('YY-MM-DD') : ''}`
        // );
    }


    return (

        <div className={Styles.Container}>
            <div className={Styles.Title}>로드쇼 정보</div>
            <div className={Styles.SearchBar}>
                <FormGroup className={`${Styles.EventStatus}`}>
                    <Row className={'w-100'}>

                        <Col md={12} sm={12} xl={4} xs={12} lg={12}>
                            <InputGroup>
                                <Button onClick={() => {
                                    setButtonId('Progress');
                                    setSelectedStatus('운영중')

                                }} variant={buttonId === 'Progress' ? 'primary' : 'outline-primary'}
                                        className={buttonId === 'Progress' ? Styles.Active : Styles.NonActive}
                                >
                                    행사진행중
                                </Button>
                                <Button onClick={() => {
                                    setButtonId('Scheduled')
                                    setSelectedStatus('예정')
                                }} variant={buttonId === 'Scheduled' ? 'primary' : 'outline-primary'}
                                        className={buttonId === 'Scheduled' ? Styles.Active : Styles.NonActive}
                                >
                                    행사 예정
                                </Button>
                            </InputGroup>
                        </Col>

                        <Col md={12} sm={12} xl={4} xs={12} lg={12}>
                            <Select
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                ref={storeRef}
                                isSearchable={true}
                                isClearable={true}
                                name="color"
                                value={appStore.selectedBranchOption(branchId)}
                                options={appStore.trBranchesOptions}
                                onChange={option => {
                                    if (option) {
                                        setStoreId(option.value);
                                    } else {
                                        setStoreId('0000');
                                    }
                                    fetchStoreMemo(true, option?.value);
                                }}
                                placeholder={'전체'}
                            />
                        </Col>

                        <Col md={12} sm={12} xl={4} xs={12} lg={12}>
                            <div className={Styles.TextSearch}>
                                <TextInput placeholder="검색어"
                                           value={keyword}
                                           onChange={(e) => {
                                               setKeyword(e.target.value);
                                           }}
                                           style={{padding: '0.365rem 0.5rem'}}
                                />
                            </div>
                        </Col>
                    </Row>
                </FormGroup>
            </div>

            <div className={Styles.LoadShowCards}>
                <div className={`overflow-auto flex-default d-flex flex-column pt-2`} style={{height: 450, flex: '1 1 0'}}>
                    <PerfectScrollbar>
                        <Row className={'w-100'}>
                            {
                                filteredRoadShow.map(roadshow =>
                                    <Col md={6} sm={6} xl={6} xs={6} lg={6}>
                                        <div className={Styles.LoadShowCardWrap}>
                                            <div className={Styles.StoreMemoCard}>
                                                <div className={Styles.Title}>
                                                    <Badge bg={'brand'} text={'dark'} className={`me-1`}>T</Badge><span>{roadshow.store_name} {roadshow.title}</span>
                                                </div>
                                                <div className={Styles.SendIcon}>
                                                    <Button variant={'primary'} size={'sm'} className={`p-1 d-flex justify-content-center align-items-center`}
                                                            onClick={() => sendRoadShowMessage(roadshow)}
                                                    >
                                                        <Send2 size={`1.2rem`} color={'#fff'}/>
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className={Styles.EventLocationDesc}>
                                                <div className={Styles.Thema}>
                                                    <Badge bg={'light'} text={'dark'} className={`me-1`}>테마</Badge><span>{roadshow.gcode_name}</span>
                                                </div>
                                                <div className={Styles.Location}>
                                                    <Badge bg={'light'} text={'dark'} className={`me-1`}>위치</Badge><span>{roadshow.location}</span>
                                                </div>
                                                <div className={Styles.Period}>
                                                    <Badge bg={'light'} text={'dark'} className={`me-1`}>기간</Badge>
                                                    <span>
                                                    {moment(roadshow.from_date).format('YY-MM-DD')}
                                                        &nbsp;~&nbsp;
                                                        {moment(roadshow.due_date).format('YY-MM-DD')}
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    );
};

RoadshowTab.propTypes = {
};

export default RoadshowTab;
