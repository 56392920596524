import {useEffect, useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import Styles from "./LostTab.module.scss";
import TextField from "@mui/material/TextField";
import axios from "axios";
import appStore from "../../store/AppStore";
import Stack from '@mui/material/Stack';
import Skeleton from "@mui/material/Skeleton";
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { koKR } from "@mui/x-date-pickers";
import koLocale from "date-fns/locale/ko";
import { startOfMonth, format, parseISO } from 'date-fns'; 
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';


const collator = new Intl.Collator('kr-KR');

const rows = [
    {seq: 14, lost_date: '2023-06-25', lost_item: '오뚜기 사천짜장', location: '12번 계산대', status: '미습득', reporter: ''},
    {seq: 15, lost_date: '2023-06-04', lost_item: '우리카드', location: '매장', status: '습득', reporter: ''},
    {seq: 16, lost_date: '2023-06-05', lost_item: '아디다스 캡 모자', location: '매장', status: '미습득', reporter: ''},
    {seq: 17, lost_date: '2023-06-25', lost_item: '빨간색 지갑 11시경 현금 있음 빨간색 지갑 11시경 현금 있음', location: '12번 계산대', status: '미습득', reporter: ''},
    {seq: 18, lost_date: '2023-06-04', lost_item: '우리카드', location: '매장', status: '습득', reporter: ''},
    {seq: 19, lost_date: '2023-06-05', lost_item: '아디다스 캡 모자', location: '매장', status: '미습득', reporter: ''},
    {seq: 20, lost_date: '2023-06-25', lost_item: '오뚜기 사천짜장', location: '12번 계산대', status: '미습득', reporter: ''},
    {seq: 21, lost_date: '2023-06-04', lost_item: '우리카드', location: '매장', status: '습득', reporter: ''},
    {seq: 22, lost_date: '2023-06-05', lost_item: '아디다스 캡 모자', location: '매장', status: '미습득', reporter: ''},
];

const LostTab = ({branchId, setModalOpen, modalOpen}) => {
    const { REACT_APP_LOST_FOUND_URL, REACT_APP_LOST_FOUND_ACCESS_TOKEN, REACT_APP_PRODUCT_SEARCH_LAMBDA_API } = process.env;
    const ROWS_PER_PAGE = 10;

    const [store, setStore] = useState({});
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [order, setOrder] = useState('asc');
    const [isAsc, setIsAsc] = useState(true);
    const [orderBy, setOrderBy] = useState('seq');
    const [typeCode, setTypeCode] = useState('found');
    const [columns, setColumns] = useState([]);
    const [fromDate, setFromDate] = useState(startOfMonth(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [description, setDescription] = useState('');
    const [items, setItems] = useState([]);
    
    useEffect(() => {
        const selectedStore = appStore.branches.find(branch => branch.id == branchId) || appStore.branches[0];
        const storeName = selectedStore?.name?.replaceAll(/EM|TR/g, '');

        setStore({
            value: selectedStore.id,
            label: `${storeName}·${selectedStore.id}`
        });

    }, [branchId]);

    useEffect(() => {
        if (typeCode === 'lost') {
            setColumns([
                {
                    field: 'lose_date',
                    headerName: '분실일자',
                    align:'center',
                },
                {
                    field: 'description',
                    headerName: '분실물 내역',
                    sortable: true,
                    align:'center',
                },
                {
                    field: 'lose_place_name',
                    headerName: '분실물 장소',
                    sortable: true,
                    align:'center',
                },
                {
                    field: 'status',
                    headerName: '상태',
                    sortable: true,
                    align:'center',
                },
                {
                    field: 'return_empolyee_number',
                    headerName: '불출자',
                    sortable: true,
                    align:'center',
                },
            ]);
            fetchLostItems();
        } else {
            setColumns([
                {
                    field: 'find_date',
                    headerName: '습득일자',
                    align:'center',
                },
                {
                    field: 'description',
                    headerName: '습득물 내역',
                    sortable: true,
                    align:'center',
                },
                {
                    field: 'find_place_name',
                    headerName: '습득물 장소',
                    sortable: true,
                    align:'center',
                },
                {
                    field: 'status',
                    headerName: '상태',
                    sortable: true,
                    align:'center',
                }
            ]);
            fetchFoundItems();
        }
    }, [typeCode]);

    useEffect(() => {
        if (!modalOpen && typeCode === 'lost') {
            fetchLostItems();   
        }
    }, [modalOpen]);

    const fetchLostItems = () => {
        setLoading(true);

        axios.post(
            `/lost_and_found/lose_item_search`,
            {
                store_code: branchId,
                lose_date: format(toDate, 'yyyyMMdd'),
                description: description,
                lose_place_name: '',
                lose_customer_name: ''
            },
            {
                baseURL: REACT_APP_LOST_FOUND_URL,
                withCredentials: false,
                headers : {
                    'username': appStore.username,
                    'emquest_access_token': REACT_APP_LOST_FOUND_ACCESS_TOKEN
                }
            }
        ).
        then(response => {
            const {data} = response;
            setItems(data.result_data);
            setTotalCount(data.result_count);
            setLoading(false);
            setError(false);
        }).
        catch(error => {
            setLoading(false);
            setError(true);
        })
    }

    const fetchFoundItems = () => {
        setLoading(true);

        axios.post(
            `/lost_and_found/found_item_search`,
            {
                store_code: branchId,
                found_date: format(toDate, 'yyyyMMdd'),
                description: description,
                founnd_place: ''
            },
            {
                baseURL: REACT_APP_LOST_FOUND_URL,
                withCredentials: false,
                headers : {
                    'username': appStore.username,
                    'emquest_access_token': REACT_APP_LOST_FOUND_ACCESS_TOKEN
                }
            }
        ).
        then(response => {
            const {data} = response;
            setItems(data.result_data);
            setTotalCount(data.result_count);
            setLoading(false);
            setError(false);
        }).
        catch(error => {
            setLoading(false);
            setError(true);
        })
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleRequestSort = (event) => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
        setIsAsc(!isAsc);
        setOrderBy(event);

        const sortedItems = [...items];
        sortedItems.sort((a, b) => {
            let val1 = a, val2 = b;
            
            // string 
            if (typeof val1[event] === 'string' && typeof val2[event] === 'string') {
                return isAsc ? collator.compare(val2[event], val1[event]) : collator.compare(val1[event], val2[event]);
            }

            // number
            return isAsc ? val1[event] - val2[event] : val2[event] - val1[event];
        });
        setItems(sortedItems);
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleSearch();
        }
    }

    const handleRadioChange = (e) => {
        setTypeCode(e.target.value);
    }

    const handleSearch = () => {
        if (typeCode === 'lost') {
            fetchLostItems();
        } else {
            fetchFoundItems();
        }
    }

    const handleToDate = (date) => {
        setFromDate(startOfMonth(new Date(date)));
        setToDate(date);
    }

    return (
        <div className={Styles.LostTabWrap}>
            <div className={Styles.HeaderWrap}>
                <div className={Styles.TitleWrap}>
                    <div className={Styles.Title}>
                        {typeCode === 'lost' ? '분실물' : '습득물'} 조회
                    </div>
                    <div className={`d-flex px-1 mx-1 ${Styles.StoreBadge}`}>
                        <span>{store.label}</span>
                    </div>
                </div>
                <RadioGroup
                    className={Styles.RadioGroup}
                    aria-labelledby="lost-found-buttons"
                    name="lost-found-buttons"
                    value={typeCode}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel 
                        className={Styles.RadioWrap}
                        value="found"
                        control={
                            <Radio 
                                size="small" 
                                sx={{
                                "&.Mui-checked": {
                                    color: "#009688",
                                    },
                                padding: '5px'
                                }}/>
                        } 
                        label={<div className={Styles.RadioButton}>습득물</div>} 
                    />
                    <FormControlLabel 
                        className={Styles.RadioWrap}
                        value="lost"
                        control={
                            <Radio 
                                size="small" 
                                sx={{
                                "&.Mui-checked": {
                                    color: "#009688",
                                    },
                                padding: '5px'
                                }}/>
                            } 
                        label={<div className={Styles.RadioButton}>분실물</div>} />
                </RadioGroup>
            </div>
            <div className={Styles.SearchConditionWrap}>
                <div className={Styles.DateRangeContainer}>
                    <div className={Styles.DateRangTitle}>
                        조회 기간을 선택하세요.
                    </div>
                    <div className={Styles.DateRangeWrap}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={koLocale}
                            localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <DatePicker
                                className={Styles.DateRange}
                                okButtonLabel="text"
                                toolbarFormat="MMMM dd"
                                inputFormat={"yyyy-MM-dd"}
                                mask={"____-__-__"}
                                value={fromDate}
                                disabled={true}
                                onChange={() => {}}
                                renderInput={(params) => (
                                    <TextField {...params} size="small" />
                                )}
                                toolbarTitle="날짜를 선택하세요"
                            />
                        </LocalizationProvider>
                        <span className={Styles.DateDash}>-</span>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={koLocale}
                            localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                        <DatePicker
                            className={Styles.DateRange}
                            okButtonLabel="text"
                            toolbarFormat="MMMM dd"
                            inputFormat={"yyyy-MM-dd"}
                            mask={"____-__-__"}
                            value={toDate}
                            onChange={handleToDate}
                            renderInput={(params) => (
                                <TextField {...params} size="small" />
                            )}
                            toolbarTitle="날짜를 선택하세요"
                        />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className={Styles.KeywordContainer}>
                    <div className={Styles.KeywordTitle}>
                        내용을 입력하세요.
                    </div>
                    <div className={Styles.KeywordWrap}>
                        <InputGroup>
                            <Form.Control 
                                className={Styles.Keyword}
                                placeholder={'검색어를 입력하세요.'}
                                value={description}
                                onChange={e=>{
                                    setDescription(e.target.value);
                                }}
                                onKeyDown={onKeyDown}
                            />
                        </InputGroup>
                        <Button className={Styles.KeywordButton} variant="outlined" size="small" startIcon={<SearchIcon fontSize="small" />} onClick={handleSearch}>
                            조회하기
                        </Button>
                    </div>
                </div>
            </div>
            <div className={Styles.ButtonWrap}>
                <Button 
                    className={`${Styles.AddButton} ${typeCode === 'found' && Styles.Disabled}`} 
                    disabled={typeCode === 'found'}
                    variant="outlined" 
                    startIcon={<AddIcon fontSize="small" />} 
                    onClick={() => setModalOpen(true)} >
                    분실물 등록
                </Button>
            </div>
            {
                loading ? (
                <Box
                    sx={{
                      marginTop: 3
                    }}
                  >
                    {[...Array(11)].map((_, idx) => (
                      <Skeleton key={idx} variant="rounded" height={30} sx={{ my: 1, mx: 1 }} animation="wave" />
                    ))}
                  </Box>
                ) : (
                    <>
                        <div style={{ height: '610px', width: '100%', margin: '1rem 0', minWidth: '400px' }}>
                            <TableContainer component={Paper} className={Styles.TableContainer} >
                                <Table sx={{ width: '100%' }} aria-label="product-search-table">
                                    <TableHead>
                                        <TableRow>
                                            {
                                                columns.map(col => (
                                                    <TableCell 
                                                        sx={{ 
                                                            padding: '16px 0px',
                                                            fontSize: '0.9rem',
                                                            fontWeight: 700,
                                                            fontFamily: 'Noto Sans'
                                                        }}
                                                        key={col.field} 
                                                        align={col.align} 
                                                        sortDirection={orderBy === col.field ? order : false}>
                                                            <TableSortLabel
                                                                className={Styles.TabelCell}
                                                                active={orderBy === col.field}
                                                                direction={isAsc ? 'asc' : 'desc'}
                                                                onClick={() => handleRequestSort(col.field)}
                                                                >
                                                                {col.headerName}
                                                                <Box component="span" sx={visuallyHidden}>
                                                                </Box>
                                                            </TableSortLabel>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.map((row, idx) => (
                                            <TableRow
                                                key={idx}
                                                sx={{ 
                                                    '&:last-child td, &:last-child th': { border: 0 },
                                                    backgroundColor: (idx % 2 == 0 && '#F8F9FA')
                                                }}
                                            >
                                                <TableCell component="th" scope="row" align="center" className={Styles.tableCellTrucate}>
                                                    {format(parseISO(row?.lose_date || row?.register_date), 'yyyy-MM-dd')}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="left" className={Styles.tableCellTrucate}>
                                                    <Tooltip title={<span className={Styles.Event}>{row.description}</span>} >
                                                        <span className={Styles.tableCellTrucate} >
                                                            {row.description}
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="left" className={Styles.tableCellTrucate}>
                                                    {row?.lose_place_name || row?.find_place_name}
                                                </TableCell>
                                                <TableCell align="center" className={Styles.tableCellTrucate}>
                                                    {
                                                        typeCode === 'lost' ? (
                                                            !!row.return_empolyee_number ? '습득' : '미습득'
                                                        ) : (
                                                            !!row.return_empolyee_number ? '고객불출' : '보관중'
                                                        )
                                                    }
                                                </TableCell>
                                                {
                                                    typeCode === 'lost' && (
                                                    <TableCell align="center" className={Styles.tableCellTrucate}>
                                                        {row?.return_empolyee_number}
                                                    </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                items?.length === 0 && 
                                (<div className={Styles.NoRowsWrap}>
                                    <div className={Styles.NoRows}>
                                        {
                                            error ? '오류가 발생했습니다.' : '조회 결과가 없습니다.'
                                        }
                                    </div>
                                </div>)
                            }
                            {/* <Stack spacing={5} sx={{ justifyContent: 'center', padding: '1rem 1rem', display: 'flex' }}>
                                <Pagination 
                                    sx={{ 
                                        [`& .Mui-selected`]: { backgroundColor: '#009688 !important', color: '#fff !important' },
                                        justifyContent: 'center', display: 'flex'
                                    }} 
                                    size="small" 
                                    count={Math.ceil(totalCount / ROWS_PER_PAGE)} 
                                    page={page}
                                    variant="outlined" 
                                    shape="rounded"
                                    onChange={handlePageChange} />
                            </Stack> */}
                        </div>
                    </>
                )
            }
        </div>
    );
};

LostTab.propTypes = {};

export default LostTab;
