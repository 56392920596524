import React, {Component} from 'react';
import {action, computed, makeObservable, observable, reaction, runInAction, toJS, values} from 'mobx';
import {observer} from 'mobx-react';
import {Accordion, Button, Col, Collapse, Form, Modal, Row, Spinner} from 'react-bootstrap';
import Section from "../layout/Section";
import RadioGroup from "../input/RadioGroup";
import appStore from "../../store/AppStore";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import {FiSearch} from "react-icons/fi";
import Select from "react-select";
import Styles from './CloseCollapse.module.scss';
import axios from "axios";
import {ClipboardText, Refresh} from "iconsax-react";
import FavorSelect from "../input/FavorSelect";
import {orgDialogStore} from "../dialog/OrgDialog";
import {NotificationManager} from "react-notifications";
import _ from 'lodash'
import PropTypes from "prop-types";
import {BsDot} from "react-icons/bs";
export const EMART_TICKET_STATUS = {
    ACTIVE     : 'ACTIVE',
    HOLD       : 'HOLD',
    CLOSE_DRAFT: 'CLOSE_DRAFT',
    CLOSED     : 'CLOSED'
};

@observer
class CloseCollapse extends Component {


    @observable
    isCloseCollapseOpen = false;

    @observable
    isSubmitting = false;

    currentTab;

    constructor(props) {
        super(props);
        let ticket = this.props?.ticket;

        if (ticket) {
            this.initData(ticket);

        }
        makeObservable(this);
    }

    @computed
    get selectedOrg() {
        return appStore.orgsOptionMap?.get(this.closeInfo?.orgId);
    }

    @computed
    get selectedCategory() {
        return appStore.categoryOptions?.find(code => code?.value === this.closeInfo?.categoryId);
    }

    @computed
    get selectedManageKeyword() {
        return appStore.managedKeyword?.find(code => code?.value === this.closeInfo?.keywordId);
    }

    @computed
    get closeInfo() {
        let ticket = this.props?.ticket;
        let result = appStore.closeCollapses.get(ticket?.id);
        return result;
    }

    @computed
    get isChanged() {
        const ticket = this.props.ticket;
        const closedType = ticket?.closedType === EMART_TICKET_STATUS.ACTIVE ? EMART_TICKET_STATUS.CLOSE_DRAFT : (ticket?.closedType || EMART_TICKET_STATUS.CLOSE_DRAFT);
        const defaultTicket = {
            categoryId: ticket?.category?.categoryId,
            orgId     : parseInt(ticket?.category?.orgId),
            closedType,
            keywordId : ticket?.category?.keywordId,
            keyword   : ticket?.category?.keyword || '',
            description      : ticket?.category?.description || '',
        }

        return !([
            'categoryId',
            'orgId',
            'closedType',
            'keywordId',
            'keyword',
            'description'
        ].every(propKey =>
            this.isEqualProp(propKey, defaultTicket, this.closeInfo)
        ));
    }

    @action.bound
    onBackupCategory() {
        const {ticket, toastRef} = this.props;
        const targetTab = /^\/main\/chat\/ACTIVE\/?.*/.test(HISTORY.location.pathname) ?
            'CLOSED'
            :
            /^\/main\/chat\/CLOSED\/?.*/.test(HISTORY.location.pathname) ? 'ACTIVE'
                :
                this.props.tab;

        if (toastRef.current) {
            toast.dismiss(toastRef.current);
        }


        toastRef.current = toast.info(
            ({closeToast, toastProps}) =>
                <div className={Styles.TempCategory}>
                    <div className={Styles.Title}> 작성중인 종료 카테고리 정보가 있습니다. <span className={Styles.Number}>- Ticket-{ticket.id}</span></div>
                    <div className={Styles.Buttons}>
                        <Button variant={'outline-secondary'} size={'sm'} className={`me-2`} onClick={closeToast}>닫기</Button>
                        <Button variant={'outline-info'} size={'sm'}
                                onClick={() => {
                                    navigate(`/main/chat/${targetTab}/${ticket.channelUrl}`);
                                    closeToast();
                                }}
                        >
                            계속작성
                        </Button>
                    </div>
                </div>,
            {
                autoClose   : false,
                closeButton : false,
                closeOnClick: false,
                icon        : () => <ClipboardText
                    size="40"
                    color="#54c7ec"
                    variant="Outline"
                />,
            }
        );
    };

    componentWillUnmount() {
        this.isCloseCollapseOpen = false;
        /*if (this.isChanged) {
            this.onBackupCategory();
        }*/
    }

    // componentDidMount() {
    //     appStore.getManagedKeyword();
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let prevTicket = prevProps?.ticket;
        const {tab, ticket} = this.props;

        if (prevTicket?.id !== ticket?.id) {
            this.isCloseCollapseOpen = false;
            const tempTicket = {closedType: EMART_TICKET_STATUS.CLOSE_DRAFT, ...prevTicket?.category};
            if ([
                'categoryId',
                'orgId',
                'closedType',
                'keywordId',
                'keyword',
                'description'
            ].every(propKey =>
                this.isEqualProp(propKey, tempTicket, toJS(appStore.closeCollapses.get(tempTicket.id)))
            )) {
                // 어떤 프로퍼티의 변화도 없으면 기존 임시 데이터는 삭제한다.
                appStore.closeCollapses.delete(tempTicket.id);
            }
            if(ticket) {
                this.initData(ticket);
            }
        }

    }

    initData(ticket) {
        let isCloseOpen = ticket.isCloseOpen || false;
        let category = ticket?.category;
        const closeCollapses = appStore.closeCollapses;
        const tempData = closeCollapses.get(ticket.id);
        const closedType = ticket?.closedType === EMART_TICKET_STATUS.ACTIVE ? EMART_TICKET_STATUS.CLOSE_DRAFT : (ticket?.closedType || EMART_TICKET_STATUS.CLOSE_DRAFT);
        const data = tempData || {
            categoryId: category?.categoryId,
            orgId     : parseInt(category?.orgId),
            closedType,
            keywordId : category?.keywordId,
            keyword   : category?.keyword || '',
            description      : category?.description || '',
        };
        closeCollapses.set(ticket.id, data);

        if (!data?.orgId) {
            this.selectRef?.clearValue();
        }

        if (!data?.categoryId) {
            this.categoryRef?.clearValue();
        }

        if (!data?.keywordId) {
            this.keywordRef?.clearValue();
        }
    }

    isEqualProp(propKey, obj1, obj2) {
        const prop1 = obj1?.[propKey];
        const prop2 = obj2?.[propKey];

        if (typeof (prop1) === 'string' || typeof (prop2) === 'string') {
            return (prop1 || '') === (prop2 || '');
        } else {
            return (prop1 || null) === (prop2 || null);
        }
    }

    handleChange(propKey, value) {
        if (!this.closeInfo)
            return;
        let ticket = this.props?.ticket;
        appStore.closeCollapses.set(
            ticket?.id,
            {...this.closeInfo, [propKey]: value}
        );
    }

    handleSave = async () => {
        const {ticket, onSave} = this.props;
        const closeInfo = this.closeInfo;
        if (!closeInfo.categoryId) {
            NotificationManager.warn('알림', '카테고리를 입력해주세요.');
            return;
        }

        const newTicket = {
            ...ticket,
            category : this.closeInfo
        };

        try {
            this.isSubmitting = true;
            await axios.post('/ticket/category', newTicket);
            NotificationManager.info('채팅 카테고리를 저장하셨습니다.');
            onSave && onSave({
                ...newTicket,
                responded: true,
            });
        } catch (e) {
            NotificationManager.error('채팅 카테고리를 저장 할 수 없었습니다. 잠시 후 다시 시도해 주세요.');
        } finally {
            this.isCloseCollapseOpen = false;
            this.isSubmitting = false;
        }
    };

    handleCancel = () => {
        const ticket = this.props.ticket;
        appStore.closeCollapses.delete(ticket?.id);
        this.initData(ticket);
    };

    render() {
        let {ticket, name} = this.props;
        return (
            <div className={Styles.CloseCollapse}>
                <div className={Styles.Opener}
                     onClick={() => {
                         this.isCloseCollapseOpen = !this.isCloseCollapseOpen;
                     }}
                >
                    <div>
                        채팅 카테고리 선택
                    </div>
                    <div>
                        {this.isCloseCollapseOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </div>

                </div>
                <Collapse in={this.isCloseCollapseOpen} className={Styles.Collapse}>
                    <div>
                        <div className={Styles.Content}>
                            <Section title={'종료타입'} required>

                                <RadioGroup name={`${name}_emartStatus_${ticket.id}`} value={this.closeInfo?.closedType}
                                            onChange={e => {
                                                this.handleChange('closedType', e.target.value);
                                            }}
                                >
                                    <Form.Check className={'me-3'} type="radio" label="1차 종결" value={EMART_TICKET_STATUS.CLOSE_DRAFT}/>
                                    <Form.Check className={'me-3'} type="radio" label="점포 확인 후 종결" value={EMART_TICKET_STATUS.CLOSED}/>
                                </RadioGroup>

                            </Section>

                            <Section title="카테고리" required>
                                <Form.Group as={Row}>
                                    <Col md={12} className="mb-2">
                                        <Select
                                            styles={{menuPortal: base => ({...base, zIndex: 9})}}
                                            ref={ref => this.categoryRef = ref}
                                            menuPortalTarget={document.body}
                                            menuShouldBlockScroll={true}
                                            // isLoading={isLoading}
                                            isSearchable={true}
                                            isClearable={true}
                                            name="color"
                                            options={appStore.categoryOptions}
                                            onChange={e => {
                                                this.handleChange('categoryId', e?.value || null);
                                            }}
                                            placeholder={'카테고리 선택'}
                                            value={this.selectedCategory}
                                        />
                                    </Col>
                                </Form.Group>
                            </Section>

                            <Section title="관리부서" required rightIcon={
                                <Button variant="primary" className="d-flex align-items-center" style={{color: '#fff'}} onClick={() => {
                                    orgDialogStore.open(orgId => {
                                        console.log(orgId)
                                        this.handleChange('orgId', orgId);
                                    });
                                }}>
                                    <FiSearch/>
                                </Button>
                            }>
                                <Form.Group as={Row}>
                                    <Col md={12} className="mb-2">
                                        <FavorSelect
                                            ref={ref => this.selectRef = ref}
                                            options={appStore.orgsOptions}
                                            onChange={e => {
                                                this.handleChange('orgId', e?.value || null);
                                            }}
                                            placeholder={'부서 선택'}
                                            value={this.selectedOrg}
                                            isFavorFn={v => appStore.favorOrgIds.has(v.toString())}
                                            onFavorToggle={(k) => {
                                                if (appStore.favorOrgIds.has(k.toString()))
                                                    appStore.favorOrgIds.delete(k.toString());
                                                else
                                                    appStore.favorOrgIds.add(k.toString());

                                                axios.post('/agent/favorites/org/update', values(appStore.favorOrgIds))
                                                    .then();

                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Section>
                            <Section title="등록상세">
                                <Col md={12} className="mb-2">
                                    <Form.Control value={this.closeInfo?.description}
                                                  onChange={e => {
                                                      this.handleChange('description', e?.target?.value || null);
                                                  }}
                                                  rows={5}
                                                  placeholder="메모를 입력하세요"
                                    />
                                </Col>
                            </Section>
                            <Section title="키워드">
                                <Form.Group as={Row}>
                                    <Col md={12} className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <Select
                                                styles={{menuPortal: base => ({...base, zIndex: 9})}}
                                                ref={ref => this.keywordRef = ref}
                                                menuPortalTarget={document.body}
                                                menuShouldBlockScroll={true}
                                                // isLoading={isLoading}
                                                isSearchable={true}
                                                isClearable={true}
                                                name="keyword"
                                                options={appStore.managedKeyword}
                                                onChange={e => {
                                                    this.handleChange('keywordId', e?.value || null);
                                                    if (e?.value && e?.value !== '99') {
                                                        this.handleChange('keyword', e?.label);
                                                    }

                                                }}
                                                placeholder={'선택'}
                                                value={this.selectedManageKeyword}
                                            />
                                        </div>
                                        <Button variant="secondary" className="d-flex justify-content-center align-items-center ms-1"
                                                // onClick={appStore.getManagedKeyword}
                                        >
                                            <Refresh size={14}/>
                                        </Button>
                                    </Col>
                                    <Col md={12} className={`mb-2`}>
                                        <Form.Control value={this.closeInfo?.keyword}
                                                      onChange={e => {
                                                          this.handleChange('keyword', e.target.value);
                                                      }}
                                                      rows={5}
                                                      placeholder="직접입력"
                                                      disabled={this.closeInfo?.keywordId !== '99'}
                                        />
                                    </Col>
                                </Form.Group>
                            </Section>
                            <div className={`d-flex justify-content-end mb-2`}>
                                {

                                    this.isChanged &&
                                    <div className="d-flex align-items-center">
                                        <BsDot color="#EF503B" size={32}/>
                                    </div>
                                }
                                <Button variant="outline-secondary" className={'me-2'}
                                        onClick={this.handleCancel}
                                        disabled={!this.isChanged}
                                >
                                    취소
                                </Button>
                                <Button variant="outline-secondary" className={'me-2'} onClick={() => {
                                    this.isCloseCollapseOpen = !this.isCloseCollapseOpen;
                                }}>닫기</Button>
                                <Button onClick={this.handleSave.bind(this)} variant="primary" style={{color: '#fff'}}
                                        disabled={!this.closeInfo?.categoryId || !this.closeInfo?.orgId}
                                >
                                    {
                                        this.isSubmitting ?
                                            <Spinner animation="border" variant="secondary"/>
                                            :
                                            <span>저장</span>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        );
    }

}

CloseCollapse.propTypes = {
    ticket          : PropTypes.any,
    tab             : PropTypes.string,
    isBackupCategory: PropTypes.bool,
};

CloseCollapse.defaultProps = {
    isBackupCategory: true
};

export {CloseCollapse as default};
