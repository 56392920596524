import React, {Component} from 'react';
import {Image} from 'react-bootstrap';
import RandomColor from './RandomColor';
import DefaultAvatar from './DefaultAvatar';
import _ from 'lodash';
import DefaultChatbotImage from './DefaultChatbotImage';
import DefaultTeamImage from './DefaultTeamImage';
import PropTypes from 'prop-types';

class Avatar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            images: [],
            index: 0
        }
    }

    componentDidMount() {

        const {src} = this.props;
        if (src) {
            let thumbnail = src;
            this.setState({
                images: [thumbnail, src]
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {src} = this.props;
        if (prevProps.src !== src && src) {
            let thumbnail =  src;
            this.setState({
                isError: false,
                images: [thumbnail, src]
            })
        }
    }

    randomAvatar = () => {
        let {className = 'rounded-circle d-flex', seed, width = '100%', type} = this.props;
        return <RandomColor seed={seed} width={width}>
            {
                {
                    agent: <DefaultAvatar
                        className={`${className ? className : ''}`} {..._.omit(this.props, [`className`])}
                        style={{width: width}}/>,
                    team: <DefaultTeamImage
                        className={`${className ? className : ''}`} {..._.omit(this.props, [`className`])}
                        style={{width: width}}/>,
                    chatbot: <DefaultChatbotImage
                        className={`${className ? className : ''}`} {..._.omit(this.props, [`className`])}
                        style={{width: width}}/>
                }[type]
            }
        </RandomColor>
    }

    render() {
        let {className = 'rounded-circle d-flex', width = '100%'} = this.props;
        return (
            <>
                {
                    this.state.isError ?
                        <>{this.randomAvatar()}</>
                        :
                        <>
                            {
                                this.state.images.length > 0 ?
                                    <Image
                                        className={`${className ? className : ''}`} {..._.omit(this.props, [`className`])}
                                        style={{width: width, objectFit: "cover", aspectRatio: "1/1"}}
                                        src={this.state.images[this.state.index]}
                                        onError={(e) => {
                                            if (this.state.index < this.state.images.length - 1) {
                                                this.setState({
                                                    index: this.state.index + 1
                                                })
                                            } else {
                                                this.setState({
                                                    isError: true
                                                })
                                            }
                                        }}
                                    />
                                    :
                                    <>{this.randomAvatar()}</>
                            }
                        </>


                }
            </>
        );
    }
}

Avatar.propTypes = {
    type: PropTypes.string.isRequired,
    /* 타입 = agent, team, chatbot */
    src: PropTypes.string.isRequired,
    /* 이미지 경로 */
    seed: PropTypes.string.isRequired
    /* 랜덤이미지 생성시 고유 값 */

};

export default Avatar;