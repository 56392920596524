import React, {Component} from 'react';
import {observer} from "mobx-react";
import axios from "axios";
import Panel from "../../components/layout/Panel";
import ReactApexChart from "react-apexcharts";
import {Col, Row} from "react-bootstrap";
import {computed, makeObservable, observable} from "mobx";
import dayjs from "dayjs";
import CommonHelper from "../../helper/CommonHelper";
import InfoReport from "./InfoReport";
import Styles from "./Report.module.scss"

@observer
class SummaryReport extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @observable
    datas = null;

    @observable
    hourDatas = null;

    @computed
    get dayCategories(){
        return this.datas?.map(data => dayjs(data.createdAt).format('MM월 DD일'));
    }

    @computed
    get daysTicket() {

       return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "새 티켓",
                    data: this.datas?.map(data => data.createdTicketCount)
                },
                {
                    name: "종료된 티켓",
                    data: this.datas?.map(data => data.closedTicketCount)
                }
            ]
        }
    }

    @computed
    get hoursTicket() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.timeCategories()
                }
            },
            series: [
                {
                    name: "새 티켓",
                    data: this.hourDatas?.map(data => data.createdTicketCount)
                },
                {
                    name: "종료된 티켓",
                    data: this.hourDatas?.map(data => data.closedTicketCount)
                }
            ]
        }
    }

    @computed
    get daysMsg() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "상담원",
                    data: this.datas?.map(data => data.agentMessageCount)
                },
                {
                    name: "고객",
                    data: this.datas?.map(data => data.userMessageCount)
                }
            ]
        }
    }

    @computed
    get hoursMsg() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.timeCategories()
                }
            },
            series: [
                {
                    name: "상담원",
                    data: this.hourDatas?.map(data => data.agentMessageCount)
                },
                {
                    name: "고객",
                    data: this.hourDatas?.map(data => data.userMessageCount)
                }
            ]
        }
    }

    @computed
    get avgResponse() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + "s";
                        }
                    },
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "평균 최초 응답 시간",
                    data: this.datas?.map(data => data.avgResponseTime)
                }
            ]
        }
    }

    @computed
    get avgComplete() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                yaxis: {
                    labels: {
                        formatter: CommonHelper.timeFormat
                    },
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "평균 티켓 해결 시간",
                    data: this.datas?.map(data => data.avgCompleteTime)
                }
            ]
        }
    }

    @computed
    get ratingCount() {

        return {
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                    toolbar: {
                        show: true,
                    }
                },
                labels: ['5점','4점','3점','2점','1점'],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: "총 응답 수"
                                }
                            }
                        }
                    }
                }
            },
            series: [
                this.datas?.map(data => data.ratingFivePointCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.ratingFourPointCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.ratingThreePointCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.ratingTwoPointCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.ratingOnePointCount).reduce((x,y) => x+y)
            ]
        }
    }

    @computed
    get closeCount() {

        return {
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                    toolbar: {
                        show: true,
                    }
                },
                labels: ['상담원','고객','시스템'],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: "총 티켓 수"
                                }
                            }
                        }
                    }
                }
            },
            series: [
                this.datas?.map(data => data.agentCloseTicketCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.customerCloseTicketCount).reduce((x,y) => x+y)
                ,this.datas?.map(data => data.systemCloseTicketCount).reduce((x,y) => x+y)
            ]
        }
    }


    timeCategories(){
        let arr = [];
        for(let i = 0; i <= 12; i++){
            arr.push(`오전:${i}:00`);
        }
        for(let i = 1; i < 12; i++){
            arr.push(`오후:${i}:00`);
        }
        return arr;
    }

    componentDidMount() {
        this.fetchDetail();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {startDate, endDate} = this.props;
        if(prevProps.startDate !== startDate || prevProps.endDate !== endDate) {
            this.fetchDetail();
        }
    }

    fetchDetail = () =>{
        const {startDate, endDate} = this.props;
        axios.post(
            `/report/summary`,
            {
                startDate,
                endDate
            }
        ).then(res=>{
           this.datas = res.data?.summaryReports;
           this.hourDatas = res.data?.summaryHourReports;
        });
    }

    render() {
        return (
            <div className={Styles.SummaryContainer}>
                <div className={Styles.Panel}>
                    <InfoReport title={"티켓"} summary={"생성된 티켓 수와 종료된 티켓 수입니다."} />
                    {
                        this.datas &&
                        <ReactApexChart
                            options={this.daysTicket.options}
                            series={this.daysTicket.series}
                            type={"line"}
                        />
                    }
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"시간당 티켓"} summary={"시간당 생성된 티켓과 종료된 티켓 수입니다."} />
                    {
                        this.hourDatas &&
                        <ReactApexChart
                            options={this.hoursTicket.options}
                            series={this.hoursTicket.series}
                            type={"bar"}
                        />
                    }
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"메시지"} summary={"상담원과 고객이 주고받은 메시지 수입니다."} />
                    {
                        this.datas &&
                        <ReactApexChart
                            options={this.daysMsg.options}
                            series={this.daysMsg.series}
                            type={"line"}
                        />
                    }

                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"시간당 메시지"} summary={"시간당 상담원과 고객이 주고받은 메시지 수입니다."} />
                    {
                        this.hourDatas &&
                        <ReactApexChart
                            options={this.hoursMsg.options}
                            series={this.hoursMsg.series}
                            type={"bar"}
                        />
                    }
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"평균 최초 응답 시간"} summary={"상담원의 최초 응답 시간의 총합을 티켓을 배정받은 횟수로 나눈 수입니다. 이때, 상담원이 응답한 티켓 배정만 계산에 포함됩니다."} />
                    {
                        this.datas &&
                        <ReactApexChart
                            options={this.avgResponse.options}
                            series={this.avgResponse.series}
                            type={"line"}
                        />
                    }
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"평균 티켓 해결 시간"} summary={"티켓 해결 시간의 총합을 종료된 티켓 수로 나눈 수입니다. 티켓 해결 시간은 티켓 생성부터 티켓 종료까지 걸린 시간을 의미합니다."} />
                    {
                        this.datas &&
                        <ReactApexChart
                            options={this.avgComplete.options}
                            series={this.avgComplete.series}
                            type={"line"}
                        />
                    }
                </div>
                <div className={`${Styles.Panel} h-100`}>
                    <InfoReport title={"고객 만족도"} summary={"응답받은 고객 만족도 정보입니다."} />
                    {
                        this.datas &&
                        <div className={'d-flex h-100 justify-center align-items-center'}>
                            <ReactApexChart
                                options={this.ratingCount.options}
                                series={this.ratingCount.series}
                                type={"donut"}
                                width={380}
                            />
                        </div>
                    }
                </div>
                <div className={`${Styles.Panel} h-100`}>
                    <InfoReport title={"티켓 종료 주체"} summary={"역할별로 종료한 티켓 수를 보여줍니다."} />
                    {
                        this.datas &&
                        <div className={'d-flex h-100 justify-center align-items-center'}>
                            <ReactApexChart
                                options={this.closeCount.options}
                                series={this.closeCount.series}
                                type={"donut"}
                                width={380}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default SummaryReport;