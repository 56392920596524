import React, {Component} from 'react';
import {observer} from "mobx-react";
import {Breadcrumb, Button, Container} from "react-bootstrap";
import {Redirect, Route, Switch} from "react-router-dom";
import QuickMsgDetailView from "./QuickMsgDetailView";
import QuickMsgListView from "./QuickMsgListView";
import Frame from "../../components/layout/Frame";

@observer
class QuickMsgFrame extends Component {
    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/quickmsg/list' ? 'd-block' : 'd-none'} h-100`}>
                    <QuickMsgListView pathname={location.pathname}/>
                </div>
                <Switch>
                    <Route path="/main/quickmsg/new" component={QuickMsgDetailView}/>
                    <Route path="/main/quickmsg/detail/:id" component={QuickMsgDetailView}/>
                    <Redirect exact from="/main/quickmsg" to="/main/quickmsg/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default QuickMsgFrame;