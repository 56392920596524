import React, {Component, isValidElement} from 'react';
import {observer} from 'mobx-react';
import {Button, Col, FormControl, FormGroup, FormLabel, InputGroup, Row} from "react-bootstrap";
import Panel from "../../components/layout/Panel";
import Styles from "./IconRegistrationView.module.scss";
import AppStore from "../../store/AppStore";
import axios from "axios";
import * as url from "url";
import moment from "moment";
import validation from "../../components/validation/Validation";
import {NotificationManager} from "react-notifications";
import {navigateReplace} from "../../helper/HistoryHelper";
import FrameWrap from "../../components/layout/FrameWrap";
import _ from "lodash";

@observer
class IconRegistrationView extends Component {

    constructor(props) {
        super(props);
        this.state = {
              fileLocation : ''
            , url : ''
            , name : ''
        }
    }
    clearInput() {
        this.setState({
            fileLocation:'',
            url:'',
            name:'',
        })
    }



    componentDidMount() {
    }


    render() {
        let {className, setIconNameInputRef, dataGridStoreRefresh}=this.props
        return (
            <div className={`${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                <FrameWrap className={'w-100'}>
                    <Panel className={'w-100'}>
                        <Panel className={`${Styles.BorderLine} w-100 d-flex flex-column gap-2`}>
                            <FormGroup>
                                <Col className={`justify-content-between d-flex`}>
                                    <div className={`${Styles.Label} text-nowrap`}>
                                        아이콘 추가
                                    </div>
                                    <div className={'d-flex gap-2 justify-content-end w-100'}>
                                        <Button
                                            className={'text-nowrap'}
                                            variant={"outline-primary"}
                                            onClick={()=> {
                                                if(this.state.name.length === 0 ) {
                                                    NotificationManager.warning('이름을 입력해 주세요.');
                                                    this.iconNameInputRef.focus()


                                                }else if(this.state.url.length === 0 ){
                                                    NotificationManager.warning('파일을 선택해 주세요.');
                                                }

                                                else {
                                                    axios.post("/setting/icon/add",
                                                        {
                                                            image : {
                                                                url: this.state.url
                                                            },
                                                            name: this.state.name,
                                                            isActive : 1,
                                                            createdAt : moment()
                                                        }
                                                    ).then(r=>
                                                        {
                                                            console.log(r);
                                                            NotificationManager.success('아이콘이 저장되었습니다.')
                                                            dataGridStoreRefresh();
                                                            this.clearInput();
                                                        }
                                                    ).catch(e => console.error(e))
                                                }
                                            }}
                                        >


                                            + 아이콘 추가
                                        </Button>
                                        {/*<Button
                                                    variant={"outline-secondary"}
                                                    onClick={()=>{
                                                        this.clearInput();
                                                        close();
                                                    }}>
                                                    취소
                                                </Button>*/}
                                    </div>

                                </Col>
                            </FormGroup>
                            <Panel>
                                <div className={Styles.AddIcon}>
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={12} xl={1}>
                                            <div className={`${Styles.Label} mb-2 text-nowrap`}>
                                                아이콘 이름
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} xl={4}>
                                            <FormControl as={'input'}
                                                         placeholder={'이름을 입력하세요.'}
                                                         style={{resize:'none'}}
                                                         maxLength={16}
                                                         value={this.state.name}
                                                         onChange={(e)=>
                                                         {
                                                             this.setState({name: e.target.value});
                                                         }}
                                                         ref={(ref) => {
                                                             setIconNameInputRef(ref)
                                                             this.iconNameInputRef = ref
                                                         }}

                                            />
                                        </Col>
                                        <Col sm={12} md={12} xl={1} className={'mb-3'}/>
                                        <Col sm={12} md={12} xl={1} className={'text-nowrap'}>
                                            <FormLabel className={Styles.Label}>
                                                파일 등록
                                            </FormLabel>
                                        </Col>
                                        <Col sm={12} md={12}  xl={5} className={`d-flex flex-row gap-2`}>
                                            <FormControl
                                                as={'input'}
                                                readOnly={true}
                                                placeholder={'파일을 선택 해주세요.'}
                                                disabled={true}
                                                value={this.state.fileLocation}
                                                onClick={()=>this.fileInput.click()}
                                            />
                                            <input className={'d-none'}
                                                   type={'file'}
                                                   accept={"image/*"}
                                                   ref={(ref) => {
                                                       this.fileInput = ref;
                                                   }}
                                                   onChange={(e)=>{
                                                       /*console.log(e.target.files[0])*/
                                                       this.setState({fileLocation:e.target.files[0]?.name});

                                                       let formData = new FormData();

                                                       Array.from(e.target.files).forEach((file,idx)=>
                                                           formData.append('file', file)
                                                       )
                                                       axios.post(`/upload/draft`,
                                                           formData,
                                                           {
                                                               headers    : {
                                                                   'Content-Type' : 'multipart/form-data'
                                                               },
                                                               onUploadProgress : (e)=>{
                                                                   console.log(e)
                                                               }
                                                           }).then(r=>{
                                                           this.fileInput.value = '';
                                                           this.setState({url : r.data.url});
                                                       })
                                                   }}
                                            />
                                            <Button className={'text-nowrap'} variant={"outline-primary"}
                                                    onClick={()=>
                                                        this.fileInput.click()
                                                    }>

                                                파일 선택

                                            </Button>

                                        </Col>
                                    </FormGroup>
                                </div>
                            </Panel>
                        </Panel>
                    </Panel>
                </FrameWrap>
            </div>
        );
    }
}

export default IconRegistrationView;
