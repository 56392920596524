import React from 'react';
import {observer} from 'mobx-react';
import {Card, Container, Form, InputGroup} from 'react-bootstrap';
import {Responsive, WidthProvider} from "react-grid-layout";

const ResponsiveGrid = WidthProvider(Responsive);

@observer
class ResponsiveGridLayoutView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {
            layouts,
            cols=12,
            rowHeight = 50,
            isDraggable = true,
            isDroppable = true,
            isResizable = true,
            isLock = false,
            onLayoutChange,
            onBreakpointChange,
            margin=[],
            containerPadding=[],
            children
        } = this.props;
        return (
            <ResponsiveGrid
                style={{width:'100%'}}
                layouts={layouts}
                margin={margin}
                containerPadding={containerPadding}
                onLayoutChange={(layout,layouts)=>{
                    onLayoutChange && onLayoutChange(layout,layouts);
                }}
                onWidthChange={(a,b)=>{
                }}
                onBreakpointChange={(breakPoint, size)=>{
                    onBreakpointChange && onBreakpointChange(breakPoint, size)
                }}
                onDragStop={(next,prev)=>{
                }}
                onResizeStop={(next,prev)=>{
                }}
                className="layout"
                items={50}
                cols={{lg: cols, md: cols, sm: cols, xs: 6, xxs: 6}}
                rowHeight={rowHeight}
                // maxRows={maxRows}
                measureBeforeMount={false}
                useCSSTransforms={true}
                // compactType={null}
                preventCollision={false}

                isBounded={true}
                // allowOverlap={true}
                isDraggable={isDraggable}
                isResizable={isResizable}
                isDroppable={isDroppable}
                droppingItem={null}
                draggable={true}
                onDrop={(layout, layoutItem) => {
                }}
                resizeHandles={isLock ? [] : ['se']}
            >
                {
                    children
                }
            </ResponsiveGrid>
        );
    }


}

export default ResponsiveGridLayoutView;
