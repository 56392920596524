import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button, Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Modal, Row} from "react-bootstrap";
import {FormStore, Input, Validation} from "../components/validation";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {agentDataGridStore} from "./agent/AgentManageListView";
import {goBack, navigateReplace} from "../helper/HistoryHelper";
import Panel from "../components/layout/Panel";
import UploadImage from "../components/avatar/UploadImage";
import RandomColor from "../components/avatar/RandomColor";
import DefaultAvatar from "../components/avatar/DefaultAvatar";
import {ConfirmDialog, USER_TYPE} from "@qbot-chat/qbot-uikit";
import TitlePanelLabel from "../components/label/TitlePanelLabel";
import TitlePanel from "../components/layout/TitlePanel";
import AppStore from "../store/AppStore";
import FrameWrap from "../components/layout/FrameWrap";
import appStore from "../store/AppStore";

const form = new FormStore();

@observer
class AgentInfoChangeView extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        axios.post(
            `/agent/detail/${appStore.id}`,
            {}
        ).then(res=>{
            form.values = res.data;
        });
    }


    save(){
        if(!form.doCheckValid()){
            NotificationManager.warning(form.firstErrorMessage)
            return;
        }

        axios.post(
            `/user/update`,
            form.values
        ).then(res=>{
            let id = res.data
            appStore.fetchSessionInfo();
            NotificationManager.success('변경사항이 저장되었습니다.')
        })
    }

    render() {

        return (
            <FrameWrap className={'w-100'}>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={'개인정보 변경'}  />
                            <div>
                                <Button variant={"outline-secondary"}
                                        onClick={()=>{
                                            goBack();
                                        }}
                                >
                                    취소
                                </Button>
                                <Button variant={"outline-primary"}
                                        className={`ms-1`}
                                        onClick={()=>{
                                            this.save();

                                        }}
                                >
                                    저장
                                </Button>
                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel>

                    <Form onSubmit={e=>e.preventDefault()}>
                        <FormGroup as={Row}>
                            <Col className={`mb-3`}>
                                <FormLabel>
                                    이름
                                </FormLabel>
                                <Input store={form}
                                       label={`이름`}
                                       name={`name`}
                                       placeholder={`이름을 입력해주세요`}
                                       validations={[
                                           new Validation(/^.+$/, `이름을 입력하세요.`)
                                       ]}
                                       validOnBlur
                                />
                            </Col>
                            <Col className={`mb-3`}>
                                <FormLabel>
                                    이메일
                                </FormLabel>
                                <Input store={form}
                                       label={'이메일'}
                                       name={`email`}
                                       placeholder={`이메일 주소를 입력하세요.`}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row}>
                            <Col className={`mb-3`}>
                                <FormLabel>
                                    전화번호
                                </FormLabel>
                                <Input store={form}
                                       label={`전화번호`}
                                       name={`tel`}
                                       placeholder={`전화번호를 입력해주세요`}
                                />
                            </Col>
                            <Col className={`mb-3`}>
                                <FormLabel>
                                    주소
                                </FormLabel>
                                <Input store={form}
                                       label={'주소'}
                                       name={`address`}
                                       placeholder={`주소를 입력하세요.`}
                                />
                            </Col>
                        </FormGroup>


                        <FormGroup as={Row}>
                            <Col className={`mb-3`}>
                                <FormLabel>
                                    프로필이미지
                                </FormLabel>
                                <div className={`d-flex flex-row gap-2 w-100`}>

                                    <UploadImage url={form.valueMap.get('avatar.fullUrl')}
                                                 ref={ref => this.ref = ref}
                                                 useUrl={true}
                                                 useFile={true}
                                                 selectImage={(image) => {
                                                     form.setValue('avatar.id', null);
                                                     form.setValue('avatar.type', image.type);
                                                     form.setValue('avatar.url', image.url);
                                                     form.setValue('avatar.fullUrl', image.fullUrl);
                                                 }}
                                                 resetImage={() => {
                                                     form.setValue('avatar.id', null);
                                                     form.setValue('avatar.type', null);
                                                     form.setValue('avatar.url', null);
                                                     form.setValue('avatar.fullUrl', null);
                                                 }}
                                                 noImage={
                                                     <RandomColor seed={form.valueMap.get('id')}>
                                                         <DefaultAvatar/>
                                                     </RandomColor>
                                                 }
                                    />

                                </div>
                            </Col>
                            <Col>
                            </Col>
                        </FormGroup>
                        { [USER_TYPE.AGENT, USER_TYPE.AGENT_LEADER].includes(appStore?.type) &&
                            <FormGroup as={Row}>
                                <Col className={`mb-3`}>
                                    <FormLabel>
                                        인사말
                                    </FormLabel>
                                    <Input store={form}
                                           as={'textarea'}
                                           rows={3}
                                           label={`인사말`}
                                           name={`greeting`}
                                           placeholder={`입력하지 않은 경우, 기본 설정된 인사말이 출력 됩니다.`}
                                    />
                                </Col>
                            </FormGroup>
                        }
                    </Form>
                </Panel>
                <ConfirmDialog/>
            </FrameWrap>
        );
    }
}

export default AgentInfoChangeView;
