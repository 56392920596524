import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Breadcrumb, Button, Container} from "react-bootstrap";
import {Redirect, Route, Switch} from "react-router-dom";
import TeamManageDetailView from "./TeamManageDetailView";
import TeamManageListView from "./TeamManageListView";
import Frame from "../../components/layout/Frame";
import MemberManageListView from "../member/MemberManageListView";

@observer
class TeamManageFrame extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/team/list' ? 'd-block' : 'd-none'} h-100`}>
                    <TeamManageListView pathname={location.pathname} />
                </div>
                <Switch>
                    <Route path="/main/team/new" component={TeamManageDetailView}/>
                    <Route path="/main/team/detail/:id" component={TeamManageDetailView}/>
                    <Redirect exact from="/main/team" to="/main/team/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default TeamManageFrame;
