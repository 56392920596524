import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  ListGroup,
  Row,
} from "react-bootstrap";
import { FormStore, Input, Validation } from "../../components/validation";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { teamDataGridStore } from "./TeamManageListView";
import { goBack, navigate, navigateReplace } from "../../helper/HistoryHelper";
import Panel from "../../components/layout/Panel";
import MultipleDataSelect from "../../components/input/MultipleDataSelect";
import { computed, makeObservable, observable, values } from "mobx";
import { FaTrash } from "react-icons/fa";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultTeamImage from "../../components/avatar/DefaultTeamImage";
import UploadImage from "../../components/avatar/UploadImage";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { ConfirmDialog, confirmDialogStore } from "@qbot-chat/qbot-uikit";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import { Warning2 } from "iconsax-react";

const form = new FormStore();

@observer
class TeamManageDetailView extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get chatbots() {
    return values(this.chatbotMap);
  }

  @observable
  chatbotMap = new Map();

  @observable
  selectedchatbot;

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/team/detail/${id}`, {}).then((res) => {
      form.values = res.data;
      let bot = res.data?.bot;
      if (bot) this.selectedchatbot = { value: bot.id, label: bot.name };
    });
  }

  chatbotSearch = debounce((inputValue, callback) => {
    axios.post(`/agent/bot/search`, {}).then((res) => {
      res.data &&
        callback(
          res.data
            .filter(
              (ps) =>
                !Array.from(this.chatbotMap.values()).find((x) => x.id == ps.id)
            )
            .map((ps) => ({ label: ps.name, value: ps.id }))
        );
    });
  }, 200);

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    if (this.selectedchatbot != null) {
      form.setValue("bot", { id: this.selectedchatbot.value });
    } else {
      NotificationManager.warning("챗봇을 선택해주세요");
      return;
    }

    if (!!this.props.match.params.id) {
      axios.post(`/team/update`, form.values).then((res) => {
        let id = res.data;
        teamDataGridStore.fetch();
        NotificationManager.success("팀이 저장되었습니다.");
        this.fetchDetail(id);
      });
    } else {
      axios.post(`/team/insert`, form.values).then((res) => {
        let id = res.data;
        NotificationManager.success("팀이 등록되었습니다.");
        teamDataGridStore.fetch();
        navigateReplace(`/main/team/detail/${id}`);
      });
    }
    this.ref.setState({ isNew: false });
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
        axios
          .post(`/team/delete`, form.values)
          .then((res) => {
            NotificationManager.success("팀이 삭제되었습니다.");
            navigateReplace(`/main/team/list`);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  render() {
    let isNew = !form.valueMap.get("id");
    return (
      <div>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={"팀 추가"} />
              ) : (
                <TitlePanelLabel label={"팀 정보 수정"} />
              )}
              <div>
                {!isNew && (
                  <Button
                    variant={"outline-danger"}
                    onClick={() => this.delete()}
                  >
                    삭제
                  </Button>
                )}
                <Button
                  variant={"outline-secondary"}
                  className={"ms-4"}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                <Button
                  variant={"outline-primary"}
                  className={`ms-1`}
                  onClick={() => this.save()}
                >
                  저장
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>이름</Form.Label>
            </Col>
            <Col md={10}>
              <Input
                store={form}
                label={`이름`}
                name={`name`}
                placeholder={`이름을 입력해주세요`}
                validations={[new Validation(/^.+$/, `이름을 입력해주세요.`)]}
                validOnBlur
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>설명</Form.Label>
            </Col>
            <Col md={10}>
              <Input
                store={form}
                label={`설명`}
                name={`description`}
                placeholder={`설명을 입력해주세요`}
                validations={[new Validation(/^.+$/, `설명을 입력해주세요.`)]}
                validOnBlur
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>
                팀 이미지
              </Form.Label>
            </Col>
            <Col md={10}>
              <div className={`d-flex flex-row gap-2 w-100`}>
                <UploadImage
                  url={form.valueMap.get("avatar.fullUrl")}
                  ref={(ref) => (this.ref = ref)}
                  useUrl={true}
                  useFile={true}
                  selectImage={(image) => {
                    form.setValue("avatar.id", null);
                    form.setValue("avatar.type", image.type);
                    form.setValue("avatar.url", image.url);
                    form.setValue("avatar.fullUrl", image.fullUrl);
                  }}
                  resetImage={() => {
                    form.setValue("avatar.id", null);
                    form.setValue("avatar.type", null);
                    form.setValue("avatar.url", null);
                    form.setValue("avatar.fullUrl", null);
                    form.setValue("avatar", null);
                  }}
                  noImage={
                    <RandomColor seed={form.valueMap.get("id")}>
                      <DefaultTeamImage />
                    </RandomColor>
                  }
                />
              </div>
            </Col>
          </Form.Group>
        </Panel>
        <Panel>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>챗봇</Form.Label>
            </Col>
            <Col md={10}>
              <InputGroup size={"sm"} className={`pb-2`}>
                <AsyncSelect
                  menuPortalTarget={document.body}
                  cacheOptions
                  loadOptions={this.chatbotSearch}
                  value={this.selectedchatbot}
                  defaultOptions
                  onChange={(e) => (this.selectedchatbot = e)}
                  isClearable={true}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Panel>
      </div>
    );
  }
}

export default TeamManageDetailView;
