import React from 'react';
import {observer} from "mobx-react";
import {Button} from "react-bootstrap";
import {FaPlus} from "react-icons/fa";
import Styles from './ContentAddButton.module.scss'
import {Add} from "iconsax-react";

@observer
class ContentAddButton extends React.Component{
    render() {
        let {title, onClick} = this.props;
        return(
            <div className={Styles.ContentAddButton}>
                <div>{title}</div>
                <div><Button variant={"outline-primary"} className={Styles.Button} onClick={onClick}><Add/></Button></div>
            </div>
        );
    }
}

export default ContentAddButton;