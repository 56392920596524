import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="ChatBotMessageIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <g>
            <path
                d="M21.4,7.5L21.4,7.5c-0.9-1-2.2-1.7-3.7-1.8h0c-0.2,0-0.4,0-0.6,0h-6c0.1-0.3,0.2-0.6,0.5-0.9 C12.1,4.2,13,4,13.8,3.9c0.2,0.6,0.8,1,1.5,1c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6c-0.5,0-1,0.2-1.3,0.6 c-1.1,0.1-2.5,0.5-3.5,1.4c-0.6,0.5-0.9,1.2-1,2H7c-1.7,0-3.1,0.6-4.2,1.6c-1,1-1.6,2.5-1.6,4.1v2.9c0,0.6,0.1,1.1,0.2,1.7 c0.6,2.5,2.8,4,5.5,4h6.1l1.9,2c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6,0,0.9-0.2c0.3-0.2,0.5-0.4,0.7-0.6c0.2-0.3,0.2-0.6,0.2-0.9V20 c1.3-0.1,2.5-0.7,3.4-1.5c1-1,1.6-2.5,1.6-4.1v-3C22.8,9.8,22.3,8.5,21.4,7.5z M21.3,14.3L21.3,14.3c0,1.3-0.5,2.4-1.2,3.1 c-0.7,0.7-1.8,1.1-3.1,1.1c-0.4,0-0.8,0.3-0.8,0.8v1.3c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1s-0.1,0-0.1,0c0,0-0.1,0-0.1,0l-2.1-2.1 c-0.1-0.1-0.3-0.1-0.4-0.1H7c-2.2,0-3.6-1.2-4.1-2.9c0,0,0,0,0,0c-0.1-0.4-0.2-0.8-0.2-1.3v-2.9c0-1.3,0.4-2.4,1.1-3.1 C4.6,7.6,5.7,7.2,7,7.2h10c0.2,0,0.3,0,0.5,0c1.2,0.1,2.1,0.6,2.7,1.3s1,1.7,1,2.9V14.3z"
            />
            <path
                d="M8,11.8c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S8.7,11.8,8,11.8z"
            />
            <path
                d="M12,11.8c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S12.7,11.8,12,11.8z"
            />
            <path
                d="M16,11.8c-0.7,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2S16.7,11.8,16,11.8z"
            />
        </g>
    </svg>
};
export default SVGComponent;