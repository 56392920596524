import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import {navigate} from "../../helper/HistoryHelper";
import {Button, Col, Container, FormGroup, Image} from "react-bootstrap";
import Panel from "../../components/layout/Panel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import FrameWrap from "../../components/layout/FrameWrap";
import axios from "axios";
import {FormStore} from "../../components/validation";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import Avatar from "../../components/avatar/Avatar";

const teamDataGridStore = new DataGridStore({
    fetchUrl: `/team/list`,
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC)
})

@observer
class TeamManageListView extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        teamDataGridStore.refresh()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.pathname &&
            prevProps.pathname.startsWith('/main/team/detail') &&
            this.props.pathname === '/main/team/list') {
            teamDataGridStore.fetch();
        }
    }

    componentWillUnmount() {
        teamDataGridStore.searchCondition.clear();
    }

    render() {
        return (
            <FrameWrap>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <div>
                                <TitlePanelLabel label={'팀'} />
                            </div>
                            <div>
                                <Button variant={"outline-primary"}
                                        onClick={()=>{
                                            navigate(`/main/team/new`)
                                        }}
                                >
                                    + 팀 추가
                                </Button>
                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel className={'flex-default'}>
                    <DataGrid store={teamDataGridStore}
                              keyColumn={`id`}
                              columns={[
                                  <Header id={`index`} name={`번호`} width={{min: 40, current: 50, max: 60}}/>,
                                  <Header id={`name`} name={`팀명`}
                                          width={{min: 36, current: 160, max: 200}}
                                          printFunction={(teamName, team) => {
                                              return (
                                                  <div className={'w-100 d-flex flex-row gap-2'}>
                                                      <Avatar type={'team'} seed={team.id} width={'1.5rem'} src={team?.avatar?.fullUrl}/>
                                                      <span>{teamName}</span>
                                                  </div>
                                              );
                                          }}

                                  />,
                                  <Header id={`description`} name={`설명`}
                                          width={{min:50, current:150}}
                                          printFunction={(description)=>
                                              <div>{description}</div>
                                  }
                                  />,
                                  <Header id={`agentCount`} name={`팀 인원`} type={`number`}
                                          className={`justify-content-end`}
                                          width={{min:70, current:70, max:80}}
                                  />,
                                  <Header id={`teamMaxCapacity`} name={`팀 티켓`}
                                          className={`justify-content-end`}
                                          width={{min:70, current:70, max:80}}
                                  />,
                                  <Header id="createdAt" sortable={true} name={`생성 일시`}
                                          className={`justify-content-end`}
                                          width={{min: 100, current: 220, max: 250}} type="date"/>,
                              ]}
                              onRowClick={rowData=>{
                                  navigate(`/main/team/detail/${rowData.id}`)
                              }}
                    />
                </Panel>
            </FrameWrap>
        );
    }
}

export { TeamManageListView as default, teamDataGridStore};
