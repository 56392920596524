import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { FormStore, Input, Validation } from "../../components/validation";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { agentDataGridStore } from "./AgentManageListView";
import { goBack, navigateReplace } from "../../helper/HistoryHelper";
import Panel from "../../components/layout/Panel";
import UploadImage from "../../components/avatar/UploadImage";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import { ConfirmDialog, confirmDialogStore } from "@qbot-chat/qbot-uikit";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import AppStore from "../../store/AppStore";
import { makeObservable, observable } from "mobx";
import { Warning2 } from "iconsax-react";
import AsyncSelect from "react-select/async";
import { teamAgentDialogStore } from "../member/MemberDialog";
import { debounce } from "lodash";
import TeamSelect from "../../components/select/TeamSelect";
import Select from "react-select";
import appStore from "../../store/AppStore";
import { ACCESS_RIGHTS, MENU } from "../../const/AppConst";

const form = new FormStore();

@observer
class AgentManageDetailView extends Component {
  @observable
  isLoginIdUsed = true;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/agent/detail/${id}`, {}).then((res) => {
      form.values = res.data;
    });
  }

  rankSearch = debounce((inputValue, callback) => {
    const filter =
      appStore.type === "SUPER"
        ? {
            teamId: form?.values.team?.id,
          }
        : {};
    axios.post(`/rank/search`, filter).then((res) => {
      res.data &&
        callback(res.data.map((ps) => ({ label: ps.label, value: ps.id })));
    });
  }, 200);

  checkLoginId = (loginId) => {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }
    if (loginId) {
      axios.post(`/user/check/loginId/${loginId}`, {}).then((res) => {
        if (res.data) {
          NotificationManager.warning("이미 사용중인 로그인 아이디 입니다.");
        } else {
          NotificationManager.success("사용 가능한 로그인 아이디 입니다.");
          this.isLoginIdUsed = false;
        }
      });
    }
  };

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }

    if (!!this.props.match.params.id) {
      axios.post(`/agent/update`, form.values).then((res) => {
        let id = res.data;
        agentDataGridStore.fetch();
        this.fetchDetail(id);
        NotificationManager.success("변경사항이 저장되었습니다.");
      });
    } else {
      if (this.isLoginIdUsed) {
        NotificationManager.warning("로그인 아이디 중복 체크를 해야합니다.");
        return;
      }
      axios.post(`/agent/insert`, form.values).then((res) => {
        let id = res.data;
        NotificationManager.success("상담사가 등록되었습니다.");
        agentDataGridStore.fetch();
        navigateReplace(`/main/agent/detail/${id}`);
      });
    }
    this.ref.setState({ isNew: false });
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
        axios
          .post(`/agent/delete`, form.values)
          .then((res) => {
            NotificationManager.success("상담사가 삭제되었습니다.");
            navigateReplace(`/main/agent/list`);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  moveAuthority() {
    confirmDialogStore.openDelegate(
      <Warning2 />,
      `관리자 위임 후 접속중인 관리자 계정은 상담사 권한으로 전환되고 자동 로그아웃 됩니다. 위임 하시겠습니까?`,
      () => {
        axios
          .post(`/company/admin/delegate`, {
            id: form.valueMap.get("id"),
          })
          .then((res) => {
            if (appStore?.type === "SUPER") {
              NotificationManager.success("권한이 위임되었습니다.");
              navigateReplace(`/main/agent/list`);
            } else {
              NotificationManager.success(
                "권한이 위임되었습니다. 재로그인 해주세요."
              );
              appStore.fetchLogout();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    );
  }

  render() {
    let isNew = !form.valueMap.get("id");
    let rank = form.values.rank;
    let team = form.values.team;
    let isLeader = form.valueMap.get("isLeader") || false;
    return (
      <div>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={"상담사 추가"} />
              ) : (
                <TitlePanelLabel label={"상담사 정보 수정"} />
              )}
              <div>
                {!isNew && (
                  <Button
                    variant={"outline-danger"}
                    className={`ms-3`}
                    onClick={() => this.delete()}
                  >
                    삭제
                  </Button>
                )}

                <Button
                  variant={"outline-secondary"}
                  className={"ms-4"}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                <Button
                  variant={"outline-primary"}
                  className={`ms-1`}
                  onClick={() => this.save()}
                >
                  저장
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>ID</FormLabel>
                <Input
                  store={form}
                  label={`ID`}
                  name={`id`}
                  placeholder={`자동생성`}
                  disabled={true}
                  validOnBlur
                />
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>이름</FormLabel>
                <Input
                  store={form}
                  label={`이름`}
                  name={`name`}
                  placeholder={`이름을 입력해주세요`}
                  validations={[new Validation(/^.+$/, `이름을 입력하세요.`)]}
                  validOnBlur
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>Login ID</FormLabel>
                <InputGroup>
                  <Input
                    store={form}
                    label={`Login ID`}
                    name={`loginId`}
                    placeholder={`Login ID`}
                    validations={[
                      new Validation(
                        /^[a-zA-Z0-9+-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        `이메일 형식으로 입력해 주세요.`
                      ),
                    ]}
                    validOnBlur
                    disabled={!isNew}
                    onChange={() => (this.isLoginIdUsed = true)}
                    style={{ borderRadius: "0.375rem 0 0 0.375rem" }}
                    button={
                      isNew &&
                      this.isLoginIdUsed && (
                        <Button
                          variant={`outline-primary`}
                          onClick={() =>
                            this.checkLoginId(form.valueMap.get("loginId"))
                          }
                        >
                          중복체크
                        </Button>
                      )
                    }
                  />
                </InputGroup>
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>비밀번호</FormLabel>
                <Input
                  store={form}
                  label={`비밀번호`}
                  name={`password`}
                  placeholder={`비밀번호를 입력해주세요`}
                  type={`password`}
                  validations={[
                    new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                  ]}
                  validOnBlur
                />
              </Col>
            </FormGroup>

            <Form.Group as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>팀</FormLabel>
                <InputGroup>
                  {team?.id && (
                    <>
                      <InputGroup.Text>팀장</InputGroup.Text>
                      <InputGroup.Checkbox
                        checked={isLeader}
                        onChange={() => form.setValue("isLeader", !isLeader)}
                      />
                    </>
                  )}
                  <TeamSelect
                    className={"flex-grow-1"}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: "9999",
                      }),
                      control: (base) => ({
                        ...base,
                        minWidth: "13rem",
                        borderRadius: `${team?.id ? "0 4px 4px 0" : "4px"}`,
                      }),
                    }}
                    value={team ? { label: team.name, value: team.id } : null}
                    onChange={(e) =>
                      form.setValue("team", { id: e.value, name: e.label })
                    }
                  />
                </InputGroup>
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>직급</FormLabel>
                <AsyncSelect
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: "9999",
                    }),
                  }}
                  menuPortalTarget={document.body}
                  cacheOptions
                  loadOptions={this.rankSearch}
                  value={rank ? { label: rank.label, value: rank.id } : null}
                  defaultOptions
                  onChange={(e) =>
                    form.setValue("rank", { id: e.value, label: e.label })
                  }
                />
              </Col>
            </Form.Group>

            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>프로필이미지</FormLabel>
                <div
                  className={`d-flex flex-row align-items-center gap-2 w-100`}
                >
                  <UploadImage
                    url={form.valueMap.get("avatar.fullUrl")}
                    ref={(ref) => (this.ref = ref)}
                    useUrl={true}
                    useFile={true}
                    selectImage={(image) => {
                      form.setValue("avatar.id", null);
                      form.setValue("avatar.type", image.type);
                      form.setValue("avatar.url", image.url);
                      form.setValue("avatar.fullUrl", image.fullUrl);
                    }}
                    resetImage={() => {
                      form.setValue("avatar.id", null);
                      form.setValue("avatar.type", null);
                      form.setValue("avatar.url", null);
                      form.setValue("avatar.fullUrl", null);
                      form.setValue("avatar", null);
                    }}
                    noImage={
                      <RandomColor seed={form.valueMap.get("id")}>
                        <DefaultAvatar />
                      </RandomColor>
                    }
                  />
                  {appStore?.type !== ACCESS_RIGHTS.AGENT &&
                  appStore?.type !== ACCESS_RIGHTS.AGENT_LEADER ? (
                    <Button
                      variant={"outline-primary"}
                      className="ms-5 h-50"
                      onClick={() => this.moveAuthority()}
                    >
                      권한 위임
                    </Button>
                  ) : null}
                </div>
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>email</FormLabel>
                <Input
                  store={form}
                  label={"이메일"}
                  name={`email`}
                  placeholder={`이메일 주소를 입력하세요.`}
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>전화번호</FormLabel>
                <Input
                  store={form}
                  label={`전화번호`}
                  name={`tel`}
                  placeholder={`전화번호를 입력해주세요`}
                />
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>주소</FormLabel>
                <Input
                  store={form}
                  label={`주소`}
                  name={`address`}
                  placeholder={`이름을 입력해주세요`}
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>인사말</FormLabel>
                <Input
                  store={form}
                  as={"textarea"}
                  rows={3}
                  label={`인사말`}
                  name={`greeting`}
                  placeholder={`입력하지 않은 경우, 기본 설정된 인사말이 출력 됩니다.`}
                />
              </Col>
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </div>
    );
  }
}

export default AgentManageDetailView;
