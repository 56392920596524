import React from 'react';
import {observer} from "mobx-react";
import Styles from "./CustomHeader.module.scss";
import Badge from "../badge/Badge";
import {BRAND_COLOR} from "../../const/AppConst";
import { TICKET_STATUS} from "@qbot-chat/qbot-uikit";
import {Button, Dropdown, Badge as RBBadge} from "react-bootstrap";
import {GalleryImport, HambergerMenu, Headphone, ProgrammingArrow, SmsTracking, TicketExpired} from "iconsax-react";
import {DropDownButton} from "@qbot-chat/qbot-uikit/chat";
import {transferDialogStore} from "../dialog/TransferDialog";
import {closeConfirmDialogStore} from "../dialog/CloseConfirmDialog";
import settingStore from "../../store/SettingStore";
import TicketStatusActiveIcon from "../icon/TicketStatusActiveIcon";
import TicketStatusClosedIcon from "../icon/TicketStatusClosedIcon";
import {navigateReplace} from "@qbot-chat/front/src/helper/HistoryHelper";
import axios from "axios";
import {chatStore, historyChannelStore, historyChatStore, ticketStore} from "../../store/AdminChatStore";
import {chatViewStore} from "../../view/ChatView";
import {EMART_TICKET_STATUS} from "../collapse/CloseCollapse";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref}
         onClick={(e) => {
             e.preventDefault();
             onClick(e);
         }}
         style={{cursor:'pointer'}}
    >
        {children}
    </div>
));

@observer
class CustomHeader extends React.Component {

    render() {
        let {store} = this.props
        let {channel} = store;
        let {properties, customer} = channel;
        let {setting} = settingStore;
        return (
            <div className={`${Styles.ChatHeader} ${store.channel?.status == TICKET_STATUS.CLOSED? Styles.Closed:''}`}>
                <div className={Styles.MainContainer}>
                    <div className={Styles.TicketStatus}>
                        {
                            store.channel?.status !== TICKET_STATUS.CLOSED ?
                                <div>
                                    {
                                        setting?.isCloseableByAgent &&
                                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                                            <TicketStatusActiveIcon fill={'var(--bs-primary)'} size={'2.5rem'} />
                                        </div>
                                    }
                                </div>
                                :
                                <div>
                                    {
                                        setting?.isCloseableByAgent &&
                                        <div className={'d-flex flex-column justify-content-center align-items-center'}>
                                            <TicketStatusClosedIcon fill={'var(--bs-danger)'} size={'2.5rem'} />
                                        </div>
                                    }
                                </div>

                        }
                    </div>
                    <div className={Styles.NameContainer}>
                        {
                            properties &&
                            <div className={Styles.Badge}>
                                <Badge color={BRAND_COLOR[properties['brand-id']]} location={properties['branch-name']}/>
                            </div>
                        }
                        <div className={Styles.Name}>
                            {customer?.name}
                        </div>
                    </div>
                    <div className={`d-flex`}>
                        {
                            store.channel?.status !== TICKET_STATUS.CLOSED && !store.isOpenClose &&
                            <>
                                <Dropdown autoClose={true}>
                                    {
                                        (setting?.isSendableCloseMessage || setting?.isCloseableByAgent) &&
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <Button variant={"outline-danger"}
                                                    className={`me-2`}
                                                    size={`sm`}
                                            >
                                                종료
                                            </Button>
                                        </Dropdown.Toggle>
                                    }

                                    <Dropdown.Menu>
                                        {
                                            setting?.isCloseableByAgent &&
                                            <>
                                                <DropDownButton onClick={()=>{
                                                    store.close()
                                                    ticketStore.deleteTicket(channel.id)
                                                    chatStore.setChannel(null);
                                                    chatViewStore.setCurrentHistoryTicket(null);
                                                    historyChatStore.setChannel(null);
                                                    historyChannelStore.clear()
                                                }}
                                                >
                                                    <TicketExpired size={24}/>종료
                                                </DropDownButton>

                                            </>
                                        }
                                        {
                                            setting?.isSendableCloseMessage &&
                                            <DropDownButton onClick={()=>closeConfirmDialogStore.open(store.channel)}
                                            >
                                                <TicketExpired size={24}/>상담 종료 확인하기
                                            </DropDownButton>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>

                                <>
                                    <Dropdown autoClose={true}>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <Button variant={"outline-secondary"}
                                                    className={`me-2`}
                                                    size={`sm`}
                                            >
                                                이관
                                            </Button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {
                                                setting?.isTransferableByAgent &&
                                                <>
                                                    <DropDownButton onClick={()=>store.openAgentTransfer()}
                                                    >
                                                        <SmsTracking size={24}/>티켓 이관 - 상담사
                                                    </DropDownButton>
                                                    <DropDownButton onClick={()=>store.openTeamTransfer()}
                                                    >
                                                        <ProgrammingArrow size={24}/>티켓 이관 - 팀
                                                    </DropDownButton>
                                                </>
                                            }
                                            <DropDownButton onClick={()=>transferDialogStore.open()}
                                            >
                                                <Headphone size={24}/>이메일 및 음성이관
                                            </DropDownButton>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </>
                            </>
                        }
                    </div>
                </div>
                <div className={`justify-content-start w-100`} style={{fontSize:10}}>
                    {
                        channel?.category &&
                        <>
                            <div>
                                <RBBadge bg={`secondary`}>
                                    종료타입
                                </RBBadge>
                                &nbsp;
                                {channel.category.closedType === 'CLOSE_DRAFT' ? '1차 종결' : '점포 확인 후 종결'}
                            </div>
                            <div>
                                <RBBadge bg={`secondary`}>
                                    카테고리
                                </RBBadge>
                                {appStore.getCategoriesBreadCrumb(channel.category.categoryId)}
                            </div>
                            <div>
                                <RBBadge bg={`secondary`}>
                                    관리부서
                                </RBBadge>

                                &nbsp;
                                {appStore.orgs.find(org=>org.org_id === parseInt(channel.category.orgId))?.full_org_nm}
                            </div>
                        </>
                    }

                </div>
            </div>
        )
    }
}

export {CustomHeader};
