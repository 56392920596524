import React, {Component} from 'react';
import {action, makeObservable, observable, toJS} from 'mobx';
import {observer} from 'mobx-react';
import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import Section from '../layout/Section';
import RadioGroup from '../input/RadioGroup';
import {toast} from 'react-toastify';
import axios from 'axios';
import {alertDialogStore} from './AlertDialog';
import Tree from '../tree/Tree';
import appStore from "../../store/AppStore";
import Styles from './OrgDialog.module.scss';
import {NotificationManager} from "react-notifications";

/*import Styles from './CloseDialog.module.scss';*/
/*import SearchCodeSelect from '../search/SearchCodeSelect';*/


class OrgDialogStore {

    @observable
    show = false;

    onSelect;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    open(onSelect) {
        this.show = true;
        this.onSelect = onSelect;
    }

    @action.bound
    close() {
        this.show = false;
        this.onSelect = undefined;
    }
}

const orgDialogStore = new OrgDialogStore();


@observer
class OrgDialog extends Component {

    handleClose() {
        orgDialogStore.close();
    }


    render() {
        return (
            <Modal show={orgDialogStore.show}
                   onHide={this.handleClose.bind(this)}
                   backdrop="static"
            >
                <Modal.Header closeButton>
                    <div className={Styles.Header}>관련 부서 선택</div>
                </Modal.Header>
                <Modal.Body className={Styles.Body}>
                    {
                        appStore.orgsTree &&
                            <Tree
                                treeData={toJS(appStore.orgsTree)}
                                renderNode={(node)=>node.title}
                                /*onSelect={(orgId) => orgDialogStore.onSelect(orgId)}*/
                                onClick={(node) => {
                                    if(node.isLeaf) {
                                        orgDialogStore.onSelect && orgDialogStore.onSelect(node.id);
                                        this.handleClose();
                                    }else {
                                        NotificationManager.warning('하위부서를 선택 해주세요.')
                                    }
                                }}
                            />
                    }
                </Modal.Body>
            </Modal>
        );
    }

}

export {OrgDialog as default, orgDialogStore};
