import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {
    Breadcrumb,
    Button,
    Col,
    Collapse,
    Container,
    Form,
    FormControl,
    FormGroup,
    Nav,
    Row,
    Tab
} from "react-bootstrap";
import Styles from "../SettingManageView.module.scss";
import Panel from "../../../components/layout/Panel";
import axios from "axios";
import WeekTimeEditor from "./WeekTimeEditor";
import settingStore from "../../../store/SettingStore";
import {NotificationManager} from "react-notifications";
import TitlePanel from "../../../components/layout/TitlePanel";
import TitlePanelLabel from "../../../components/label/TitlePanelLabel";
import {makeObservable, observable} from "mobx";
import appStore from "../../../store/AppStore";
import TeamSelect from "../../../components/select/TeamSelect";
import Avatar from "../../../components/avatar/Avatar";
import Frame from "../../../components/layout/Frame";
import FrameWrap from "../../../components/layout/FrameWrap";
import {ACCESS_RIGHTS} from "../../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";

@observer
class WorkTimeManageFrame extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
        this.state={
            key: 1
        }
    }

    @observable
    selectedTeam = null;

    componentDidMount() {
        if(!ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type)){
            this.selectedTeam = appStore.team;
            settingStore.fetchWorkSheet(this.selectedTeam);
        }

    }

    render() {
        return (
            <div className={`${Styles.Tab} h-100`}>
                <FrameWrap>
                    <TitlePanel>
                        <FormGroup as={Row}>
                            <Col md={8} sm={12} className={`align-items-center d-flex`}>
                                <div className={'mt-2 mb-2'}>
                                    <TitlePanelLabel label={'운영 시간 설정 - 운영 시간'}/>
                                </div>
                            </Col>
                            <Col md={4} sm={12} className={'d-flex justify-content-end'}>
                                <div className={'w-100'}>
                                    {
                                        ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) ?
                                            <TeamSelect
                                                autoFocus={true}
                                                placeholder="팀 선택"
                                                onChange={(e) => {
                                                    if(e && e.object) {
                                                        this.selectedTeam = e.object;
                                                        settingStore.fetchWorkSheet(this.selectedTeam);
                                                    }else{
                                                        this.selectedTeam = null;
                                                    }
                                                }}
                                            />:
                                            <div className={'d-flex flex-row p-2 rounded-2'}
                                                 style={{border: '1px solid #eee', }}
                                            >
                                                <Avatar src={appStore.team.avatar.fullUrl} seed={appStore.team.id} type={'team'} width={'2rem'}/>
                                                <div className={'ms-2 align-self-center'}> {appStore.team.name} </div>
                                            </div>
                                    }
                                </div>
                            </Col>
                        </FormGroup>
                    </TitlePanel>
                    <TitlePanel>

                        <Panel className={`${Styles.BorderLine} w-100 d-flex flex-column gap-2`}>
                            <FormGroup as={Row} className={`pb-3`}>
                                <Col md={12} className={`d-flex align-items-center`}>
                                    <div className={`${Styles.Title} ${Styles.Label}`}>
                                        <div className={`d-flex`}>
                                            요일별 설정
                                        </div>
                                    </div>
                                </Col>
                            </FormGroup>
                            {
                                this.selectedTeam &&
                                <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.key} activeKey={this.state.key}
                                               onSelect={key=>{
                                                   this.setState({key})
                                               }}
                                >
                                    <FormGroup as={Row} >
                                        <Col sm={3}>
                                            <Nav variant="pills" className="flex-column" >
                                                {
                                                    settingStore.weekTimes.map((weekTime, idx)=>
                                                        <Nav.Item key={`week_time_${idx}`}>
                                                            <Nav.Link eventKey={weekTime.dayOfWeek}>{moment.weekdays(weekTime.dayOfWeek)}</Nav.Link>
                                                        </Nav.Item>
                                                    )
                                                }
                                            </Nav>
                                        </Col>
                                        <Col sm={9}>
                                            <Tab.Content>
                                                {
                                                    settingStore.weekTimes.map(weekTime=>
                                                        <Tab.Pane eventKey={weekTime.dayOfWeek}>
                                                            {
                                                                weekTime.dayOfWeek == this.state.key &&
                                                                <WeekTimeEditor weekTime={weekTime} team={this.selectedTeam} />
                                                            }

                                                        </Tab.Pane>
                                                    )
                                                }
                                            </Tab.Content>
                                        </Col>
                                    </FormGroup>
                                </Tab.Container>
                            }
                        </Panel>

                        {
                            this.selectedTeam &&
                            <Panel className={`${Styles.BorderLine} w-100 d-flex flex-column gap-2 mt-3`}>
                                <FormGroup as={Row}>
                                    <Col md={3}>
                                        <div className={`${Styles.Title} ${Styles.Label}`}>
                                            <div className={`d-flex`}>
                                                근무시간 외 메시지 설정
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <FormControl as={'textarea'}
                                                     placeholder={'메시지를 입력하세요.'}
                                                     style={{resize:'none'}}
                                                     rows={4}
                                                     defaultValue={settingStore.workSheet?.exceptMessage}
                                                     value={this.state.value || settingStore.workSheet?.exceptMessage}
                                                     onChange={e=>{
                                                         this.setState({value:e.target.value})
                                                     }}
                                                     isInvalid={!(this.state.value || settingStore.workSheet?.exceptMessage)}
                                        />
                                        {
                                            !(this.state.value || settingStore.workSheet?.exceptMessage) &&
                                            <Form.Control.Feedback type="invalid">
                                                메시지를 입력하세요.
                                            </Form.Control.Feedback>
                                        }
                                    </Col>
                                </FormGroup>
                                <Collapse in={(this.state.value || settingStore.workSheet?.exceptMessage) != settingStore.workSheet?.exceptMessage}>
                                    <div >
                                        <div className={`d-flex justify-content-end pt-3`}>
                                            <Button variant={"outline-secondary"} className={Styles.Button} onClick={()=>this.setState({value : ''})}>취소</Button>
                                            <Button variant={"outline-primary"}  className={`${Styles.Button} ms-2`} disabled={!this.state.value}
                                                    onClick={()=>{
                                                        axios.post(
                                                            `/setting/except/message/update`,
                                                            {
                                                                ...settingStore.workSheet,
                                                                exceptMessage:this.state.value
                                                            }
                                                        ).then(r=>{
                                                            settingStore.fetchWorkSheet(this.selectedTeam);
                                                            NotificationManager.success(`업데이트 완료`);
                                                        })
                                                    }}
                                            >
                                                저장
                                            </Button>
                                        </div>
                                    </div>
                                </Collapse>
                            </Panel>
                        }
                    </TitlePanel>
                </FrameWrap>
            </div>
        );
    }
}

export default WorkTimeManageFrame;
