import React, {Component} from 'react';
import ModalBase from "../../../components/modal/ModalBase";
import {
    Badge,
    Button,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    FormGroup,
    FormLabel,
    InputGroup,
    Modal,
    Nav,
    Row
} from "react-bootstrap";
import {observer} from "mobx-react";
import {makeObservable, observable, reaction} from "mobx";
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import Panel from "../../../components/layout/Panel";
import {FormStore, Input, Validation} from "../../../components/validation";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {BsPencil, BsTrash} from "react-icons/bs";
import Styles from './QuickMsgModal.module.scss';
import {ConfirmDialog, confirmDialogStore} from "@qbot-chat/qbot-uikit/modal";
import FrameWrap from "../../../components/layout/FrameWrap";
import Avatar from "../../../components/avatar/Avatar";
import {goBack} from "../../../helper/HistoryHelper";
const form = new FormStore();

const quickMsgDataGridStore = new DataGridStore({
    fetchUrl: `/quickmsg/list`,
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
    noHeader: true
})
const TYPE_NAME = {
    ALL: '전체',
    COMMON: '공통',
    PERSONAL: '개인'
}

@observer
class QuickMsgModal extends Component {

    @observable
    keyword = "";

    @observable
    type = "ALL";

    @observable
    tabId = 0;

    @observable
    selectedId = null;

    constructor(props) {
        super(props);
        makeObservable(this);
        reaction(() => this.tabId,
            (tabId) => {
                if(tabId === 1){
                    quickMsgDataGridStore.refresh();
                }
            })
    }

    componentDidMount() {
        quickMsgDataGridStore.refresh();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.props.store.quickMsgStore.isPopupOpen){
            form.clear();
            this.tabId = 0;
        }
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            quickMsgDataGridStore.page = 1;
            quickMsgDataGridStore.fetch();
        }
    }

    search = () => {
        quickMsgDataGridStore.searchCondition.set("type",this.type);
        quickMsgDataGridStore.searchCondition.set("keyword", this.keyword);
        quickMsgDataGridStore.fetch();
    }

    fetchDetail(){
        if(this.selectedId){
            axios.post(
                `/quickmsg/detail/${this.selectedId}`,
                {}
            ).then(res=>{
                form.setValue("id",this.selectedId);
                this.type = res.data.quickMsgType;
                form.setValue("title", res.data.title);
                form.setValue("text", res.data.content);
                form.setValue("creator",res.data.creator.name)
            });
        }
    }


    save(){
        form.setValue("quickMsgType", 'PERSONAL');
        form.setValue("content", form.valueMap.get("text"));
        form.setValue("creator", null);
        if(!form.doCheckValid()){
            NotificationManager.warning(form.firstErrorMessage)
            return;
        }
        if(!!this.selectedId){
            confirmDialogStore.openSave(
                '저장',
                '저장 하시겠습니까?',
                () => {
                    axios.post(
                        `/quickmsg/update`,
                        form.values
                    ).then(res=>{
                        form.clear();
                        this.tabId = 1;
                        NotificationManager.success('빠른답변이 저장되었습니다.')
                    })
                }
            )
        }else{
            confirmDialogStore.openAdd(
                '저장',
                '저장 하시겠습니까?',
                () => {
                    axios.post(
                        `/quickmsg/insert`,
                        form.values
                    ).then(res=>{
                        form.clear();
                        this.tabId = 1;
                        NotificationManager.success('빠른답변이 등록되었습니다.')
                    })
                }
            )
        }

    }

    delete() {
        if(form.valueMap.get('id')){
            form.setValue("creator", null);
            confirmDialogStore.openRemove(
                '삭제',
                '삭제 하시겠습니까?',
                () => {
                    axios.post(
                        `/quickmsg/delete`,
                    form.values
                ).then(res=>{
                        form.clear();
                        this.tabId = 1;
                        NotificationManager.success('빠른답변이 삭제되었습니다.');
                    })
                }
            )
        }
    }

    goBack() {
        this.type = 'ALL';
        form.clear();
        this.props.store.quickMsgStore.setIsPopupOpen(false)
    }

    tabContent = () => {
        const {store} = this.props;
        let isNew = !form.valueMap.get('id');
        if(this.tabId === 0) {
            return <FrameWrap>
                <Panel className={Styles.Panel}>
                    <Form>
                        <FormGroup as={Row} className={'mb-3'}>
                            {
                                !isNew &&
                                <FormGroup as={Row} className={'mb-3'}>
                                    <Col md={2}>
                                        <div className={Styles.Label}>
                                            작성자
                                        </div>
                                    </Col>
                                    <Col md={10} className={'d-flex flex-row'}>
                                        <InputGroup>
                                            <Input store={form}
                                                   label={`creator`}
                                                   name={`creator`}
                                                   disabled
                                            />
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                            }
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    제목
                                </div>
                            </Col>
                            <Col md={10}>
                                <InputGroup>
                                    <Input store={form}
                                           label={`제목`}
                                           name={`title`}
                                           placeholder={`제목을 입력하세요.`}
                                           validations={[
                                               new Validation(/^.+$/, `제목을 입력하세요.`)
                                           ]}
                                           validOnBlur
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    내용
                                </div>
                            </Col>
                            <Col md={10}>
                                <InputGroup>
                                    <Input store={form}
                                           label={`내용`}
                                           name={`text`}
                                           placeholder={`내용을 입력하세요.`}
                                           as={`textarea`}
                                            validations={[
                                                new Validation(/^.+$/m, `내용을 입력하세요.`)
                                            ]}
                                           style={{height: '20rem'}}
                                           validOnBlur
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Form>
                </Panel>
                <Row>
                    <Col className={`justify-content-end d-flex gap-2 mt-3`}>
                        {
                            !isNew &&
                            <Button variant={"outline-danger"}
                                    onClick={() => this.delete()}
                            >
                                삭제
                            </Button>
                        }

                        <Button variant={"outline-secondary"}
                                className={`ms-4`}
                                onClick={()=> this.goBack()}
                        >
                            취소
                        </Button>
                        <Button variant={"outline-primary"}
                                className={`ms-1`}
                                onClick={()=> this.save()}
                        >
                            저장
                        </Button>
                    </Col>
                </Row>
            </FrameWrap>
        }
        if(this.tabId === 1) {
            return <FrameWrap>
                <Panel className={Styles.Panel}>
                    <FormGroup>
                        <Col className={`d-flex flex-column`}>
                            <div className={'d-flex justify-content-between'}>
                                <Col md={2}>
                                    <DropdownButton id="dropdown-basic-button" title={TYPE_NAME[this.type]}>
                                        <Dropdown.Item eventKey="ALL" onClick={() => {
                                            this.type = 'ALL';
                                            this.search();
                                        }}>전체</Dropdown.Item>
                                        <Dropdown.Item eventKey="COMMON" onClick={() => {
                                            this.type = 'COMMON';
                                            this.search();
                                        }}>공통</Dropdown.Item>
                                        <Dropdown.Item eventKey="PERSONAL" onClick={() => {
                                            this.type = 'PERSONAL';
                                            this.search();
                                        }}>개인</Dropdown.Item>
                                    </DropdownButton>
                                </Col>
                                <Col md={10}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="제목+내용으로 검색"
                                            onChange={(e) => {
                                                this.keyword = e.target.value;
                                            }}
                                            onKeyDown={(e) => this.onKeyDown(e)}
                                        />
                                        <Button variant={"outline-secondary"}
                                                onClick={()=> this.search()}
                                        >
                                            검색
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </div>
                        </Col>
                    </FormGroup>
                </Panel>
                <Panel className={Styles.Data}>
                    <DataGrid store={quickMsgDataGridStore}
                              keyColumn={`id`}
                              columns={[
                                  <Header id={`quickMsgType`} name={`구분`} width={{min:60, current:70, max:80}} printFunction={type => (
                                      {
                                          COMMON:<Badge bg="primary">공통</Badge>,
                                          PERSONAL:<Badge bg="success">개인</Badge>,
                                      }[type]
                                  )}/>,
                                  <Header id={`creator`} name={`작성자`}  width={{min: 36, current: 160, max: 200}} printFunction={creator=>{
                                      return (
                                          creator?.id || '' ?
                                              <div className={'w-100 d-flex flex-row gap-2'}>
                                                  <Avatar type={'agent'} seed={creator?.id} width={'1.5rem'} src={creator?.avatar?.fullUrl}/>
                                                  <span>{creator?.name}</span>
                                              </div>
                                              : '-'
                                      )
                                  }}/>,
                                  <Header id={`title`} name={`제목`}
                                          width={{min:150, current:200, max:500}}
                                          printFunction={(title) =>
                                              (
                                                  <div>{title}</div>
                                              )
                                          }/>,
                                  <Header id={`content`} name={`답변 내용`}
                                          width={{min:150, current:200, max:500}}
                                          printFunction={(content) =>
                                              (
                                                  <div>{content}</div>
                                              )
                                          }/>,
                              ]}
                              onRowClick={rowData=>{
                                  this.selectedId = rowData.id;
                                  this.fetchDetail();
                                  this.tabId = 0;
                              }}
                    />
                </Panel>
                <Row>
                    <Col className={`justify-content-end d-flex mt-3`}>
                        <Button variant={"outline-secondary"}
                                onClick={()=> this.goBack()}
                        >
                            취소
                        </Button>
                    </Col>
                </Row>
            </FrameWrap>
        }
    }
    render() {
        const {store} = this.props;
        return (
            <>
                { store.quickMsgStore &&
                    <ModalBase
                        size={'lg'}
                        title={`빠른답변`}
                        show={store.quickMsgStore.isPopupOpen}
                        onHide={() => store.quickMsgStore.setIsPopupOpen(false)}
                    >
                        <Modal.Body className={Styles.Container}>
                            <div className={Styles.Header}>
                                빠른 답변 관리
                            </div>
                            <div>
                                <Nav fill variant="tabs" defaultActiveKey={0} activeKey={this.tabId}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="0" onClick={() => this.tabId = 0}>답변 추가/수정</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="1" onClick={() => this.tabId = 1}>리스트 관리</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {this.tabContent()}
                            </div>
                            <div>

                            </div>
                        </Modal.Body>
                        <ConfirmDialog />
                    </ModalBase>
                }
            </>

        );
    }
}

export default QuickMsgModal;