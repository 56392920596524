import {
  action,
  computed,
  makeObservable,
  observable,
  values,
  toJS,
} from "mobx";
import Cookies from "js-cookie";
import * as moment from "moment";
import axios from "axios";
import { navigate } from "../helper/HistoryHelper";
import * as short from "short-uuid";
import {
  channelStore,
  chatStore,
  rSocket,
  ticketDetailChatStore,
  ticketStore,
  quickMsgStore,
  historyChannelStore,
  historyChatStore,
} from "./AdminChatStore";
import settingStore from "./SettingStore";

const AUTH = "authKey";
const ID = "id";
const EMAIL = "email";
const IS_SAVE = "save";
const USER_ID = "username";
const USER_NAME = "name";
const IS_ADMIN = "admin";

export const uuidConv = short();

export const CookieKeys = {
  AUTH,
  ID,
  USER_ID,
  USER_NAME,
  IS_ADMIN,
  EMAIL,
  IS_SAVE,
};

/**
 * 앱 전반에 관련된 상태
 */
class AppStore {
  constructor() {
    makeObservable(this);
    setInterval(() => (this.now = moment()), 1000);
  }

  failedPath = "/";

  @observable
  hoverShow = false;

  @observable
  addOpen = false;

  @observable
  assignSound = false;

  @observable
  chatReady = false;

  @observable
  now = moment();

  @observable
  isLogin = false;

  @observable
  loginId = "";

  @observable
  isFirstLogin = false;

  @observable
  user;

  @observable
  team;

  @observable
  company;

  @observable
  companyId;

  @observable
  id = "";

  @observable
  avatar = "";

  @observable
  name = Cookies.get(CookieKeys.USER_NAME);

  @observable
  permissions = [{ id: "/login" }];

  @observable
  isAdmin = false;

  @observable
  isSuper = false;

  @observable
  currentPath = "/";

  @observable
  previousPath = "/";

  @observable
  sideBarOpen = false;

  @observable
  isSessionChecked = false;

  @observable
  username = "";

  @observable
  type = "";

  @observable
  isUpdatingSession = false;

  @observable
  status = "ACTIVE";

  @observable
  isResizing = false;

  @observable
  includeLabsUser;

  @action.bound
  updateSession({
    id,
    name,
    avatar,
    type,
    status,
    team,
    loginId,
    isFirstLogin,
    company,
    companyId,
  }) {
    let user = { id, name, avatar, type };
    this.id = id;
    this.loginId = loginId;
    this.avatar = avatar?.fullUrl;
    this.name = name;
    this.type = type;
    this.status = status;
    this.user = user;
    this.team = team;
    this.company = company;
    this.isFirstLogin = isFirstLogin;
    this.companyId = companyId;
    Cookies.set(CookieKeys.ID, id);
    Cookies.set(CookieKeys.USER_NAME, name);
    rSocket.run(
      {
        sender: user,
        ticket: ticketStore,
        channel: channelStore,
        chat: chatStore,
      },
      (rsocket) => {
        quickMsgStore.setRSocketStore(rSocket);
        chatStore.setQuickMsgStore(quickMsgStore);
        ticketDetailChatStore.setRSocketStore(rSocket);
        historyChannelStore.setRSocketStore(rSocket);
        historyChatStore.setRSocketStore(rSocket);
        appStore.chatReady = true;
        ticketStore.initAssignment();
      }
    );
    if (type === "ADMIN" || type === "SUPER") {
      this.isAdmin = true;
      if (type === "SUPER") this.isSuper = true;
      else this.isSuper = false;
    } else {
      this.isAdmin = false;
    }
    this.getFavoritesOrg();
    this.isLogin = true;
    settingStore.fetchSettingInfo();
    settingStore.fetchTicketEvent();
  }

  @action.bound
  clearSession() {
    this.userId = "";
    this.userEmail = "";
    this.name = "";
    this.userProfileUrl = "";
    Cookies.remove(CookieKeys.ID);
    Cookies.remove(CookieKeys.USER_NAME);
    this.eventTrackFn = undefined;
    this.isLogin = false;
  }

  /**
   * 로그인을 요청한다.
   * @param form 로그인폼
   * @param success 성공콜백
   * @paraam fail 실패콜백
   */
  @action.bound
  async fetchLogin({ form, success, fail, beforeSend, complete }) {
    chatStore.setChannel(null);
    const self = this;
    beforeSend && beforeSend();
    this.isUpdatingSession = true;
    axios
      .post("/user/login", form, { withCredentials: true })
      .then((response) => {
        const data = response?.data;
        const {
          id,
          name,
          type,
          avatar,
          status,
          team,
          loginId,
          isFirstLogin,
          companyId,
        } = data;
        this.updateSession({
          id,
          name,
          avatar,
          type,
          status,
          team,
          loginId,
          isFirstLogin,
          companyId,
        });
        success && success(data);
        this.isUpdatingSession = false;
        navigate("/login");
      })
      .catch(function (error) {
        fail && fail(error);
        self.isUpdatingSession = false;
      })
      .finally(() => {
        complete && complete();
      });
  }

  /**
   * 로그아웃
   */
  @action.bound
  fetchLogout() {
    rSocket.close();
    axios
      .post("/user/logout", {
        user: { id: appStore.userId },
      })
      .then(() => {
        window.localStorage.clear("cookie");
        this.clearSession();
        navigate("/login");
      });
  }

  /**
   * 로그인 세션이 쿠키에 남아있을때 세션정보를 재 로드한다.
   */
  @action.bound
  async fetchSessionInfo(companyId) {
    try {
      if (!Cookies.get(CookieKeys.ID)) {
        this.clearSession();
        navigate("/login");
      } else {
        let store = this;
        store.isUpdatingSession = true;
        let config =
          companyId || companyId === 0 ? { companyId: companyId } : {};
        const response = await axios
          .post("/user/session-touch", config)
          .catch((e) => console.log(e))
          .finally((r) => {
            store.isUpdatingSession = false;
          });
        if (!response.data) {
          this.clearSession();
        } else {
          let {
            id,
            name,
            avatar,
            type,
            username,
            agent,
            chatUser,
            status,
            team,
            loginId,
            company,
            companyId,
          } = response?.data;
          this.updateSession({
            id,
            name,
            avatar,
            type,
            username,
            agent,
            chatUser,
            status,
            team,
            loginId,
            company,
            companyId,
          });
        }
      }
    } catch (e) {
      this.clearSession();
    } finally {
      this.isSessionChecked = true;
    }
  }

  //---- emart ----//

  @observable
  orgs = [];

  @observable
  orgsTree = [];

  @observable
  orgsOptionMap = new Map();

  @computed
  get orgsOptions() {
    const favorOrgIds = toJS(this.favorOrgIds);
    const options = values(this.orgsOptionMap);
    return options.sort(({ value: aValue }, { value: bValue }) =>
      !favorOrgIds.has(aValue.toString()) && favorOrgIds.has(bValue.toString())
        ? 1
        : -1
    );
  }

  @observable
  favorOrgIds = new Set();

  @observable
  categoryOptions = [];

  @observable
  branchesMap = new Map();

  @observable
  categoriesMap = new Map();

  @observable
  receiptModalMap = new Map();

  @observable
  fastRefundBranches = [];

  @computed
  get branches() {
    return values(this.branchesMap);
  }

  @computed
  get branchesOptions() {
    return this.branches.map((branch) => ({
      label: `${branch.brand.name} ${branch.name}`,
      value: branch.id.toString(),
    }));
  }

  @computed
  get conciseBranchesOptions() {
    return this.branches.map((branch) => ({
      label: branch.name,
      value: branch.id?.toString(),
    }));
  }

  @computed
  get trBranchesOptions() {
    return this.branches
      .filter((branch) => branch?.brand.id === "TR")
      .map((branch) => ({
        label: `${branch.brand.name} ${branch.name}`,
        value: branch.id.toString(),
      }));
  }

  @observable
  repliesConfig = {};

  selectedBranchOption(branchId) {
    if (branchId) {
      let branch = this.branchesOptions.find(
        (branch) => branchId == branch.value
      );
      if (branch) {
        return branch;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  @computed
  get receiptModals() {
    return values(this.receiptModalMap);
  }

  @computed
  get categories() {
    return values(this.categoriesMap);
  }

  @computed
  get currentMenuGroupActive() {
    let matched = appStore.currentPath.match(MENU_GROUP_RE);
    if (matched && matched.length > 1) return matched[1];
    else return "people";
  }

  makeOrgTreeRec = (orgs, parentId = 0) => {
    const filtered = orgs.filter((org) => org?.up_org_id === parentId);
    if (!filtered || filtered?.length < 1) return null;

    return filtered.map((org) => {
      let children = this.makeOrgTreeRec(orgs, org?.org_id);
      return {
        title: org.org_nm,
        children,
        id: org.org_id,
        isLeaf: children ? false : true,
        parentId: org.up_org_id,
      };
    });
  };

  loadBranches() {
    axios
      .post("/code/branches", {
        companyId: this.companyId,
      })
      .then(({ data: branches }) => {
        this.branchesMap.replace(
          branches.map((branch) => {
            return [branch.id, branch];
          }) || []
        );
      });
  }

  // loadOrgTree() {
  //   axios.post("/branch/orgs/all").then(({ data: orgs }) => {
  //     this.orgs.replace(orgs || []);
  //     const tree = this.makeOrgTreeRec(orgs);
  //     this.orgsTree.replace(tree || []);
  //     this.orgsOptionMap.replace(
  //       this.orgs
  //         .filter((org) => org.leaf_yn === "N")
  //         .map((org) => [
  //           org.org_id,
  //           { value: org.org_id, label: org.full_org_nm },
  //         ])
  //     );
  //   });
  // }

  // loadCategories() {
  //   axios.post("/code/categories/all").then(({ data: categories }) => {
  //     this.categoriesMap.replace(
  //       categories.map((category) => {
  //         category.isLeaf = !categories.find(
  //           (c) => c.up_cate_id === category.cate_id
  //         );
  //         return [category.cate_id, { ...category }];
  //       }) || []
  //     );
  //     this.categoryOptions.replace(
  //       this.categories
  //         .filter((category) => category.isLeaf == true)
  //         .map((category) => ({
  //           value: category.cate_id,
  //           label: this.getCategoriesBreadCrumb(category.cate_id),
  //         })) || []
  //     );
  //   });
  // }

  @observable
  brands = [];

  @computed
  get brandOptions() {
    return this.brands.map((brand) => ({
      label: brand.name,
      value: brand.id?.toString(),
    }));
  }

  loadBrands() {
    axios
      .put("/code/brands", {
        companyId: this.companyId,
      })
      .then(({ data }) => {
        this.brands = data;
      });
  }

  getCategoriesBreadCrumb(categoryId) {
    let store = this;
    let category = this.categoriesMap.get(categoryId);
    let categoriesBreadCrumb = "";
    if (category?.up_cate_id && category?.up_cate_id != 0) {
      categoriesBreadCrumb = `${store.getCategoriesBreadCrumb(
        category?.up_cate_id
      )} >`;
    }
    return category?.cate_nm
      ? `${categoriesBreadCrumb} ${category?.cate_nm}`
      : "";
  }

  getOrgBreadCrumb(orgId) {
    let store = this;
    let org = this.orgs.find((org) => org.org_id == orgId);
    let orgBreadCrumb = "";
    if (org?.up_cate_id && org?.up_cate_id != 0) {
      orgBreadCrumb = `${store.getOrgBreadCrumb(org?.up_cate_id)} >`;
    }
    return org?.full_org_nm ? `${orgBreadCrumb} ${org?.full_org_nm}` : "";
  }

  @observable
  managedKeyword = [];

  // getManagedKeyword = () => {
  //   axios.get(`/code/keywords`).then(({ data }) => {
  //     this.managedKeyword =
  //       data?.map((keyword) => ({
  //         label: keyword.voc_kwd_txt,
  //         value: keyword.voc_kwd_cd,
  //       })) || [];
  //   });
  // };

  getFavoritesOrg = () => {
    axios.post("/agent/favorites/org", { id: this.id }).then(({ data }) => {
      this.favorOrgIds.replace(data);
    });
  };

  @observable
  closeCollapses = new Map();
}

const appStore = new AppStore();
window.appStore = appStore;
export default appStore;
