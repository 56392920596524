import React, {Component} from 'react';
import {observer} from "mobx-react";
import Frame from "../../components/layout/Frame";
import TitlePanel from "../../components/layout/TitlePanel";
import {Button, Col, FormGroup} from "react-bootstrap";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import RankDialog, {rankDialogStore} from "./RankDialog";
import Panel from "../../components/layout/Panel";
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import appStore from "../../store/AppStore";
import moment from "moment/moment";
import {roadShowDialogStore} from "../roadshow/RoadShowDialog";
import Avatar from "../../components/avatar/Avatar";
import FrameWrap from "../../components/layout/FrameWrap";

const dataGridStore = new DataGridStore({
    fetchUrl: '/rank/list',
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
    noHeader: true
});

@observer
class RankView extends Component {

    componentDidMount() {
        dataGridStore.refresh();
    }

    componentWillUnmount() {
        dataGridStore.searchCondition.clear();
    }

    render() {
        return (
            <Frame>
                <FrameWrap>
                    <TitlePanel>
                        <FormGroup>
                            <Col className={`justify-content-between align-items-center d-flex`}>
                                <TitlePanelLabel label={'일반 설정 - 직급'}/>
                                <Button variant={`outline-primary`}
                                        onClick={() => {
                                            rankDialogStore.open(undefined, () => dataGridStore.refresh(), dataGridStore.searchCondition.get('branchId'));
                                        }}
                                >
                                    + 새 직급 생성
                                </Button>

                            </Col>
                        </FormGroup>
                    </TitlePanel>
                    <Panel className={'flex-default'}>
                        <DataGrid
                            store={dataGridStore}
                            keyColumn={`id`}
                            columns={[
                                <Header key="label" id="label" name="직급"
                                        width={{min:150, current:200, max:500}}
                                        printFunction={(title) =>
                                            <div>{title}</div>
                                        }/>,
                                <Header id={`maxCapacity`} sortable={true} name={`최대티켓수량`} type={`number`}
                                        className={`justify-content-end`}
                                        width={{min:100, current:100, max:80}}
                                />,
                                <Header id={`userCount`} sortable={true} name={`사용자 수`} type={`number`}
                                        className={`justify-content-end`}
                                        width={{min:100, current:100, max:80}}
                                />,
                                <Header id={`creator`} name={`생성자`}
                                        width={{min: 36, current: 160, max: 200}}
                                        printFunction={creator=>{
                                            return (
                                                creator?.id || '' ?
                                                    <div className={'w-100 d-flex flex-row gap-2'}>
                                                        <Avatar type={'agent'} seed={creator?.id} width={'1.5rem'} src={creator?.avatar?.fullUrl}/>
                                                        <span>{creator?.name}</span>
                                                    </div>
                                                    : '-'
                                            )
                                        }}/>,
                                <Header id="createdAt" sortable={true} name={`생성일`}
                                        className={`justify-content-end`}
                                        width={{min: 100, current: 220, max: 250}} type="date"/>,

                                <Header id={`updater`} name={`수정자`}
                                        width={{min: 36, current: 160, max: 200}}
                                        printFunction={updater=>{
                                            return (
                                                updater?.id || '' ?
                                                    <div className={'w-100 d-flex flex-row gap-2'}>
                                                        <Avatar type={'agent'} seed={updater?.id} width={'1.5rem'} src={updater?.avatar?.fullUrl}/>
                                                        <span>{updater?.name}</span>
                                                    </div>
                                                    : '-'
                                            )
                                        }}/>,
                                <Header id="updatedAt" sortable={true} name={`수정일`}
                                        className={`justify-content-end`}
                                        width={{min: 100, current: 220, max: 250}} type="date"/>,

                            ]}
                            highlightClass="table-primary"
                            onRowClick={rowData => {
                                rankDialogStore.open(rowData.id, () => dataGridStore.fetch());
                            }}
                        />
                    </Panel>
                    <RankDialog/>
                </FrameWrap>
            </Frame>
        );
    }
}

export default RankView;