import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { ScrollTabNavItem, ChannelList, Chat, TicketList, ScrollTabContainer, ScrollTabContent, ScrollTabNav, ScrollTabPanel, TICKET_STATUS, Channel, NoChannel, DropDownButton, CHANNEL_STATUS } from '@qbot-chat/qbot-uikit/chat';
import { channelStore, chatStore, historyChannelStore, ticketStore, historyChatStore, ticketDetailChatStore } from '../store/AdminChatStore';
import { ArrowLeft2, PlayCircle, StopCircle, Headphone, TicketExpired, SmsTracking, ProgrammingArrow, HambergerMenu } from 'iconsax-react';
import { CustomButton } from '@qbot-chat/qbot-uikit/component';
import TicketDetailView from './ticket/TicketDetailView';
import SplitPane from 'react-split-pane';
import { observable, makeObservable, action, autorun, reaction } from 'mobx';
import Styles from './ChatView.module.scss';
import { Accordion, Badge as RBBadge, Button, Dropdown } from 'react-bootstrap';
import Badge from '../components/badge/Badge';
import { BRAND_COLOR } from '../const/AppConst';
import CloseCollapse from '../components/collapse/CloseCollapse';
import OrgDialog from '../components/dialog/OrgDialog';
import TransferDialog, { transferDialogStore } from '../components/dialog/TransferDialog';
import { confirmDialogStore } from '@qbot-chat/qbot-uikit';
import settingStore from '../store/SettingStore';
import CloseConfirmDialog, { closeConfirmDialogStore } from '../components/dialog/CloseConfirmDialog';
import { navigateReplace } from '@qbot-chat/front/src/helper/HistoryHelper';
import { MdClear } from 'react-icons/md';
import { CustomHeader } from '../components/chat/CustomHeader';
import TicketStatusActiveIcon from '../components/icon/TicketStatusActiveIcon';
import PastTicketIcon from '../components/icon/PastTicketIcon';
import TicketStatusClosedIcon from '../components/icon/TicketStatusClosedIcon';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { FaRegStopCircle } from 'react-icons/fa';
import axios from 'axios';
import QPlayer from '../components/player/QPlayer';
import { Style } from '@mui/icons-material';

class ChatViewStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  templateMessage = '';

  @observable
  size = [];

  @observable
  currentHistoryTicket = null;

  @observable
  isFindNotClosed = false;

  @action
  setCurrentHistoryTicket(currentHistoryTicket) {
    this.currentHistoryTicket = currentHistoryTicket;
  }

  subscribeChangeSize() {
    reaction(
      () => chatViewStore.currentHistoryTicket,
      (currentHistoryTicket) => {
        if (currentHistoryTicket) {
          chatViewStore.size = ['60%', '40%'];
        } else {
          chatViewStore.size = ['600px', '100%'];
        }
      }
    );
  }

  fetchFindNotClosed() {
    axios.post(`/ticket/find/not/closed`, {}).then(({ data }) => {
      this.isFindNotClosed = data;
    });
  }
}

const chatViewStore = new ChatViewStore();

@observer
class ChatView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      activeKey: 'ACTIVE',
    };
  }
  scenarioList = [];
  scenarioCompleted = [];
  currentStatus = '';

  componentDidMount() {
    chatViewStore.subscribeChangeSize();
    chatViewStore.fetchFindNotClosed();
    this.clear();
  }

  clear() {
    chatStore.setChannel(null);
    chatViewStore.setCurrentHistoryTicket(null);
    historyChannelStore.clear();
  }

  handleCurrentTextareaChange = (text) => {
    chatViewStore.templateMessage = text;
  };
  render() {
    let { setting } = settingStore;
    return (
      <div className={`d-flex flex-default`} style={{ background: '#fff' }}>
        <div className={Styles.TicketList}>
          {appStore.chatReady && (
            <div className={`d-flex flex-column w-100`} style={{ flex: 1, minHeight: 0 }}>
              <ScrollTabContainer defaultActiveKey={'ACTIVE'}>
                <div>
                  <ScrollTabNav
                    activeKey={this.state.activeKey}
                    className={`${Styles.TicketTabs} d-flex`}
                    onClick={(activeKey) => {
                      console.log('activeKey ' + activeKey);
                      if (activeKey == TICKET_STATUS.CLOSED) channelStore.refresh();
                      this.setState({ activeKey });
                    }}
                  >
                    <ScrollTabNavItem eventKey={'ACTIVE'} isBlink={ticketStore.tickets.find((ticket) => !ticket.isConfirm)}>
                      <FaRegCirclePlay size={'1.25rem'} />
                      <div>진행중 {ticketStore.tickets.length} </div>
                    </ScrollTabNavItem>
                    <ScrollTabNavItem eventKey={'CLOSED'} isBlink={chatViewStore.isFindNotClosed} blinkClassName={Styles.CloseBlink}>
                      <FaRegStopCircle size={'1.25rem'} />
                      <div>종료됨</div>
                    </ScrollTabNavItem>
                  </ScrollTabNav>
                  <ScrollTabContent>
                    <ScrollTabPanel eventKey={'ACTIVE'} activeKey={this.state.activeKey}>
                      <TicketList
                        store={ticketStore}
                        activeChannelId={chatStore?.channelId}
                        isAgent={true}
                        onChannelClick={(channel) => {
                          console.log('------시나리오 정보 가져오기------');
                          this.scenarioList = [
                            { code: 'D0005', value: '통화가능' },
                            { code: 'D0006', value: '연체사실' },
                            { code: 'D0007', value: '안내문발송' },
                            { code: 'D0012', value: '주소확인' },
                          ];
                          this.scenarioCompleted = ['D0005', 'D0006'];
                          this.currentStatus = 'D0007';
                          chatStore.setChannel(channel);
                          chatViewStore.setCurrentHistoryTicket(undefined);
                          historyChatStore.setChannel(undefined);
                          historyChannelStore.searchCondition.set(`customerId`, channel?.customer?.id);
                          historyChannelStore.refresh();
                        }}
                        onCloseCallback={(channel) => {
                          if (chatStore?.channelId == channel.id) {
                            chatStore.setChannel(null);
                            chatViewStore.setCurrentHistoryTicket(null);
                            historyChatStore.setChannel(null);
                            historyChannelStore.clear();
                          }
                        }}
                        renderLabel={(ticket) => {
                          let properties = ticket.properties;
                          if (properties) {
                            return <Badge color={BRAND_COLOR[properties['brand-id']]} location={properties['branch-name']} />;
                          } else {
                            return;
                          }
                        }}
                      />
                    </ScrollTabPanel>
                    <ScrollTabPanel eventKey={'CLOSED'} activeKey={this.state.activeKey}>
                      <ChannelList
                        store={channelStore}
                        renderCustomChannel={({ channel, store, onChannelClick, isAgent, renderLabel, onCloseCallback, activeChannelId }) => {
                          let category = channel?.category;
                          let isNotClose = !category;
                          return <Channel key={channel.id} channel={channel} store={store} onChannelClick={onChannelClick} isAgent={isAgent} renderLabel={renderLabel} onCloseCallback={onCloseCallback} activeChannelId={activeChannelId} customClassName={isNotClose ? Styles.NotClosed : ''} />;
                        }}
                        activeChannelId={chatStore?.channelId}
                        onChannelClick={(channel) => {
                          chatStore.setChannel(channel);
                          chatViewStore.setCurrentHistoryTicket(undefined);
                          historyChatStore.setChannel(undefined);
                          historyChannelStore.searchCondition.set(`customerId`, channel?.customer?.id);
                          historyChannelStore.refresh();
                        }}
                        renderLabel={(ticket) => {
                          let properties = ticket.properties;
                          if (properties) {
                            return <Badge color={BRAND_COLOR[properties['brand-id']]} location={properties['branch-name']} />;
                          } else {
                            return;
                          }
                        }}
                      />
                    </ScrollTabPanel>
                  </ScrollTabContent>
                </div>
              </ScrollTabContainer>
            </div>
          )}
          {appStore.chatReady && historyChannelStore && historyChannelStore?.channels?.length > 0 && (
            <div style={{ maxHeight: 352 }}>
              <Accordion defaultActiveKey='0'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header className={`overflow-hidden`}>
                    <span className={Styles.HistoryHeader}>최근 상담 이력</span>
                  </Accordion.Header>
                  <Accordion.Body className={`p-0 flex-default d-flex w-100`} style={{ height: 300 }}>
                    <div className={`d-flex flex-column w-100 h-100`} style={{ flex: 1, maxHeight: 300 }}>
                      <ScrollTabContent>
                        <ScrollTabPanel>
                          <ChannelList
                            store={historyChannelStore}
                            renderCustomChannel={({ channel, store, onChannelClick, isAgent, renderLabel, onCloseCallback, activeChannelId }) => {
                              let category = channel?.category;
                              let isNotClose = !category;
                              return <Channel key={channel.id} channel={channel} store={store} onChannelClick={onChannelClick} isAgent={isAgent} renderLabel={renderLabel} onCloseCallback={onCloseCallback} activeChannelId={activeChannelId} customClassName={isNotClose ? Styles.NotClosed : ''} />;
                            }}
                            activeChannelId={historyChatStore?.channelId}
                            onChannelClick={(channel) => {
                              chatViewStore.currentHistoryTicket = channel;
                              historyChatStore.setChannel(channel);
                            }}
                            renderLabel={(ticket) => {
                              let properties = ticket.properties;
                              if (properties) {
                                return <Badge color={BRAND_COLOR[properties['brand-id']]} location={properties['branch-name']} />;
                              } else {
                                return;
                              }
                            }}
                          />
                        </ScrollTabPanel>
                      </ScrollTabContent>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          )}
        </div>
        <SplitPane split='vertical' primary='second' onChange={(size) => (chatViewStore.size = size)} allowResize={chatViewStore.currentHistoryTicket?.id ? false : true} style={{ width: '100%' }}>
          {/* initialSize="30%" minSize={"32rem"} size={chatViewStore.size[0]} */}
          <SplitPane initialSize={'512px'} minSize={'512px'} allowResize={chatViewStore.currentHistoryTicket?.id ? false : true}>
            <div className={Styles.HistoryChatViewWrap}>
              {chatViewStore.currentHistoryTicket && (
                <div className={`d-flex ${Styles.HistoryChatView}`} style={{ flex: 1, width: this.currentHistoryTicket?.id && '390px' }}>
                  {/* <div className={`overflow-hidden`} style={{minHeight:0, flex:'1 1 0'}}> */}
                  <Chat
                    store={historyChatStore}
                    ref={historyChatStore.chatRef}
                    contentClassName={Styles.CustomContent}
                    renderCustomHeader={(store) => {
                      let { channel } = store;
                      let { properties, customer } = channel;
                      return (
                        <>
                          <div className={Styles.ChatHeader}>
                            <div className={Styles.TicketStatus}>
                              <div className={'d-flex flex-column justify-content-center align-items-center'}>
                                <PastTicketIcon fill={'var(--bs-secondary)'} size={'2.5rem'} />
                              </div>
                            </div>
                            <div className={Styles.NameContainer}>
                              {properties && (
                                <div className={Styles.Badge}>
                                  <Badge color={BRAND_COLOR[properties['brand-id']]} location={properties['branch-name']} />
                                </div>
                              )}
                              <div className={Styles.Name}>{customer?.name}</div>
                            </div>
                            <div className={`d-flex`}>
                              <div
                                className={Styles.CloseButton}
                                onClick={() => {
                                  chatViewStore.currentHistoryTicket = null;
                                }}
                              >
                                <MdClear size={24} />
                              </div>
                            </div>
                          </div>
                          <div className={Styles.HeaderCategory} style={{ fontSize: 10 }}>
                            {channel?.category && (
                              <>
                                <div>
                                  <RBBadge bg={`secondary`}>종료타입</RBBadge>
                                  &nbsp;
                                  {channel.category.closedType === 'CLOSE_DRAFT' ? '1차 종결' : '점포 확인 후 종결'}
                                </div>
                                <div>
                                  <RBBadge bg={`secondary`}>카테고리</RBBadge>
                                  {appStore.getCategoriesBreadCrumb(channel.category.categoryId)}
                                </div>
                                <div>
                                  <RBBadge bg={`secondary`}>관리부서</RBBadge>
                                  &nbsp;
                                  {appStore.orgs.find((org) => org.org_id === parseInt(channel.category.orgId))?.full_org_nm}
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      );
                    }}
                    renderSubHeader={(ticket) => {
                      return (
                        <CloseCollapse
                          name={'history'}
                          ticket={ticket}
                          onSave={(ticket) => {
                            chatViewStore.fetchFindNotClosed();
                            channelStore.load();
                            historyChannelStore.load();
                            historyChatStore.channel = ticket;
                            if (ticket.id == chatStore.channelId) {
                              chatStore.channel = ticket;
                            }
                          }}
                        />
                      );
                    }}
                    backButton={
                      <CustomButton
                        onClick={() => {
                          chatViewStore.setCurrentHistoryTicket(null);
                          historyChatStore.setChannel(null);
                        }}
                      >
                        <ArrowLeft2 size={28} />
                      </CustomButton>
                    }
                    callbackLeave={(channelId, callback) => {
                      historyChannelStore.leave(channelId).then((r) => callback && callback());
                    }}
                  />
                  {/* </div> */}
                </div>
              )}
              {/* className={`d-flex flex-column`} style={{flex:'1 1 0', minHeight:0}} */}
              <div className={Styles.ChatView}>
                {/* style={{minHeight:0, flex:'1 1 0'}} */}
                {/* <div className={}> */}
                <Chat
                  store={chatStore}
                  ref={chatStore.chatRef}
                  isImageSendAble={true}
                  isImageSendMsg={true}
                  renderCustomHeader={(store) => (
                    <>
                      {!!chatStore.channel?.customer?.name && chatStore.channel?.customer.name.startsWith('010') && (
                        <div className={Style.ChatViewPlayerWrap}>
                          <QPlayer store={chatStore} />
                        </div>
                      )}
                      <CustomHeader store={store} />
                    </>
                  )}
                  // renderSubHeader={(ticket) => {
                  //   return (
                  //     <CloseCollapse
                  //       name={'current'}
                  //       ticket={ticket}
                  //       onSave={(ticket) => {
                  //         chatViewStore.fetchFindNotClosed();
                  //         channelStore.load();
                  //         historyChannelStore.load();
                  //         chatStore.channel = ticket;
                  //         if (ticket.id == historyChatStore.channel?.id) {
                  //           historyChatStore.channel = ticket;
                  //         }
                  //       }}
                  //     />
                  //   );
                  // }}
                  backButton={
                    <CustomButton
                      onClick={() => {
                        this.clear();
                        // chatStore.setChannel(null)
                      }}
                    >
                      <ArrowLeft2 size={24} />
                    </CustomButton>
                  }
                  onCloseCallback={(channel) => {
                    ticketStore.deleteTicket(channel.id);
                    chatStore.setChannel(null);
                    chatViewStore.setCurrentHistoryTicket(null);
                    historyChatStore.setChannel(null);
                    historyChannelStore.clear();
                  }}
                  callbackLeave={(channelId, callback) => {
                    channelStore.leave(channelId).then((r) => callback && callback());
                  }}
                />
                {/* </div> */}
              </div>
              {/* {this.scenarioList.length > 0 && (
                <div className={Styles.ScenarioArea}>
                  <div className={Styles.ScenarioTop}>시나리오 진행</div>
                  <div className={Styles.ScenarioWrap}>
                    {this.scenarioList.map((e) => {
                      return (
                        <div className={`${Styles.ScenarioItem} ${e.code === this.currentStatus ? Styles.Current : this.scenarioCompleted.filter((j) => e.code === j).length === 0 ? Styles.Yet : ''}`}>
                          <span className={Styles.Label}>{e.code}</span>
                          <span className={Styles.Text}>{e.value}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )} */}
            </div>
          </SplitPane>
          {/*  minSize={"540px"} initialSize="70%" size={chatViewStore.size[1]}*/}
          <SplitPane initialSize='calc(100% - 512px)' allowResize={chatViewStore.currentHistoryTicket?.id ? false : true}>
            <div className={Styles.DetailContainer}>{chatStore.channel && <TicketDetailView ticket={chatStore.channel} historyTickets={[]} currentTextareaChange={this.handleCurrentTextareaChange} store={chatStore} />}</div>
          </SplitPane>
        </SplitPane>
        <TransferDialog ticket={chatStore.channel} />
        <CloseConfirmDialog />
        <OrgDialog />
      </div>
    );
  }
}

export { ChatView as default, chatViewStore };
