import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Button, Col, Form, FormControl, FormGroup, FormLabel, InputGroup, Modal, Row} from "react-bootstrap";
import {FormStore, Input, Validation} from "../../components/validation";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {chatBotDataGridStore} from "./ChatbotManageListView";
import {goBack, navigateReplace} from "../../helper/HistoryHelper";
import Panel from "../../components/layout/Panel";
import UploadImage from "../../components/avatar/UploadImage";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import DefaultChatbotImage from "../../components/avatar/DefaultChatbotImage";
import {ConfirmDialog, confirmDialogStore} from "@qbot-chat/qbot-uikit";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import {Warning2} from "iconsax-react";

const form = new FormStore();

@observer
class ChatbotManageDetailView extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.id) {
            this.fetchDetail(params.id);
        } else {
            form.clear({maxCapacity: 1})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if(params.id != prevParams.id){
            if (params && params.id ) {
                this.fetchDetail(params.id);
            }else if(!params.id){
                form.clear()
            }
        }
    }

    fetchDetail(id){
        axios.post(
            `/agent/detail/${id}`,
            {}
        ).then(res=>{
            form.values = res.data;
        });
    }

    save(){
        if(!form.doCheckValid()){
            NotificationManager.warning(form.firstErrorMessage)
            return;
        }

        if(!!this.props.match.params.id){
            axios.post(
                `/agent/bot/update`,
                form.values
            ).then(res=>{
                let user = res.data;
                let id = res.data
                chatBotDataGridStore.fetch()
                this.fetchDetail(id);
                NotificationManager.success('변경사항이 저장되었습니다.')
            })
        }else{
            axios.post(
                `/agent/bot/insert`,
                form.values
            ).then(res=>{
                let id = res.data;
                NotificationManager.success('챗봇이 등록되었습니다.')
                chatBotDataGridStore.fetch()
                navigateReplace(`/main/setting/manage/chat_bot/detail/${id}`)
            })
        }
        this.ref.setState({isNew:false})
    }

    delete() {
        if(!!this.props.match.params.id){
            confirmDialogStore.openRemove(
                <Warning2/>,
                `삭제 하시겠습니까?`,
                () => {
                    axios.post(
                        `/agent/bot/delete`,
                        form.values
                    ).then(res=>{
                        NotificationManager.success('상담사가 삭제되었습니다.')
                        navigateReplace(`/main/bot/list`);
                    }).catch(e => {
                        console.error(e)
                    })
                }
            )
        }
    }

    render() {

        let isNew = !form.valueMap.get('id');
        return (
          <div>
            <TitlePanel>
              <FormGroup>
                <Col className={`justify-content-between align-items-center d-flex`}>
                  {isNew ? <TitlePanelLabel label={'챗봇 추가'} /> : <TitlePanelLabel label={'챗봇 정보 수정'} />}
                  <div>
                    <Button variant={'outline-danger'} onClick={() => this.delete()}>
                      삭제
                    </Button>
                    <Button variant={'outline-secondary'} className={`ms-4`} onClick={() => goBack()}>
                      취소
                    </Button>
                    <Button variant={'outline-primary'} className={`ms-1`} onClick={() => this.save()}>
                      저장
                    </Button>
                  </div>
                </Col>
              </FormGroup>
            </TitlePanel>
            <Panel>
              <Form onSubmit={(e) => e.preventDefault()}>
                <FormGroup as={Row}>
                  <Col className={`mb-3`}>
                    <FormLabel>ID</FormLabel>
                    <Input store={form} label={`ID`} name={`id`} placeholder={`자동생성`} disabled={true} validOnBlur />
                  </Col>
                  <Col className={`mb-3`}>
                    <FormLabel>이름</FormLabel>
                    <Input store={form} label={`이름`} name={`name`} placeholder={`이름을 입력해주세요`} validations={[new Validation(/^.+$/, `이름을 입력하세요.`)]} validOnBlur />
                  </Col>
                </FormGroup>
                <FormGroup as={Row}>
                  <Col className={`mb-3`}>
                    <FormLabel>Answer Engine URL (A/E)</FormLabel>
                    <InputGroup>
                      <Input store={form} label={`Answer Engine URL (A/E)`} name={`answerUrl`} placeholder={`answerUrl`} validations={[new Validation(/^.+$/, `Answer Engine URL을 입력하세요.`)]} validOnBlur />
                    </InputGroup>
                  </Col>
                  <Col className={`mb-3`}>
                    <FormLabel>Dialogue Engine URL (D/E)</FormLabel>
                    <Input store={form} label={`Dialogue Engine URL (D/E)`} name={`dialogueUrl`} placeholder={`dialogueUrl`} validations={[new Validation(/^.+$/, `Dialogue Engine URL을 입력하세요.`)]} validOnBlur />
                  </Col>
                </FormGroup>
                <FormGroup as={Row}>
                  <Col className={`mb-3`}>
                    <FormLabel>인사말</FormLabel>
                    <Input store={form} as={'textarea'} rows={3} label={`인사말`} name={`greeting`} placeholder={`입력하지 않은 경우, 기본 설정된 인사말이 출력 됩니다.`} />
                  </Col>
                </FormGroup>
                <FormGroup as={Row}>
                  <Col className={`mb-3`}>
                    <FormLabel>프로필이미지</FormLabel>
                    <div className={`d-flex flex-row gap-2 w-100`}>
                      <UploadImage
                        url={form.valueMap.get('avatar.fullUrl')}
                        ref={(ref) => (this.ref = ref)}
                        useUrl={true}
                        useFile={true}
                        selectImage={(image) => {
                          form.setValue('avatar.id', null);
                          form.setValue('avatar.type', image.type);
                          form.setValue('avatar.url', image.url);
                          form.setValue('avatar.fullUrl', image.fullUrl);
                        }}
                        resetImage={() => {
                          form.setValue('avatar.id', null);
                          form.setValue('avatar.type', null);
                          form.setValue('avatar.url', null);
                          form.setValue('avatar.fullUrl', null);
                        }}
                        noImage={
                          <RandomColor seed={form.valueMap.get('id')}>
                            <DefaultChatbotImage />
                          </RandomColor>
                        }
                      />
                    </div>
                  </Col>
                </FormGroup>
              </Form>
            </Panel>
            <ConfirmDialog />
          </div>
        );
    }
}

export default ChatbotManageDetailView;
