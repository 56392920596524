import React from 'react';
import Draggable from "react-draggable";
import Styles from './DragLayerPopup.module.scss';
import {v4 as uuid} from 'uuid'
import Panel from "./Panel";
import {MdUnfoldLess, MdUnfoldMore, MdClear} from 'react-icons/md'
import {observer} from "mobx-react";

@observer
class DragLayerPopup extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isFold : false,
        }
    }

    render() {
        let {children, id = `handle_${uuid()}`, title, onClose, maxHeight = 300, maxWidth = 300} = this.props;
        return (
            <Draggable bounds="body"
                       handle={`.${id}`}
            >
                <Panel className={`${Styles.DragLayerPopup}`} style={{maxHeight, maxWidth, padding:0}}>
                    <div className={`${id} ${Styles.Header}`}>
                        <div className={Styles.Title}>
                            {title}
                        </div>
                        <div className={`d-flex align-items-center`}>
                            <a className={Styles.Fold} onClick={()=>{
                                this.setState({isFold:!this.state.isFold})
                            }}>
                                <MdUnfoldLess size={'1.5rem'} />
                            </a>
                            <a className={Styles.Close} onClick={()=>{
                                onClose && onClose();
                            }}>
                                <MdClear size={'1.5rem'} />
                            </a>
                        </div>
                    </div>
                    <div className={`${Styles.Content} ${this.state.isFold? Styles.Fold:''}`}>
                        {children}
                    </div>
                </Panel>
            </Draggable>
        );
    }
}

export {DragLayerPopup as default};
