import React, { Component } from "react";
import { observer } from "mobx-react";
import { FormStore, Input, Validation } from "../../components/validation";
import axios from "axios";
import { noticeDataGridStore } from "./NoticeListView";
import { goBack, navigateReplace } from "../../helper/HistoryHelper";
import { NotificationManager } from "react-notifications";
import TitlePanel from "../../components/layout/TitlePanel";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import Panel from "../../components/layout/Panel";
import {
  ConfirmDialog,
  confirmDialogStore,
  USER_TYPE,
} from "@qbot-chat/qbot-uikit";
import { makeObservable, observable } from "mobx";
import { Warning2 } from "iconsax-react";
import dayjs from "dayjs";
import { DatePicker, Popover } from "antd";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { ACCESS_RIGHTS } from "../../const/AppConst";

const { RangePicker } = DatePicker;
const form = new FormStore();
const dateFormat = "YYYY-MM-DD";

@observer
class NoticeDetailView extends Component {
  @observable
  isLoginIdUsed = true;

  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    makeObservable(this);
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/notice/detail/${id}`, {}).then((res) => {
      form.values = res.data;
      const resData = Object.keys(res.data);
      const fileData = [];
      console.log(res.data);
      if (resData.includes("file1")) {
        fileData.push({
          file1: res.data.file1,
          fileName: res.data.file1Name,
        });
      }
      if (resData.includes("file2")) {
        fileData.push({
          file2: res.data.file2,
          fileName: res.data.file2Name,
        });
      }
      this.setState({
        files: [...fileData],
      });
      console.log(fileData);
    });
  }

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }

    if (!!this.props.match.params.id) {
      axios.post(`/notice/update`, form.values).then((res) => {
        let id = res.data;
        noticeDataGridStore.fetch();
        this.fetchDetail(id);
        NotificationManager.success("변경사항이 저장되었습니다.");
      });
    } else {
      axios.post(`/notice/insert`, form.values).then((res) => {
        let id = res.data;
        NotificationManager.success("공지사항이 등록되었습니다.");
        noticeDataGridStore.fetch();
        navigateReplace(`/main/notice/list`);
      });
    }
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
        axios
          .post(`/nodice/delete`, form.values)
          .then((res) => {
            NotificationManager.success("해당 공지사항이 삭제되었습니다.");
            navigateReplace(`/main/notice/list`);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  setNoticeDate(dates) {
    dates[0] &&
      form.setValue("startedAt", dayjs(dates[0]).format("YYYY-MM-DD"));
    dates[1] && form.setValue("endedAt", dayjs(dates[1]).format("YYYY-MM-DD"));
  }

  async filesUpload(e) {
    const files = Array.from(e.target.files);
    if (files && files.length > 2) {
      return NotificationManager.error("파일은 두개까지만 업로드 가능합니다.");
    }
    const promises = files.map((file, idx) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const data = {
            fileName: file.name,
            fileData: reader.result.split(",")[1],
          };
          resolve(data);
          form.setValue(`file${idx + 1}`, data.fileData);
          form.setValue(`file${idx + 1}Name`, data.fileName);
        };
        reader.onerror = (error) => reject(error);
      });
    });
    const encodedFiles = await Promise.all(promises);
    this.setState({
      files: encodedFiles,
    });
  }

  fileDelete(idx) {
    const prevData = [...this.state.files];
    prevData.splice(idx, 1);
    this.setState({
      files: [...prevData],
    });
  }

  render() {
    let isNew = !this.props.match.params.id;
    let noticeStartedAt = form.values.startedAt;
    let noticeEndedAt = form.values.endedAt;
    const { files } = this.state;
    return (
      <div>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={"공지사항 추가"} />
              ) : (
                <TitlePanelLabel label={"공지사항 수정"} />
              )}
              <div>
                {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) &&
                  !isNew && (
                    <Button
                      variant={"outline-danger"}
                      onClick={() => this.delete()}
                    >
                      삭제
                    </Button>
                  )}
                <Button
                  variant={"outline-secondary"}
                  className={"ms-4"}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) && (
                  <Button
                    variant={"outline-primary"}
                    className={`ms-1`}
                    onClick={() => this.save()}
                  >
                    저장
                  </Button>
                )}
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup className="w-50" as={Row}>
              <Col className={`mb-5`}>
                <FormLabel>제목</FormLabel>
                <Input
                  store={form}
                  label={`제목`}
                  name={`title`}
                  placeholder={`제목을 입력해주세요.`}
                  disabled={false}
                  validOnBlur
                />
              </Col>
            </FormGroup>
            <FormGroup className="w-50" as={Row}>
              <Col className={`mb-5`}>
                <FormLabel>공지사항 기간 선택</FormLabel>
                <div className="w-50">
                  <RangePicker
                    placeholder={["시작일", "종료일"]}
                    size="large"
                    format={dateFormat}
                    allowEmpty
                    value={[
                      noticeStartedAt && dayjs(noticeStartedAt, dateFormat),
                      noticeEndedAt && dayjs(noticeEndedAt, dateFormat),
                    ]}
                    onChange={(dates) => {
                      this.setNoticeDate(dates);
                    }}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-5`}>
                <Button
                  variant={`outline-primary`}
                  onClick={() => this.fileInput.click()}
                >
                  파일 업로드
                </Button>
                <Popover
                  placement="rightTop"
                  title={"파일업로드"}
                  content={"최대 2개까지 업로드 가능합니다"}
                  arrow={false}
                >
                  <QuestionCircleOutlined className="ms-2" />
                </Popover>
                <input
                  type="file"
                  multiple
                  ref={(ref) => (this.fileInput = ref)}
                  style={{ display: "none" }}
                  onChange={(e) => this.filesUpload(e)}
                />
                <div className="mt-2">
                  {files?.map((file, index) => (
                    <div key={index} className="d-flex align-items-center mb-1">
                      {file.fileName}
                      <CloseOutlined
                        className="mt-1 ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.fileDelete(index)}
                      />
                    </div>
                  ))}
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>내용</FormLabel>
                <Input
                  store={form}
                  as={"textarea"}
                  label={`내용`}
                  name={`content`}
                  placeholder={`이름을 입력해주세요`}
                />
              </Col>
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </div>
    );
  }
}

export default NoticeDetailView;
