import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {Container, Nav, Tab} from "react-bootstrap";
import TimePanel from "./TimePanel";
import {makeObservable, observable} from "mobx";
import axios from "axios";
import settingStore from "../../../store/SettingStore";
import Panel from "../../../components/layout/Panel";
import TitlePanel from "../../../components/layout/TitlePanel";
import TitlePanelLabel from "../../../components/label/TitlePanelLabel";

@observer
class TicketTimeEventManageView extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (

            <div className={`${Styles.Tab} h-100`}>

                <div className={'h-100 w-100 d-flex flex-column'}>
                    <TitlePanel>
                        <TitlePanelLabel label={'이벤트 설정 - 타임 이벤트'} />
                    </TitlePanel>
                    <TimePanel/>
                </div>
            </div>
        );
    }
}

export default TicketTimeEventManageView;