import React from 'react';
import {observer} from "mobx-react";
import Styles from './MessageTemplateEditor.module.scss'
import ContentStyles from './ContentEditor.module.scss'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ContentEditor from "./ContentEditor";
import {CONTENT_TYPE} from "@qbot-chat/qbot-uikit/chat";

const SortableItem = observer(SortableElement(({content, idx, onChangeItem, onDeleteItem, textContentCount}) =>{
    return <ContentEditor content={content} idx={idx} onChangeItem={onChangeItem} onDeleteItem={onDeleteItem} textContentCount={textContentCount}/>
}));

const SortableList = observer(SortableContainer(({content, onChangeItem, onDeleteItem}) => {
    let contents = content.value || [];
    return (
        <div>
            {contents?.map((content, index) => (
                <SortableItem key={`item-${index}`}
                              index={index}
                              idx={index}
                              content={content}
                              onChangeItem={onChangeItem}
                              onDeleteItem={onDeleteItem}
                              textContentCount={contents?.filter(content=>content.type == CONTENT_TYPE.TEXT)?.length || 1}
                />
            ))}
        </div>
    )
}));

@observer
class MessageTemplateEditor extends React.Component{
    render() {
        let {content, onSortStart, onSortEnd, onChangeItem, onDeleteItem} = this.props;
        return(
           <SortableList content={content}
                         onSortEnd={onSortEnd}
                         onChangeItem={onChangeItem}
                         onDeleteItem={onDeleteItem}
                         helperClass={ContentStyles.Grabbing}
                         useDragHandle
           />
        );
    }
}

export default MessageTemplateEditor;