import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {
    Alert,
    Button,
    Col,
    Row,
    Collapse,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Nav,
    OverlayTrigger,
    Tooltip, Popover
} from "react-bootstrap";
import {FormStore} from "../../../components/validation";
import axios from "axios";
import {CONTENT_TYPE} from "@qbot-chat/qbot-uikit/chat";
import {NotificationManager} from "react-notifications";
import {TimePicker} from 'antd';
import dayjs from 'dayjs';
import {InfoCircle} from "iconsax-react";
import {MentionInput} from "@qbot-chat/qbot-uikit/component";
import {TEMPLATE_CONSTANT_KEYS, TEMPLATE_CONSTANTS, TEMPLATE_OPTIONS} from '../../../const/AppConst';
import settingStore from "../../../store/SettingStore";

const duration = require('dayjs/plugin/duration')
dayjs.extend(duration);


@observer
class SettingMessageEditor extends Component {

    constructor(props) {
        super(props);
        let message = props.message;
        this.state = {
            message
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(this.props.message, prevProps.message)){
            let {message} = this.props;
            this.setState({
                message
            })
        }
    }

    render() {
        let {message, description, title = '제목', badge, isUseMention = false, id} = this.props;
        let isSame = (message === this.state?.message);
        return (
            <div className={Styles.TicketEventEditor}>
                <div className={`${Styles.Title} ${Styles.Label}`}>
                    <FormGroup as={Row} className={`w-100`}>
                        <Col md={8}>
                            <div className={`d-flex`}>
                                {title}
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}

                                    overlay={(props)=>
                                        <Popover {...props}>
                                            <Popover.Body>
                                                <InfoCircle color={`var(--bs-info)`} className={`me-2`}/>{description}
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <div className={`d-flex align-items-center`}>
                                        <InfoCircle size={16} color={`var(--bs-gray-500)`} className={Styles.Info} />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </Col>
                        <Col md={4}>
                            {
                                badge
                            }
                        </Col>
                    </FormGroup>
                </div>
                <div className={Styles.EditorContainer}>
                    <FormGroup as={Row}>
                        <Col md={4}>
                            <FormLabel className={Styles.Label}>
                                메시지 설정
                            </FormLabel>
                        </Col>
                        <Col md={8}>
                            <MentionInput value={this.state?.message}
                                          onChange={(message)=>{this.setState({message})}}
                                          options={TEMPLATE_OPTIONS.filter(option=>!option.id.startsWith(`CUSTOMER`))}
                                          renderHighlight={(id, display) => {
                                              return (TEMPLATE_OPTIONS.find( option => option.id === id))?.label || ''
                                          }}
                                          isUseMention={isUseMention}
                            />
                            {
                                !this.state.message &&
                                    <div className={Styles.FeedbackMessage}>
                                        메시지를 입력하세요.
                                    </div>
                            }
                        </Col>
                    </FormGroup>
                </div>
                <Collapse in={!isSame}>
                    <div className={`${Styles.ButtonContainer}`}>
                        <Button variant={"outline-secondary"} className={Styles.Button} onClick={()=>this.setState({message})}>취소</Button>
                        <Button variant={"outline-primary"}  className={Styles.Button} disabled={!this.state.message}
                                onClick={()=>{
                                    let data = {...settingStore.setting}
                                    data[id] = this.state.message;
                                    axios.post(`/setting/update`,
                                        data,
                                    ).then(res=>{
                                        settingStore.fetchSettingInfo()
                                        NotificationManager.success(`업데이트 완료`);
                                    })
                                }}
                        >
                            저장
                        </Button>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default SettingMessageEditor;