import React, {Component} from 'react';
import {observer} from "mobx-react";
import {DataGrid, DataGridStore, Header} from "@qbot-chat/qbot-uikit";
import {Sort} from "@qbot-chat/qbot-uikit/grid";
import {computed, makeObservable, observable} from "mobx";
import dayjs from "dayjs";
import axios from "axios";
import {Col, Image, Row} from "react-bootstrap";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import Panel from "../../components/layout/Panel";
import ReactApexChart from "react-apexcharts";
import DefaultTeamImage from "../../components/avatar/DefaultTeamImage";
import CommonHelper from "../../helper/CommonHelper";
import InfoReport from "./InfoReport";
import Styles from "./Report.module.scss";
import Avatar from "../../components/avatar/Avatar";

const teamReportDataGridStore = new DataGridStore({
    fetchUrl: `/report/team/list`,
    selectFnFromResponse: (res) => ({items: res.data.items, rowsCount: res.data.count}),
    sort: new Sort(`team`, Sort.SORT_DIRECTION.ASC),
    noHeader: true
})
@observer
class TeamReport extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @observable
    datas = [];

    @observable
    hourDatas = [];

    @computed
    get dayCategories(){
        return this.datas?.map(data => dayjs(data.createdAt).format('MM월 DD일'));
    }

    timeCategories(){
        let arr = [];
        for(let i = 0; i <= 12; i++){
            arr.push(`오전:${i}:00`);
        }
        for(let i = 1; i < 12; i++){
            arr.push(`오후:${i}:00`);
        }
        return arr;
    }

    @computed
    get closeTicket() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "종료된 티켓",
                    data: this.datas?.map(data => data.closeTicketCount)
                }
            ]
        }
    }

    @computed
    get avgResponse() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                yaxis: {
                    labels: {
                        formatter: CommonHelper.timeFormat
                    },
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "평균 최초 응답 시간",
                    data: this.datas?.map(data => data.avgResponseTime)
                }
            ]
        }
    }

    @computed
    get avgComplete() {

        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                yaxis: {
                    labels: {
                        formatter: CommonHelper.timeFormat
                    },
                },
                xaxis: {
                    categories: this.dayCategories
                }
            },
            series: [
                {
                    name: "평균 티켓 해결 시간",
                    data: this.datas?.map(data => data.avgCompleteTime)
                }
            ]
        }
    }

    @computed
    get hoursCloseMsg() {
        return {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: this.timeCategories()
                }
            },
            series: [
                {
                    name: "평균 티켓 수",
                    data: this.hourDatas.map(data => data.closeTicketCount)
                }
            ]
        }
    }

    @computed
    get ratingCount() {

        return {
            options: {
                chart: {
                    width: 380,
                    type: 'donut',
                    toolbar: {
                        show: true,
                    }
                },
                labels: ['5점','4점','3점','2점','1점'],
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: "총 응답 수"
                                }
                            }
                        }
                    }
                }
            },
            series: [
                this.datas?.map(data => data.ratingFivePointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingFourPointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingThreePointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingTwoPointCount).reduce((x,y) => x+y, 0)
                ,this.datas?.map(data => data.ratingOnePointCount).reduce((x,y) => x+y, 0)
            ]
        }
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {startDate, endDate, selectedTeam} = this.props;
        if(prevProps.startDate !== startDate || prevProps.endDate !== endDate || prevProps.selectedTeam !== selectedTeam) {
            this.fetch();
        }
    }

    fetch = () => {
        const {startDate, endDate, selectedTeam} = this.props;
        teamReportDataGridStore.page = 1;
        teamReportDataGridStore.searchCondition.clear();
        teamReportDataGridStore.searchCondition.set("startDate", startDate);
        teamReportDataGridStore.searchCondition.set("endDate", endDate);
        teamReportDataGridStore.searchCondition.set("teamId", selectedTeam? selectedTeam.id : null)
        teamReportDataGridStore.fetch();
        this.teamReport();
    }

    teamReport = () => {
        const {startDate, endDate, selectedTeam} = this.props;
        axios.post(
            `/report/team`,
            {
                startDate,
                endDate,
                teamId: selectedTeam? selectedTeam.id : null
            }
        ).then(res=>{
            this.datas = res.data.teamReports;
            this.hourDatas = res.data.teamHourReports;
        });
    }

    avatar = (team) => {
        return <>
            {
                team.imageUrl ?
                    <Image className={'rounded-circle'} style={{width: "2rem"}} src={team.imageUrl} />
                    :
                    <div style={{width: "2rem"}}>
                        <RandomColor  seed={team.id}>
                            <DefaultTeamImage className={'rounded-circle'}/>
                        </RandomColor>
                    </div>
            }
            <div className={'ms-1'}> {team.name} </div>
        </>;
    }

    viewReturn = () => {
        const {viewMode} = this.props;
        return {
            table : <Panel className={'flex-grow-1'}>
                <DataGrid store={teamReportDataGridStore}
                          keyColumn={`id`}
                          columns={[
                              <Header id={`team`} name={`이름`}  printFunction={team => {
                                  return (
                                      <div className={'w-100 d-flex flex-row gap-2'}>
                                          <Avatar type={'team'} seed={team.id} width={'1.5rem'} src={team?.avatar?.fullUrl}/>
                                          <span>{team.name}</span>
                                      </div>
                                  )
                              }} sortable/>,
                              <Header id={`assignmentTicketCount`} width={{min: 110,  current: 110}} name={`받은 티켓`} sortable
                              info={`팀이 한 번이라도 배정받았던 총 티켓 수입니다. 동일한 티켓을 여러 번 배정 혹은 이관받아도 1개의 티켓으로 계산됩니다.`}/>,
                              <Header id={`closeTicketCount`} width={{min: 120,  current: 120}} name={`종료된 티켓`} sortable
                              info={`팀이 종료한 총 티켓 수입니다.`}/>,
                              <Header id={`avgRating`} width={{min: 150, current: 150}} name={`평균 고객 만족도`} printFunction={avgRating =>
                                  avgRating || "-" } sortable info={`팀이 받은 평균 고객 만족도 점수입니다.`}/>,
                              <Header id={`avgResponseTime`} width={{min: 170, current: 170}} name={`평균 최초 응답 시간`} printFunction={avgResponseTime =>
                                  avgResponseTime ? CommonHelper.timeFormat(avgResponseTime) : "-"} sortable info={`최초 응답 시간의 총합을 최초 응답을 받은 메시지 수로 나눈 수입니다.`}/>,
                              <Header id={`avgCompleteTime`} width={{min: 170, current: 170}} name={`평균 티켓 해결 시간`} printFunction={avgCompleteTime =>
                                  avgCompleteTime ? CommonHelper.timeFormat(avgCompleteTime) : "-"} sortable info={`팀의 총 티켓 해결 시간을 종료된 티켓 수로 나눈 수입니다. 티켓 해결 시간은 티켓 생성부터 티켓 종료까지 걸린 시간을 의미합니다.`}/>
                          ]}
                />
            </Panel>,
            graph : <div className={Styles.AgentContainer}>
                <div className={Styles.Panel}>
                    <InfoReport title={"종료된 티켓"} summary={"모든 혹은 선택한 팀이 종료한 티켓 수입니다."} />
                    <ReactApexChart
                        options={this.closeTicket.options}
                        series={this.closeTicket.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"평균 최초 응답 시간"} summary={"배정 시간 부터 최초 응답 시간 까지의 평균 시간 입니다."} />
                    <ReactApexChart
                        options={this.avgResponse.options}
                        series={this.avgResponse.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"평균 티켓 해결 시간"} summary={"팀의 총 티켓 해결 시간을 종료된 티켓 수로 나눈 수입니다. 티켓 해결 시간은 마지막 배정 시간부터  마지막 상담사의 메시지 시간을 의미합니다."} />
                    <ReactApexChart
                        options={this.avgComplete.options}
                        series={this.avgComplete.series}
                        type={"line"}
                    />
                </div>
                <div className={Styles.Panel}>
                    <InfoReport title={"시간당 종료된 티켓"} summary={"모든 혹은 선택한 팀이 시간당 종료한 평균 티켓 수입니다."} />
                    <ReactApexChart
                        options={this.hoursCloseMsg.options}
                        series={this.hoursCloseMsg.series}
                        type={"bar"}
                    />
                </div>
                <div className={`${Styles.Panel} d-flex flex-column justify-center`}>
                    <InfoReport title={"고객 만족도"} summary={"모든 혹은 선택한 팀이 받은 평균 고객 만족도 점수입니다."} />
                    <div className={'d-flex justify-center'}>
                        <ReactApexChart
                            options={this.ratingCount.options}
                            series={this.ratingCount.series}
                            type={"donut"}
                            width={380}
                        />
                    </div>
                </div>
            </div>
        }[viewMode]
    }
    render() {
        return (
            <div className={'h-100 d-flex flex-column'}>
                {this.viewReturn()}
            </div>
        );
    }
}

export {TeamReport as default, teamReportDataGridStore};