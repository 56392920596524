import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="TicketStatusActiveIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <g>
            <path
                d="M22.8,15c0,4.4-1.3,5.8-5.8,5.8H7c-4.4,0-5.8-1.3-5.8-5.8v-0.5c0-0.4,0.3-0.8,0.8-0.8c1,0,1.8-0.8,1.8-1.8 S3,10.2,2,10.2c-0.4,0-0.8-0.3-0.8-0.8V9c0-4.4,1.3-5.8,5.8-5.8h10c4.4,0,5.8,1.3,5.8,5.8 M21.2,9c0-3.6-0.7-4.2-4.2-4.2H7 c-3.5,0-4.2,0.7-4.2,4.1c1.4,0.3,2.5,1.6,2.5,3.2s-1.1,2.8-2.5,3.2c0,3.4,0.7,4.1,4.2,4.1h10c3.3,0,4.1-0.6,4.2-3.6"
            />
            <path
                d="M15.1,11.3l-3.9-2.2C10.7,8.8,10,9.1,10,9.8v4.5c0,0.6,0.7,1,1.2,0.7l3.9-2.2C15.6,12.4,15.6,11.6,15.1,11.3z"
            />
        </g>
    </svg>
};
export default SVGComponent;