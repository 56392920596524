import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Styles from './Section.module.scss';
import Required from './Required';
import HorizontalDivider from './HorizontalDivider';

class Section extends Component {

    render() {
        const {title, required, children, isDivided, rightIcon} = this.props;
        return (
            <div className={Styles.Section}>
                <div className={Styles.Title}>
                    <div className="d-flex align-items-center flex-default">
                        {title}
                        {
                            required && <Required/>
                        }
                    </div>
                    {
                        rightIcon
                    }
                </div>
                <div className={Styles.Content}>
                    {children}
                </div>
                {
                    isDivided && <HorizontalDivider/>
                }
            </div>
        );
    }
}

Section.propTypes = {};

export default Section;
