import React, {useState} from "react";
import {More} from "iconsax-react";
import axios from "axios";
import dayjs from "dayjs";
import Styles from "../team/Team.module.scss";
import Avatar from "../../components/avatar/Avatar";
import {FaStar} from "react-icons/fa6";
import appStore from "../../store/AppStore";
import {Badge, Dropdown} from "react-bootstrap";
import {navigate} from "../../helper/HistoryHelper";
import {confirmDialogStore, USER_TYPE} from "@qbot-chat/qbot-uikit";
import {teamAgentDialogStore} from "./MemberDialog";
import {ACCESS_RIGHTS} from "../../const/AppConst";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    const [color, setColor] = useState('#6C757D');

    return <a
        className={'align-self-start'}
        href=""
        ref={ref}
        onMouseOver={() => setColor('#007DFC')}
        onMouseLeave={() => setColor('#6C757D')}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <More size="16" color={color}/>
    </a>
});



const MemberCard = (props) => {
    const agentRemove = () => {
        confirmDialogStore.open({
                title: `팀원 삭제`,
                summary: <>
                    <div className={`${Styles.Font} ${Styles.FontA}`}>아래 상담원을 팀에서 제외 합니다.</div>
                    <div className={`${Styles.Font} ${Styles.FontC}`}>이름 : {props.agent.name} / 로그인ID : {props.agent.loginId}</div>
                </>,
                onOk : () => {
                    axios.post("/team/agent/delete",
                        {
                            teamId: props.team.id,
                            agentId: props.agent.id
                        }
                    ).then((res) => {
                        props.store.refresh();
                    })
                },
                okLabel: '제외',
                okColor: 'outline-danger'
            }
        )
    }

    const leaderAssign = () => {
        confirmDialogStore.open({
                title: `팀장 임명`,
            summary: <>
                <div className={`${Styles.Font} ${Styles.FontA}`}>아래 상담원을 팀장으로 임명 합니다.</div>
                <div className={`${Styles.Font} ${Styles.FontB}`}>이름 : {props.agent.name} / 로그인ID : {props.agent.loginId}</div>
            </>,
                onOk : () => {
                    axios.post("/team/leader/assign",
                        {
                            teamId: props.team.id,
                            agentId: props.agent.id
                        }
                    ).then((res) => {
                        props.store.refresh();
                    })
                },
                okLabel: '임명',
                okColor: 'outline-primary'
            }
        )

    }

    const leaderDismissal = () => {

        confirmDialogStore.open({
                title: `팀장 해임`,
                summary: <>
                    <div className={`${Styles.Font} ${Styles.FontA}`}>아래 상담원을 팀장에서 해임 합니다.</div>
                    <div className={`${Styles.Font} ${Styles.FontC}`}>이름 : {props.agent.name} / 로그인ID : {props.agent.loginId}</div>
                </>,
                onOk : () => {
                    axios.post("/team/leader/dismissal",
                        {
                            teamId: props.team.id,
                            agentId: props.agent.id
                        }
                    ).then((res) => {
                        props.store.refresh();
                    })
                },
                okLabel: '해임',
                okColor: 'outline-danger'
            }
        )
    }

    const isNewAgent = () => {
        return dayjs(props.agent.teamAddDate).isAfter(dayjs().subtract(3, 'day'))
    }

    return <div className={`${Styles.AgentCard} ${isNewAgent() ? Styles.New : Styles.Old}`} >
        <div className={Styles.CardInfo} onDoubleClick={() => navigate(`/main/member/detail/${props.agent.id}`)}>
            <div style={{width: '4rem'}}>
                <Avatar type={'agent'} seed={props.agent.id} src={props.agent.avatar?.fullUrl}/>
            </div>
            <div className={'d-flex flex-column justify-content-around h-100'}>
                <div className={'d-flex align-items-center'}>
                    {
                        props.agent.isLeader &&
                        <FaStar className={'me-1'} color={'var(--bs-warning)'} size={'0.7rem'}/>
                    }
                    <div className={Styles.Name}>{props.agent.name}</div>
                    <Badge className={'me-3'} pill bg={'secondary'}>{props.agent.rank ? props.agent.rank.label : '없음'}</Badge>
                </div>
                <div className={Styles.LoginId}>{props.agent.loginId}</div>
                <div className={Styles.Capacity}>수용가능티켓 : {props.agent.maxCapacity}</div>
            </div>
        </div>
        {
            appStore.type !== 'AGENT' && props.agent.id !== appStore.id &&
            <Dropdown className={Styles.More}>
                <Dropdown.Toggle as={CustomToggle}  id="dropdown-custom-components"/>
                <Dropdown.Menu>
                    {
                        ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) &&
                        <>
                            {
                                props.agent.isLeader ?
                                    <Dropdown.Item eventKey="1" onClick={leaderDismissal}>팀장해임</Dropdown.Item>
                                    :
                                    <Dropdown.Item eventKey="1" onClick={leaderAssign}>팀장임명</Dropdown.Item>
                            }
                        </>
                    }
                    {
                        ACCESS_RIGHTS[USER_TYPE.AGENT_LEADER].includes(appStore.type) &&
                        <>
                            <Dropdown.Item style={{color: '#F64E60'}} eventKey="2" onClick={agentRemove}>팀원삭제</Dropdown.Item>
                            <Dropdown.Item eventKey="3" onClick={() => teamAgentDialogStore.open({
                                agent: props.agent,
                                rank: props.agent.rank,
                                onSave: () => props.store.fetch()
                            })}>직급변경</Dropdown.Item>
                        </>
                    }
                </Dropdown.Menu>
            </Dropdown>
        }

    </div>
}

export {MemberCard as default};