import React, {Component} from 'react';
import Styles from './ListLabel.module.scss';
import PropTypes from 'prop-types';
import {noop} from 'lodash';

class ListLabel extends Component {
    render() {
        const {label, onClick, children} = this.props;
        return (
            <div className={Styles.ListLabel} onClick={onClick || noop}>
                <div className={Styles.Text}>
                    {label}
                    {children}
                </div>
            </div>
        );
    }
}

ListLabel.propTypes = {
    label : PropTypes.string,
    onClick: PropTypes.func,
};

export default ListLabel;
