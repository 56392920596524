import React, {useEffect, useState} from 'react';
import { useCountUp } from 'react-countup';
import Styles from './Chart.module.scss'
const SubTimeCountChart = ({data, title, size=36}) => {
    const MS_MINUTE = 60;
    const MS_SECOND = 1;
    let minute = Math.floor((data)/MS_MINUTE);
    let second = Math.floor((data-(minute*MS_MINUTE))/MS_SECOND);

    useEffect(()=>{
        startSeconds()
        startMinutes()
    },[data]);

    const secondsCountUpRef = React.useRef(null);
    const minutesCountUpRef = React.useRef(null);
    const [startSecondsCount, setStartSecondsCount] = useState(0);
    const [startMinutesCount, setStartMinutesCount] = useState(0);
    const { start:startSeconds } = useCountUp({
        ref: secondsCountUpRef,
        start:startSecondsCount,
        end:second,
        duration: 2,
        onEnd: ({ pauseResume }) => {
            setStartSecondsCount(second)
        },
    });
    const { start:startMinutes} = useCountUp({
        ref: minutesCountUpRef,
        start:startMinutesCount,
        end:minute,
        duration: 2,
        onEnd: ({ pauseResume }) => {
            setStartMinutesCount(minute)
        },
    });
    return (
        <div className={Styles.SubCount}>
            <div className={Styles.Name}>
                {title}
            </div>
            <div className={`d-flex align-items-end justify-content-end`} style={{fontSize:size}}>
                {
                    minute > 0 &&
                    <div className={`pe-2 d-flex align-items-end`}><div ref={minutesCountUpRef} />분</div>
                }
                <div ref={secondsCountUpRef} style={{fontSize:size}}/>초
            </div>

        </div>
    );
};

export {SubTimeCountChart as default}
