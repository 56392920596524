import React, { Component } from "react";
import { observer } from "mobx-react";
import { FormStore, Input, Validation } from "../../components/validation";
import axios from "axios";
import { adminDataGridStore } from "./AdminManageListView";
import { goBack, navigateReplace } from "../../helper/HistoryHelper";
import { NotificationManager } from "react-notifications";
import TitlePanel from "../../components/layout/TitlePanel";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormLabel,
  InputGroup,
  Row,
} from "react-bootstrap";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import Panel from "../../components/layout/Panel";
import UploadImage from "../../components/avatar/UploadImage";
import RandomColor from "../../components/avatar/RandomColor";
import DefaultAvatar from "../../components/avatar/DefaultAvatar";
import {
  ConfirmDialog,
  confirmDialogStore,
  USER_TYPE,
} from "@qbot-chat/qbot-uikit";
import { makeObservable, observable } from "mobx";
import { Warning2 } from "iconsax-react";
import { ACCESS_RIGHTS } from "../../const/AppConst";

const form = new FormStore();
@observer
class AdminManageDetailView extends Component {
  @observable
  isLoginIdUsed = true;
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    let {
      match: { params },
    } = this.props;
    if (params && params.id) {
      this.fetchDetail(params.id);
    } else {
      form.clear();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {
      match: { params },
    } = this.props;
    let prevParams = prevProps.match.params;
    if (params.id != prevParams.id) {
      if (params && params.id) {
        this.fetchDetail(params.id);
      } else if (!params.id) {
        form.clear();
      }
    }
  }

  fetchDetail(id) {
    axios.post(`/admin/detail/${id}`, {}).then((res) => {
      form.values = res.data;
    });
  }

  checkLoginId = (loginId) => {
    if (loginId) {
      axios.post(`/user/check/loginId/${loginId}`, {}).then((res) => {
        if (res.data) {
          NotificationManager.warning("이미 사용중인 로그인 아이디 입니다.");
        } else {
          NotificationManager.success("사용 가능한 로그인 아이디 입니다.");
          this.isLoginIdUsed = false;
        }
      });
    }
  };

  save() {
    if (!form.doCheckValid()) {
      NotificationManager.warning(form.firstErrorMessage);
      return;
    }

    if (!!this.props.match.params.id) {
      axios.post(`/admin/update`, form.values).then((res) => {
        let id = res.data;
        adminDataGridStore.fetch();
        this.fetchDetail(id);
        NotificationManager.success("변경사항이 저장되었습니다.");
      });
    } else {
      if (this.isLoginIdUsed) {
        NotificationManager.warning("로그인 아이디 중복 체크를 해야합니다.");
        return;
      }
      axios.post(`/admin/insert`, form.values).then((res) => {
        let id = res.data;
        NotificationManager.success("관리지가 등록되었습니다.");
        adminDataGridStore.fetch();
        navigateReplace(`/main/admin/detail/${id}`);
      });
    }
  }

  delete() {
    if (!!this.props.match.params.id) {
      confirmDialogStore.openRemove(<Warning2 />, `삭제 하시겠습니까?`, () => {
        axios
          .post(`/admin/delete`, form.values)
          .then((res) => {
            NotificationManager.success("관리자가 삭제되었습니다.");
            navigateReplace(`/main/admin/list`);
          })
          .catch((e) => {
            console.error(e);
          });
      });
    }
  }

  render() {
    let isNew = !form.valueMap.get("id");
    return (
      <div>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              {isNew ? (
                <TitlePanelLabel label={"관리자 추가"} />
              ) : (
                <TitlePanelLabel label={"관리자 정보 수정"} />
              )}
              <div>
                {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) &&
                  !isNew && (
                    <Button
                      variant={"outline-danger"}
                      onClick={() => this.delete()}
                    >
                      삭제
                    </Button>
                  )}
                <Button
                  variant={"outline-secondary"}
                  className={"ms-4"}
                  onClick={() => goBack()}
                >
                  취소
                </Button>
                {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) && (
                  <Button
                    variant={"outline-primary"}
                    className={`ms-1`}
                    onClick={() => this.save()}
                  >
                    저장
                  </Button>
                )}
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>ID</FormLabel>
                <Input
                  store={form}
                  label={`ID`}
                  name={`id`}
                  placeholder={`자동생성`}
                  disabled={true}
                  validOnBlur
                />
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>이름</FormLabel>
                <Input
                  store={form}
                  label={`이름`}
                  name={`name`}
                  placeholder={`이름을 입력해주세요`}
                  validations={[new Validation(/^.+$/, `이름을 입력하세요.`)]}
                  validOnBlur
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>Login ID</FormLabel>
                <InputGroup>
                  <Input
                    store={form}
                    label={`Login ID`}
                    name={`loginId`}
                    placeholder={`Login ID`}
                    validations={[
                      new Validation(/^.+$/, `아이디를 입력하세요.`),
                    ]}
                    validOnBlur
                    disabled={!isNew}
                    onChange={() => (this.isLoginIdUsed = true)}
                    style={{ borderRadius: "0.375rem 0 0 0.375rem" }}
                    button={
                      isNew &&
                      this.isLoginIdUsed && (
                        <Button
                          variant={`outline-primary`}
                          onClick={() =>
                            this.checkLoginId(form.valueMap.get("loginId"))
                          }
                        >
                          중복체크
                        </Button>
                      )
                    }
                  />
                </InputGroup>
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>비밀번호</FormLabel>
                <Input
                  store={form}
                  label={`비밀번호`}
                  name={`password`}
                  placeholder={`비밀번호를 입력해주세요`}
                  type={`password`}
                  validations={[
                    new Validation(/^.+$/, `비밀번호를 입력하세요.`),
                  ]}
                  validOnBlur
                />
              </Col>
            </FormGroup>

            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>프로필이미지</FormLabel>
                <div className={`d-flex flex-row gap-2 w-100`}>
                  <UploadImage
                    url={form.valueMap.get("avatar.fullUrl")}
                    ref={(ref) => (this.ref = ref)}
                    useUrl={true}
                    useFile={true}
                    selectImage={(image) => {
                      form.setValue("avatar.id", null);
                      form.setValue("avatar.type", image.type);
                      form.setValue("avatar.url", image.url);
                      form.setValue("avatar.fullUrl", image.fullUrl);
                    }}
                    resetImage={() => {
                      form.setValue("avatar.id", null);
                      form.setValue("avatar.type", null);
                      form.setValue("avatar.url", null);
                      form.setValue("avatar.fullUrl", null);
                    }}
                    noImage={
                      <RandomColor seed={form.valueMap.get("id")}>
                        <DefaultAvatar />
                      </RandomColor>
                    }
                  />
                </div>
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>email</FormLabel>
                <Input
                  store={form}
                  label={"이메일"}
                  name={`email`}
                  placeholder={`이메일 주소를 입력하세요.`}
                />
              </Col>
              <Col className={`mb-3`}>
                <FormLabel>전화번호</FormLabel>
                <Input
                  store={form}
                  label={`전화번호`}
                  name={`tel`}
                  placeholder={`전화번호를 입력해주세요`}
                />
              </Col>
            </FormGroup>
            <FormGroup as={Row}>
              <Col className={`mb-3`}>
                <FormLabel>주소</FormLabel>
                <Input
                  store={form}
                  label={`주소`}
                  name={`address`}
                  placeholder={`이름을 입력해주세요`}
                />
              </Col>
            </FormGroup>
          </Form>
        </Panel>
        <ConfirmDialog />
      </div>
    );
  }
}

export default AdminManageDetailView;
