import React, {useState} from 'react';
import {observer} from 'mobx-react';

import {Button, Col, Form, FormGroup, InputGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {DataGrid, DataGridStore, Header, Sort} from "@qbot-chat/qbot-uikit/grid";
import moment from 'moment';
import RoadShowDialog, {roadShowDialogStore} from './RoadShowDialog';
import Select from 'react-select';
import appStore from '../../store/AppStore';
import Panel from "../../components/layout/Panel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import TitlePanel from "../../components/layout/TitlePanel";
import Frame from "../../components/layout/Frame";
import FrameWrap from "../../components/layout/FrameWrap";
import {ACCESS_RIGHTS} from "../../const/AppConst";
import {USER_TYPE} from "@qbot-chat/qbot-uikit";

const dataGridStore = new DataGridStore({
    fetchUrl: '/traders/roadshows',
    selectFnFromResponse: (res) => ({items : res.data.items, rowsCount : res.data.count}),
    sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
    noHeader: true
});

const LongTextCell = ({text}) => {
    const [focus, setFocus] = useState(false);

    const onFocus = () => {
        setFocus(true);
    };
    const onBlur = e => {
        setFocus(false);
    };
    return (
            text &&
            <div onMouseOver={onFocus} onMouseOut={onBlur}>
                <OverlayTrigger
                    placement="right"
                    show={focus}
                    delay={{show: 250, hide: 350}}
                    overlay={(props)=>
                        <Popover style={{
                            maxWidth: '100%',
                            whiteSpace: "pre"
                        }} {...props}>
                            <Popover.Body>
                                {text}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <span>{text?.length < 10 ? text : `${text.slice(0, 10)}...`}</span>
                </OverlayTrigger>
            </div>

    )
}


@observer
class RoadShowView extends React.Component {

    componentDidMount() {
        dataGridStore.refresh();
    }

    componentWillUnmount() {
        dataGridStore.searchCondition.clear();
    }

    render() {
        let searchCondition = dataGridStore.searchCondition;
        return (
            <Frame>
                <FrameWrap>
                    <TitlePanel>
                        <FormGroup>
                            <Col className={`justify-content-between align-items-center d-flex`}>
                                <TitlePanelLabel label={'로드쇼'}/>

                                {
                                    ACCESS_RIGHTS[USER_TYPE.ADMIN].includes(appStore.type) &&
                                    <Button variant={`outline-primary`}
                                            onClick={() => {
                                                roadShowDialogStore.open(undefined, () => dataGridStore.refresh(), dataGridStore.searchCondition.get('branchId'));
                                            }}
                                    >
                                        + 새 로드쇼 생성
                                    </Button>
                                }
                            </Col>
                        </FormGroup>
                    </TitlePanel>
                    <Panel>
                        <Panel style={{border:'1px solid #E8E8E8', borderRadius:'.5rem'}}>
                            <FormGroup>
                                <Col className={`d-flex flex-column`}>
                                    <div className={'d-flex justify-content-between'}>
                                    <Col md={3}>
                                        <InputGroup className={'w-100 flex-nowrap'}>
                                            <InputGroup.Text>점포</InputGroup.Text>
                                            <Select
                                                className={'flex-grow-1'}
                                                menuPortalTarget={document.body}
                                                menuShouldBlockScroll={true}
                                                isSearchable={true}
                                                isClearable={true}
                                                name="color"
                                                options={appStore.trBranchesOptions}
                                                onChange={option => {
                                                    if (option) {
                                                        dataGridStore.searchCondition.set('branchId', option.value);
                                                    } else {
                                                        dataGridStore.searchCondition.set('branchId', null);
                                                    }
                                                    dataGridStore.refresh();
                                                }}
                                                placeholder={'전체'}
                                                styles={{
                                                    control: base => ({
                                                        ...base,
                                                        borderRadius:'0 4px 4px 0'
                                                    })
                                                }}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={{span:8, offset:1}}>
                                        <InputGroup>
                                            <Form.Control placeholder={'입력해주세요'} value={searchCondition.get('keyword') || ''}
                                                          onChange={e => {
                                                              searchCondition.set('keyword', e.target.value);
                                                          }}
                                                          onKeyDown={e => {
                                                              if (e.key === 'Enter')
                                                                  dataGridStore.refresh();
                                                          }}
                                            />
                                            <Button variant={`outline-secondary`}
                                                    onClick={() => {

                                                        dataGridStore.refresh();
                                                    }}
                                            >
                                                검색
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </div>
                                </Col>
                            </FormGroup>
                        </Panel>
                    </Panel>
                    <Panel className={'flex-default'}>
                        <DataGrid store={dataGridStore}
                                  columns={[
                                      <Header key="id" id={`id`} name={`ID`}
                                              width={{min: 50, current: 65, max: 85}}
                                      />,

                                      <Header key="store_id" id="store_id" name="지점명"
                                              width={{min: 80, current: 140, max: 180}}
                                              printFunction={branchId =>
                                              <div>
                                                  {appStore.branchesMap.get(branchId)?.name || ''}
                                              </div>
                                      }
                                      />,
                                      /*<Header key="store_name" id="store_name" name="점포명" minWidth={55} width={55} style={{textAlign: 'center'}}/>,*/
                                      <Header key="title" id="title" name="제목"
                                              width={{min:150, current:200, max:500}}
                                              printFunction={(title) =>
                                                  <div>{title}</div>
                                      }/>,

                                      <Header key="gcode_name" id="gcode_name" name="테마"
                                              width={{min:80, current:140, max:200}}
                                              printFunction={(gcode_name) =>
                                                  <div>{gcode_name}</div>
                                      }/>,

                                      <Header key="location" id="location" name="위치"
                                              width={{min:80, current:140, max:300}}
                                              printFunction={(location) =>
                                                  <div>{location}</div>
                                              }/>,

                                      <Header key="from_date" id="from_date" name="기간"
                                              width={{min:100, current:170, max:180}}
                                              printFunction={(from_date, row) =>
                                                  <div>
                                                  {moment(from_date).format('YY-MM-DD')} ~ {moment(row.due_date).format('YY-MM-DD')}
                                                  </div>
                                              }
                                      />,
                                      <Header key="author_id" id="author_id" name="작성자"
                                              width={{min: 100, current: 110, max: 120}}
                                              printFunction={authorId =>
                                                  authorId?.replace(/^.{2}/, '**')
                                              }
                                      />,
                                      <Header key="status" id="status" name="상태"
                                              width={{min: 50, current: 65, max: 85}}
                                      />,

                                      <Header id="reg_dts" name={`작성일`}
                                              className={`justify-content-end`}
                                              width={{min: 100, current: 220, max: 250}} type="date"/>,
                                  ]}
                                  isCheckable={false}
                                  isModifiable={false}
                                  keyColumn="id"
                                  highlightClass="table-primary"
                                  onRowClick={rowData => {
                                      roadShowDialogStore.open(rowData.id, () => dataGridStore.fetch());
                                  }}
                        />
                    </Panel>
                    <RoadShowDialog/>
                </FrameWrap>
            </Frame>
        );
    }
}

export default RoadShowView;
