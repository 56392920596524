import React, {Component} from 'react';
import {FormStore, Input, Validation} from "../../components/validation";
import {observer} from "mobx-react";
import Panel from "../../components/layout/Panel";
import {Button, Col, FormGroup, InputGroup, Row, Form} from "react-bootstrap";
import Styles from "./TicketProperty.module.scss";
import {goBack, navigateReplace} from "../../helper/HistoryHelper";
import {TICKET_PROPERTY_TYPE} from "@qbot-chat/qbot-uikit/chat";
import SelectBox from "../../components/input/SelectBox";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {ConfirmDialog, confirmDialogStore} from "@qbot-chat/qbot-uikit/modal";
import {ticketPropertyDataGridStore} from "./TicketPropertyListView";
import {Add} from "iconsax-react";
import {computed, makeObservable, observable, keys, toJS} from "mobx";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import FrameWrap from "../../components/layout/FrameWrap";
import TitlePanel from "../../components/layout/TitlePanel";
import notificationStore from "../../store/NotificationStore";

const form = new FormStore();

const typeOptions = [
    {label: `문자`, value: TICKET_PROPERTY_TYPE.STRING},
    {label: `숫자`, value: TICKET_PROPERTY_TYPE.NUMBER},
    {label: `예/아니오`, value: TICKET_PROPERTY_TYPE.BOOLEAN},
    {label: `날짜`, value: TICKET_PROPERTY_TYPE.DATE},
    {label: `열거`, value: TICKET_PROPERTY_TYPE.ENUM}
]
@observer
class TicketPropertyDetailView extends Component {

    @observable
    label

    @observable
    value

    @observable
    enumsMap = new Map();

    @observable
    type
    constructor(props) {
        super(props);
        makeObservable(this);
    }

    @computed
    get enumsValues() {
        return toJS(keys(this.enumsMap))
    }

    reset(){
        form.clear();
        form.setValue('isRequired', false);
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if (params && params.fieldKey) {
            this.fetchDetail(params.fieldKey);
        } else {
            this.reset();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {match: {params}} = this.props;
        let prevParams = prevProps.match.params;
        if(params.fieldKey != prevParams.fieldKey){
            if (params && params.fieldKey ) {
                this.fetchDetail(params.fieldKey);
            }else if(!params.fieldKey){
                this.reset();
            }
        }
    }

    fetchDetail(fieldKey){
        axios.post(
            `/ticket/property/setting/detail/${fieldKey}`,
            {}
        ).then(res=>{
            form.values = res.data;
            if(res.data.enums)
                this.enumsMap.replace(res.data.enums);
        });
    }

    save() {
        if(!form.doCheckValid()){
            NotificationManager.warning(form.firstErrorMessage);
            return;
        }
        if(!form.valueMap.get("type")){
            NotificationManager.warning('데이터 유형을 선택해야 합니다.');
            return;
        }
        if(form.valueMap.get("type") === TICKET_PROPERTY_TYPE.ENUM) {
            form.setValue('enums', Object.fromEntries(this.enumsMap));
        }

        if(!!this.props.match.params.fieldKey){
            confirmDialogStore.openSave(
                '저장',
                '저장 하시겠습니까?',
                () => {
                    axios.post(
                        `/ticket/property/setting/update`,
                        form.values
                    ).then(res=>{
                        let fieldKey = res.data;
                        ticketPropertyDataGridStore.fetch()
                        NotificationManager.success('티켓 필드가 저장되었습니다.')
                        navigateReplace(`/main/setting/manage/property/detail/${fieldKey}`)
                    }).catch(e => console.error(e))
                }
            )

        }else{
            confirmDialogStore.openAdd(
                '저장',
                '저장 하시겠습니까?',
                () => {
                    axios.post(
                        `/ticket/property/setting/insert`,
                        form.values
                    ).then(res=>{
                        let fieldKey = res.data;
                        NotificationManager.success('티켓 필드가 등록되었습니다.')
                        ticketPropertyDataGridStore.fetch()
                        navigateReplace(`/main/setting/manage/property/detail/${fieldKey}`)
                    }).catch(e => console.error(e))
                }
            )
        }
    }

    render() {
        let isNew = !form.valueMap.get('createdAt');
        return (
            <FrameWrap>
                <TitlePanel>
                    <FormGroup>
                        <Col className={'justify-content-between align-items-center d-flex'}>
                            {
                                isNew?
                                    <TitlePanelLabel label={'티켓필드 등록'} /> :
                                    <TitlePanelLabel label={'티켓필드 수정'} />
                            }
                            <div>
                                <Button variant={"outline-secondary"}
                                        size={'md'}
                                        onClick={()=>{
                                            goBack();
                                        }}
                                >
                                    취소
                                </Button>
                                <Button variant={"outline-primary"}
                                        size={'md'}
                                        className={'ms-2'}
                                        onClick={()=>{
                                            this.save();
                                        }}
                                >
                                    저장
                                </Button>
                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel>
                    <Panel className={Styles.BorderLine}>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    사용
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                <Form.Check
                                    type="switch"
                                    checked={form.valueMap.get('isActive')}
                                    onChange={e => form.setValue('isActive', e.target.checked)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    라벨
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                <Input store={form}
                                       label={`라벨`}
                                       name={`name`}
                                       placeholder={`라벨명을 입력해 주세요.`}
                                       validations={[
                                           new Validation(/^.+$/, `라벨명을 입력해 주세요.`)
                                       ]}
                                       validOnBlur
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    필드키
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                {
                                    isNew ?
                                        <Input store={form}
                                               label={`필드키`}
                                               name={`fieldKey`}
                                               placeholder={`알파벳 소문자, 숫자, 대시 기호만 사용가능하며, 알파벳이나 숫자로만 시작할 수 있습니다.`}
                                               validations={[
                                                   new Validation(/^.+$/, `필드키를 입력해 주세요.`)
                                               ]}
                                               validOnBlur
                                        />
                                        :
                                        <div>
                                            {form.valueMap.get("fieldKey")}
                                        </div>
                                }

                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    데이터 유형
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                {
                                    isNew ?
                                        <SelectBox
                                                   store={form}
                                                   value={typeOptions.find(option => option.value == form.valueMap.get('type'))}
                                                   placeholder={'타입 선택'}
                                                   options={typeOptions}
                                                   onChange={(changeType) => {
                                                       this.type = changeType.value;
                                                       form.setValue('type', changeType.value);
                                                   }}
                                        />
                                        :
                                        <div>
                                            {(typeOptions.find(option => option.value === form.valueMap.get('type')))?.label}
                                        </div>
                                }
                            </Col>
                        </FormGroup>
                        {
                            isNew && this.type === TICKET_PROPERTY_TYPE.ENUM &&
                            <FormGroup as={Row} className={'mb-3'}>
                                <Col md={2}>
                                    <div className={Styles.Label}>
                                        열거 값
                                    </div>
                                </Col>
                                <Col md={10} className={'d-flex flex-row'}>
                                    <InputGroup>
                                        <Form.Control placeholder={'라벨'} value={this.label} onChange={e => this.label = e.target.value}/>
                                        <Form.Control placeholder={'값'} value={this.value} onChange={e => this.value = e.target.value}/>
                                        <Button variant="outline-primary"
                                            onClick={e => {
                                                if(!this.label || !this.value || this.label.trim().length === 0 || this.value.trim().length === 0) {
                                                    NotificationManager.warning('라벨 또는 값이 비어있습니다.')
                                                    return;
                                                }
                                                this.enumsMap.replace(this.enumsMap.set(this.label, this.value))
                                                this.label = '';
                                                this.value = '';
                                            }}
                                        >
                                            <Add/>
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                        }
                        {
                            this.enumsValues.length > 0 &&
                            <FormGroup as={Row} className={'mb-3'}>
                                <Col md={2}>

                                </Col>
                                <Col md={10} className={'d-flex flex-wrap'} >
                                    {
                                        this.enumsValues.map((key, k) => {
                                            return  <div className={`${Styles.Row} me-3`}>
                                                <InputGroup key={k} size={'sm'}>
                                                    <Form.Control style={{backgroundColor: '#007DFC', color: '#FFFFFF'}} value={`${key} : ${this.enumsMap.get(key)}`}/>
                                                    {
                                                        isNew &&
                                                        <Button variant="outline-primary" size="sm"
                                                                style={{paddingLeft: '.3rem', paddingRight: '.3rem'}}
                                                                onClick={() => this.enumsMap.delete(key)}
                                                        >
                                                            <Add style={{rotate: '45deg'}}/>
                                                        </Button>
                                                    }
                                            </InputGroup>
                                            </div>

                                        })
                                    }
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    필수 항목 여부
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                <div className={'d-flex'}>
                                    <Form.Check
                                        className={'me-2'}
                                        label={"선택사항"}
                                        type={"radio"}
                                        name={"isRequired"}
                                        id={"false"}
                                        checked={!form.valueMap.get('isRequired')}
                                        onClick={() => form.setValue('isRequired', false)}
                                    />
                                    <Form.Check
                                        label={"필수사항"}
                                        type={"radio"}
                                        name={"isRequired"}
                                        id={"true"}
                                        checked={form.valueMap.get('isRequired')}
                                        onClick={() => form.setValue('isRequired', true)}
                                    />
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup as={Row} className={'mb-3'}>
                            <Col md={2}>
                                <div className={Styles.Label}>
                                    설명(선택항목)
                                </div>
                            </Col>
                            <Col md={10} className={'d-flex flex-row'}>
                                <Input store={form}
                                       label={`설명`}
                                       name={`content`}
                                       placeholder={`해당 프로퍼티 설명`}
                                />
                            </Col>
                        </FormGroup>
                    </Panel>
                </Panel>
                <ConfirmDialog/>
            </FrameWrap>
        );
    }
}

export default TicketPropertyDetailView;