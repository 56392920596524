import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="MonitoringIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <g>
            {/*여기아래 붙여넣기*/}
            <path
                d="M17.7,18.1H6.6c-4,0-5.2-1.2-5.2-5.2V6.5c0-4,1.2-5.2,5.2-5.2h11.1c4,0,5.2,1.2,5.2,5.2v6.3 C22.9,16.8,21.7,18.1,17.7,18.1z M6.6,2.8c-3.1,0-3.7,0.5-3.7,3.7v6.3c0,3.1,0.5,3.7,3.7,3.7h11.1c3.1,0,3.7-0.5,3.7-3.7V6.5 c0-3.1-0.5-3.7-3.7-3.7H6.6z"
            />
            <path
                d="M12.2,22.5c-0.4,0-0.8-0.3-0.8-0.8v-4.1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.1 C12.9,22.1,12.6,22.5,12.2,22.5z M21.8,13.8H2.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h19.3c0.4,0,0.8,0.3,0.8,0.8 S22.2,13.8,21.8,13.8z"
            />
            <path
                d="M16.7,22.8h-9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h9c0.4,0,0.8,0.3,0.8,0.8S17.1,22.8,16.7,22.8z"
            />
            <g>
                <path
                    d="M10.5,11.5h-3c-0.3,0-0.6-0.2-0.6-0.4V8c0-0.6,0.7-1.1,1.5-1.1h2.1c0.3,0,0.6,0.2,0.6,0.4V11 C11.1,11.3,10.8,11.5,10.5,11.5z M8.1,10.3l1.8,0V8H8.4C8.2,8,8.1,8.1,8.1,8.3l0,0V10.3z"
                />
                <path
                    d="M13.5,11.5h-3c-0.3,0-0.6-0.2-0.6-0.4V5.3c0-0.6,0.7-1.1,1.5-1.1h1.2c0.8,0,1.5,0.5,1.5,1v5.7 C14.1,11.3,13.8,11.5,13.5,11.5z M11.1,10.3h1.8V5.7c0-0.1-0.1-0.2-0.3-0.2l0,0h-1.2c-0.2,0-0.3,0.1-0.3,0.2c0,0,0,0,0,0v4.9 L11.1,10.3z"
                />
                <path
                    d="M16.5,11.5h-3c-0.3,0-0.6-0.2-0.6-0.4V8.4c0-0.2,0.3-0.4,0.6-0.4h2.1c0.8,0,1.5,0.5,1.5,1.1v2 C17.1,11.3,16.9,11.5,16.5,11.5z M14.1,10.2h1.8v-1c0-0.1-0.1-0.2-0.3-0.2h-1.5C14.1,9,14.1,10.2,14.1,10.2z"
                />
            </g>
        </g>
    </svg>
};
export default SVGComponent;