import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@qbot-chat/qbot-uikit/dist/index.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {configure} from 'mobx';
import appStore from './store/AppStore';
import {HISTORY} from './helper/HistoryHelper';
import {MENU_GROUP_RE} from './const/AppConst';

configure({enforceActions: 'never'});

// 주소표시줄에 직접입력시 처리
appStore.currentPath = HISTORY.location.pathname;

let matched = appStore.currentPath.match(MENU_GROUP_RE);
if (matched && matched.length > 1) {
    appStore.currentMenuGroup = matched[1];
}

// 쿠키를 확인하여 현재 세션상태를 결정한다.
appStore.fetchSessionInfo().then(r => {
    console.log('세션 확인됨.');
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
