import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {action, computed, makeObservable, observable, values} from 'mobx';
import axios from 'axios';
import {AsyncPaginate} from 'react-select-async-paginate';
import DefaultTeamImage from '../avatar/DefaultTeamImage';
import Select, {components} from "react-select";
import RandomColor from '../avatar/RandomColor';
import {Image} from 'react-bootstrap';
import Avatar from "../avatar/Avatar";

const TeamImage = (props) => {
    return <div className={'d-flex flex-row m-2'}>
        <Avatar src={props.team?.avatar?.fullUrl} seed={props.team.id} type={'team'} width={'2rem'}/>
        <div className={'ms-2 align-self-center'}> {props.team.name} </div>
    </div>;
}

const TeamOption = (props) => {
    return (
        <components.Option {...props}  >
            <TeamImage team={props.data.object}/>
        </components.Option>
    );
}

@observer
class TeamSelect extends Component {

    @observable
    hasMore = true;

    searchKeyword = null;

    constructor(props, context) {
        super(props, context);
        makeObservable(this);
    }


    @action.bound
    async teamSearch(search, loadedOptions) {
        const changedKeyword = search !== this.searchKeyword;

        const {data} = await axios.post(
            `/team/infinite/list`,
            {
                keyword: search,
                lastRow: loadedOptions.length
            }
        )
        const newOptions = [
            ...(changedKeyword ? [] : loadedOptions),
            ...data?.items?.map(team => (
                {label: team.name, value: team.id, object: team }
            )) || []
        ];

        this.searchKeyword = search;

        return ({
            options: newOptions,
            hasMore: newOptions.length < data.count
        });
    }


    render() {
        const {value, onSelect} = this.props;
        return (
            <AsyncPaginate
                className={'ms-2'}
                isClearable={true}
                components={{Option : TeamOption}}
                styles={{
                    menu: (base) => ({
                        ...base,
                        width: '20rem',
                        zIndex: 12
                    }),
                }}
                value={value}
                placeholder="모든 팀"
                loadOptions={this.teamSearch}
                debounceTimeout={300}
                onChange={onSelect}
            />
        );
    }
}

TeamSelect.propTypes = {
    value: PropTypes.shape({value: PropTypes.any, label: PropTypes.string, object: PropTypes.object}),
    onSelect: PropTypes.func,
};

export default TeamSelect;
