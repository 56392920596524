import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {
    Alert,
    Button,
    Col,
    Row,
    Collapse,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Nav,
    OverlayTrigger,
    Tooltip, Popover
} from "react-bootstrap";
import {FormStore} from "../../../components/validation";
import axios from "axios";
import {CONTENT_TYPE, TICKET_EVENT_TYPE} from "@qbot-chat/qbot-uikit/chat";
import {NotificationManager} from "react-notifications";
import {TimePicker} from 'antd';
import dayjs from 'dayjs';
import {InfoCircle} from "iconsax-react";
import {MentionInput} from "@qbot-chat/qbot-uikit/component";
import {TEMPLATE_CONSTANTS} from "../../../const/AppConst";
import Panel from "../../../components/layout/Panel";
import settingStore from "../../../store/SettingStore";

const form = new FormStore();

@observer
class AutoEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked || false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.checked !== prevProps.checked){
            this.setState({checked:this.props.checked})
        }
    }

    render() {
        let {id, title, description, ticketEvent, subEditor, checked} = this.props;

        let isSame = this.state.checked === checked;
        return (
            <>
                <Panel className={`${Styles.PanelBorder} mb-3`}>
                    <FormGroup as={Row} >
                        <Col md={8}>
                            <div className={`${Styles.Title} ${Styles.Label}`}>
                                <div className={`d-flex`}>
                                    {title}

                                </div>
                            </div>
                            <div className={Styles.Description}>
                                {description}
                            </div>
                        </Col>
                        <Col md={4} className={`d-flex align-items-center`}>
                            <Form.Check type="switch"
                                        checked={this.state.checked}
                                        onChange={e=>{
                                            this.setState({checked:e.target.checked})
                                        }}
                            />
                        </Col>
                    </FormGroup>
                    <Collapse in={!isSame}>
                        <div className={`${Styles.ButtonContainer}`}>
                            <Button variant={"outline-secondary"} className={Styles.Button} onClick={()=>{this.setState({checked})}}>취소</Button>
                            <Button variant={"outline-primary"}  className={Styles.Button}
                                    onClick={()=>{
                                        let data = {...settingStore.setting}
                                        data[id] = this.state.checked;
                                        axios.post(`/setting/update`,
                                            data,
                                        ).then(res=>{
                                            settingStore.fetchSettingInfo()
                                            NotificationManager.success(`업데이트 완료`);
                                        })
                                    }}
                            >
                                저장
                            </Button>
                        </div>
                    </Collapse>
                </Panel>
                <Collapse in={this.state?.checked && subEditor}>
                    <div>
                        {subEditor}
                    </div>
                </Collapse>
            </>
        );
    }
}

export default AutoEditor;