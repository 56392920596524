import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  DataGrid,
  DataGridStore,
  Header,
  Sort,
} from "@qbot-chat/qbot-uikit/grid";
import { navigate } from "../../helper/HistoryHelper";
import { Button, Col, Form, FormGroup, InputGroup } from "react-bootstrap";
import { Select, Button as Refresh } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import Panel from "../../components/layout/Panel";
import TitlePanel from "../../components/layout/TitlePanel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import FrameWrap from "../../components/layout/FrameWrap";
import Avatar from "../../components/avatar/Avatar";
import { makeObservable, observable } from "mobx";
import { ACCESS_RIGHTS } from "../../const/AppConst";
import { USER_TYPE } from "@qbot-chat/qbot-uikit";
import appStore from "../../store/AppStore";

const companyDataGridStore = new DataGridStore({
  fetchUrl: `/company/list`,
  selectFnFromResponse: (res) => ({
    items: res.data.items,
    rowsCount: res.data.count,
  }),
  sort: new Sort(`id`, Sort.SORT_DIRECTION.DESC),
});

@observer
class CompanyListView extends Component {
  @observable
  searchStatus = null;

  @observable
  searchKeyword = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    companyDataGridStore.refresh();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.pathname &&
      prevProps.pathname.startsWith("/main/company/detail") &&
      this.props.pathname === "/main/company/list"
    ) {
      companyDataGridStore.fetch();
    }
  }

  componentWillUnmount() {
    companyDataGridStore.searchCondition.clear();
  }

  activeHandler = (value) => {
    this.searchStatus = value;
    this.searchKeyword = value;
    this.search();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      companyDataGridStore.page = 1;
      this.search();
    }
  };

  search = () => {
    let obj = Object.fromEntries(companyDataGridStore.searchCondition);
    companyDataGridStore.searchCondition.set("status", this.searchStatus);
    companyDataGridStore.searchCondition.set("keyword", this.searchKeyword);
    if (
      !_.isEqual(obj, Object.fromEntries(companyDataGridStore.searchCondition))
    ) {
      companyDataGridStore.refresh();
    } else {
      companyDataGridStore.fetch();
    }
  };

  render() {
    return (
      <FrameWrap>
        <TitlePanel>
          <FormGroup>
            <Col
              className={`justify-content-between align-items-center d-flex`}
            >
              <TitlePanelLabel label={"회사 관리"} />
              <div>
                <Button
                  variant={"outline-primary"}
                  onClick={() => {
                    navigate(`/main/company/new`);
                  }}
                >
                  + 등록하기
                </Button>
              </div>
            </Col>
          </FormGroup>
        </TitlePanel>
        <Panel>
          <Panel
            className={`rounded-3`}
            style={{ border: "1px solid #E8E8E8" }}
          >
            <FormGroup>
              <Col className={`d-flex flex-column`}>
                <div className={"d-flex justify-content-between"}>
                  <Col md={4}>
                    {ACCESS_RIGHTS[USER_TYPE.SUPER].includes(appStore.type) ? (
                      <div className="d-flex align-items-center gap-3">
                        <Select
                          defaultValue={"실행 상태"}
                          style={{ width: 180, height: 38, fontSize: "1rem" }}
                          size="large"
                          onChange={this.activeHandler}
                          options={[
                            { label: "활성화", value: true },
                            { label: "비활성화", value: false },
                          ]}
                        />
                        <Refresh
                          shape="circle"
                          icon={<ReloadOutlined />}
                          onClick={() => this.activeHandler(null)}
                        />
                      </div>
                    ) : (
                      <div className={"d-flex flex-row"}>
                        <div className={"ms-2 align-self-center"}>
                          {" "}
                          {appStore.team.name}{" "}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={{ span: 4, offset: 1 }}>
                    <InputGroup>
                      <Form.Control
                        placeholder="내용 입력"
                        value={this.searchKeyword}
                        onChange={(e) => {
                          this.searchKeyword = e.target.value;
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                      />
                      <Button
                        variant={"outline-secondary"}
                        onClick={() => this.search()}
                      >
                        검색
                      </Button>
                    </InputGroup>
                  </Col>
                </div>
              </Col>
            </FormGroup>
          </Panel>
        </Panel>
        <Panel className={"flex-default"}>
          <DataGrid
            store={companyDataGridStore}
            keyColumn={`id`}
            columns={[
              <Header
                id={`index`}
                name={`번호`}
                width={{ min: 40, current: 50, max: 60 }}
              />,
              // <Header
              //   id="parentCompany"
              //   name={`상위기업`}
              //   width={{ min: 100, current: 125, max: 275 }}
              //   printFunction={(data) => {
              //     return data ? <span>{data}</span> : <span>-</span>;
              //   }}
              // />,
              <Header
                id={`companyName`}
                name={`업체명`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(companyName) => {
                  return <span>{companyName}</span>;
                }}
              />,
              <Header
                id={`serviceTypeName`}
                name={`사용 서비스`}
                width={{ min: 100, current: 125, max: 275 }}
                printFunction={(serviceTypeName) => (
                  <span>{serviceTypeName}</span>
                )}
              />,
              <Header
                id={`isActive`}
                name={`사용`}
                width={{ min: 50, current: 80, max: 100 }}
                printFunction={(isActive) => {
                  return isActive ? <span>활성화</span> : <span>비활성화</span>;
                }}
              />,
              <Header
                id={`createdAt`}
                name={`최초 등록 일시`}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,
              <Header
                id={`creator`}
                name={`최초 등록자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(creator) => {
                  return <span>{creator}</span>;
                }}
              />,
              <Header
                id="updatedAt"
                name={`최종 수정 일시`}
                sortable={true}
                width={{ min: 100, current: 220, max: 250 }}
                type="date"
              />,

              <Header
                id={`updater`}
                name={`최종 수정자`}
                width={{ min: 36, current: 160, max: 200 }}
                printFunction={(updater) => {
                  return updater ? <span>{updater}</span> : <span>-</span>;
                }}
              />,
            ]}
            onRowClick={(rowData) => {
              navigate(`/main/company/detail/${rowData.id}`);
            }}
          />
        </Panel>
      </FrameWrap>
    );
  }
}

export {
  CompanyListView as default,
  companyDataGridStore as companyDataGridStore,
};
