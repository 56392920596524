import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="ChatBotIcon"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <path
            d="M12,22.8C6.1,22.8,1.3,17.9,1.3,12S6.1,1.2,12,1.2S22.8,6.1,22.8,12S17.9,22.8,12,22.8z M12,2.8 c-5.1,0-9.2,4.2-9.2,9.2s4.2,9.2,9.2,9.2s9.3-4.1,9.3-9.2S17.1,2.8,12,2.8z"
        />
        <path
            d="M14.3,16.7l-1.6-1.1l1.6-1.1c0.3-0.2,0.4-0.7,0.2-1c-0.2-0.3-0.7-0.4-1-0.2l-2.2,1.4l-2.2-1.4 c-0.3-0.2-0.8-0.1-1,0.2c-0.2,0.3-0.1,0.8,0.2,1l1.6,1.1l-1.6,1.1c-0.3,0.2-0.4,0.7-0.2,1C8.2,17.9,8.5,18,8.7,18 c0.1,0,0.3,0,0.4-0.1l2.2-1.4l2.2,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.6-0.3C14.7,17.4,14.6,16.9,14.3,16.7z"
        />
        <path
            d="M7.5,10.8L7.5,10.8c-0.7,0-1.3-0.6-1.3-1.3V8.5c0-0.7,0.6-1.3,1.3-1.3h0c0.7,0,1.3,0.6,1.3,1.3v1.1 C8.8,10.3,8.3,10.8,7.5,10.8z"
        />
        <path
            d="M14.6,10.8L14.6,10.8c-0.7,0-1.3-0.6-1.3-1.3V8.5c0-0.7,0.6-1.3,1.3-1.3h0c0.7,0,1.3,0.6,1.3,1.3v1.1 C15.9,10.3,15.4,10.8,14.6,10.8z"
        />
    </svg>
};
export default SVGComponent;