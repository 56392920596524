import React from 'react';
import {Doughnut} from "react-chartjs-2";
import {STATS_COLOR} from "../../const/AppConst";
import {observer} from "mobx-react";



@observer
class DoughnutChart extends React.Component{

    constructor(props) {
        super(props);
    }

    legendContainer = (chart, id) => {
        const legendContainer = document.getElementById(id);
        if(!legendContainer)
            return null;
        let listContainer = legendContainer.querySelector('div.legend');

        if (!listContainer) {
            listContainer = document.createElement('div');
            listContainer.className = 'legend';
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'column';
            listContainer.style.width = '100%';
            legendContainer.appendChild(listContainer);
        }
        return listContainer;
    };

    render() {
        let store = this;
        let {layout, id, total, data, title, legendContainer} = this.props;
        let {w, h} = layout;
        let statsData = {
            labels: data.map(data=>data.label),
            datasets : [{
                label: ``,
                data: data.map(data=>data.value),
                backgroundColor: STATS_COLOR,
                borderColor: STATS_COLOR,
                borderWidth: 1,
            }]
        }
        let htmlLegendPlugin = {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
                const legendContainer = store.legendContainer(chart, id);
                if(!legendContainer)
                    return true;
                while (legendContainer.firstChild) {
                    legendContainer.firstChild.remove();
                }
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let values = chart.data.datasets[0].data;
                items.forEach(item => {
                    const itemDiv = document.createElement('div');
                    itemDiv.style.display = 'flex';
                    itemDiv.style.flexDirection = 'row';
                    itemDiv.style.justifyContent = 'space-between';
                    itemDiv.style.alignItems = 'center';
                    itemDiv.style.marginBottom = '2px';
                    itemDiv.onclick = () => {
                        const {type} = chart.config;
                        if (type === 'pie' || type === 'doughnut') {
                            // Pie and doughnut charts only have a single dataset and visibility is per item
                            chart.toggleDataVisibility(item.index);
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        }
                        chart.update();
                    };
                    const legendDiv = document.createElement('div');
                    legendDiv.style.display = 'flex';
                    legendDiv.style.flexDirection = 'row';
                    legendDiv.style.alignItems = 'center';
                    const dot = document.createElement('div');
                    dot.style.background = item.fillStyle;
                    dot.style.borderColor = item.strokeStyle;
                    dot.style.borderWidth = item.lineWidth + 'px';
                    dot.style.display = 'inline-block';
                    dot.style.height = '16px';
                    dot.style.marginRight = '10px';
                    dot.style.width = '16px';
                    dot.style.borderRadius = '8px';
                    // Text
                    const textContainer = document.createElement('p');
                    textContainer.style.color = item.fontColor;
                    textContainer.style.margin = 0;
                    textContainer.style.padding = 0;
                    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                    const text = document.createTextNode(item.text);
                    textContainer.appendChild(text);

                    const count = document.createTextNode(values[item.index].toLocaleString());
                    const countDiv = document.createElement('div');
                    countDiv.style.width = '60px';
                    countDiv.style.textAlign = 'right';
                    countDiv.appendChild(count)
                    legendDiv.appendChild(dot);
                    legendDiv.appendChild(textContainer);
                    itemDiv.appendChild(legendDiv);
                    itemDiv.appendChild(countDiv);
                    legendContainer.appendChild(itemDiv);
                });
            }
        };
        return(
            <div className={`d-flex flex-column h-100 bg-body`}>
                <div className={`p-3 fw-bold`}>{title}</div>
                <div style={{flex:1, minHeight:0}} className={`d-flex`}>
                    <div style={{flex:1, minWidth:0}}>
                        <div className={`d-flex flex-column position-absolute align-items-center justify-content-center w-100 h-100 fw-bold`} style={{top:0, pointerEvents:'none'}}>
                            <p>총 발행 티켓수</p>
                            <p>{total}</p>
                        </div>
                        <Doughnut className={'m-4'}
                                  data={statsData}
                                  options={{
                                      cutout: '70%',
                                      maintainAspectRatio: false,
                                      plugins: {
                                          legend: {
                                              position: 'right',
                                              display: false
                                          }
                                      }
                                  }}
                                  plugins={[
                                      htmlLegendPlugin
                                  ]}
                        />

                    </div>
                    <div className={`align-items-end justify-content-end ms-3 me-4 ${w > 4 ? `d-flex` : 'd-none'}`}>
                        {legendContainer}
                    </div>
                </div>
            </div>
        )
    }
}

export {DoughnutChart as default}