import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {Badge, Nav} from "react-bootstrap";
import TicketEventEditor from "./TicketEventEditor";
import {TICKET_EVENT_TYPE} from "@qbot-chat/qbot-uikit/chat";
import settingStore from "../../../store/SettingStore";
import Panel from "../../../components/layout/Panel";
import {ArrowRight, ArrowRight2} from "iconsax-react";

@observer
class TimePanel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let {ticketEventMap, fetchTicketEvent} = settingStore;
        let idleWait = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_IDLE_WAIT);
        let closeWait = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_CLOSE_WAIT);
        let assignmentWait = ticketEventMap.get(TICKET_EVENT_TYPE.TICKET_ASSIGNMENT_WAIT);
        return (
            <Panel className={`h-100 overflow-y-scroll`}>
                {
                    idleWait &&
                    <Panel className={`${Styles.PanelBorder} flex-grow-1`}>
                        <TicketEventEditor ticketEvent={idleWait} fetchTicketEvent={fetchTicketEvent}
                                           title={`유휴 상태 시간 설정`}
                        />
                    </Panel>
                }
                {
                    closeWait &&
                    <Panel className={`${Styles.PanelBorder} flex-grow-1`}>
                        <TicketEventEditor ticketEvent={closeWait} fetchTicketEvent={fetchTicketEvent}
                                           title={`종료 상태 시간 설정`}
                        />
                    </Panel>
                }
                {
                    assignmentWait &&
                    <Panel className={`${Styles.PanelBorder} flex-grow-1`}>
                        <TicketEventEditor ticketEvent={assignmentWait} fetchTicketEvent={fetchTicketEvent}
                                           title={`대기 상태 시간 설정`}
                        />
                    </Panel>
                }
            </Panel>
        );
    }
}

export default TimePanel;