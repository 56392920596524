import React from 'react';
import _ from 'lodash';
import TitlePanelLabel from "../label/TitlePanelLabel";
import {ArrowRight2} from "iconsax-react";

class Frame extends React.Component{
    render() {
        let {children, className} = this.props
        return (
            <div className={`h-100 w-100 flex-default ${className ? className : ''}`} {..._.omit(this.props,[`className`])}>
                {children}
            </div>
        )
    }
}

export default Frame;
