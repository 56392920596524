import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ArrowLeft2, ArrowRight2} from 'iconsax-react';
import Styles from './DoubleArrowIcon.module.scss';
import {MdKeyboardDoubleArrowLeft} from "react-icons/md";
import _ from "lodash";

class DoubleArrowIcon extends Component {
    render() {
        const {collapsed, className} = this.props;
        return (
            <div className={`${Styles.DoubleArrowIcon} ${collapsed ? Styles.Collapsed : ''} ${className ? className : ''}`} {..._.omit(this.props, [`className`])}>
                <MdKeyboardDoubleArrowLeft  color={'#9199A1'}/>
            </div>
        );
    }
}

DoubleArrowIcon.propTypes = {
    collapsed: PropTypes.bool,
};

export default DoubleArrowIcon;
