import Dagre from '@dagrejs/dagre';

const position = { x: 0, y: 0 };
const NO_DATA = 'D0000';

export const flowIdExtract = (id) => {
  return id.split('_')[0];
};
export const flowUid = (arr) => {
  return arr.join('_');
};

export const SCRIPT_TYPE_ENUM = {
  S: { label: '시작', colorType: 'success' },
  E: { label: '종료', colorType: 'danger' },
  Q: { label: '질문', colorType: 'primary' },
  A: { label: '답변', colorType: 'info' },
};

function processNodeIds(allData, node, type) {
  const depth = +node.depth + (type === 'prevIds' ? -1 : +1);
  const reverseType = type === 'prevIds' ? 'nextIds' : 'prevIds';
  return allData.filter((item) => node[type].filter((v) => v !== NO_DATA).includes(item.currentIntentId) && +item.depth === depth && item[reverseType].includes(node.currentIntentId)).map((v) => v.id);
}

export function nodesLoader(nodesData) {
  const mergeNodes = nodesData.filter((arr, i, callback) => i === callback.findIndex((v) => v.currentIntentId === arr.currentIntentId && v.depth === arr.depth && (v.scriptType === 'A' ? v.prevIntentId === arr.prevIntentId : true)));

  const merges = mergeNodes.map((node) => {
    const currentNodes = nodesData.filter((edgeNode) => node.currentIntentId === edgeNode.currentIntentId && node.depth === edgeNode.depth && (node.scriptType === 'A' ? node.prevIntentId === edgeNode.prevIntentId : true));
    const prevIds = Array.from(new Set(currentNodes.map((v) => v.prevIntentId)));
    const nextIds = Array.from(new Set(currentNodes.map((v) => v.nextIntentId)));
    const id = flowUid([node.currentIntentId, prevIds?.join('-') ?? NO_DATA, nextIds?.join('-') ?? NO_DATA, node.depth]);
    return { ...node, id, prevIds, nextIds };
  });
  const nodes = merges.map((node) => {
    const { id, content, scriptType, currentIntentId, depth } = node;
    return {
      id,
      data: {
        content,
        scriptType,
        currentId: currentIntentId,
        prevIds: processNodeIds(merges, node, 'prevIds'),
        nextIds: processNodeIds(merges, node, 'nextIds'),
        depth,
      },
      type: 'custom',
    };
  });
  const edges = nodes.flatMap((node) =>
    node.data.nextIds.map((nextId, i) => {
      return { id: flowUid(['edges', node.id, nextId, i]), source: node.id, target: nextId, type: 'custom' };
    })
  );
  return { nodes, edges };
}

export function noedsSave(nodes) {
  const res = nodes.flatMap((node) => {
    const { depth, scriptType, currentId, prevIds, nextIds } = node.data;
    const edgeIds = combineArr(prevIds, nextIds);

    return edgeIds.map(({ prevId, nextId }) => ({
      depth,
      scriptType,
      currentIntentId: currentId,
      prevIntentId: prevId ? flowIdExtract(prevId) : NO_DATA,
      nextIntentId: nextId ? flowIdExtract(nextId) : NO_DATA,
    }));
  });
  return res;
}

const nodeWidth = 240;
const nodeHeight = 50;

export const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const dagreGraph = new Dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setDefaultNodeLabel(() => ({}));

  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    const textLength = node.data?.content?.length ?? 0;
    const height = textLength > 18 ? nodeHeight + Math.round(textLength / 18) * 24 : nodeHeight;
    const width = textLength > 10 ? nodeWidth : 140;

    dagreGraph.setNode(node.id, { width, height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  Dagre.layout(dagreGraph);

  const newPostionNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  return newPostionNodes;
};

export function findDepth(edges, id, currentDepth) {
  let depth = currentDepth || 1;
  let result = [{ id, depth }];

  const targets = edges.filter((item) => item.source === id).map((item) => item.target);

  targets.forEach((target) => {
    result.push(...findDepth(edges, target, depth + 1));
  });
  return result;
}

export function getStartNodes(nodes, edges) {
  return nodes.filter((node) => !edges.map((v) => v.target).includes(node.id));
}

export function getDepthNodes(nodes, edges) {
  const startNodeIds = getStartNodes(nodes, edges).map((node) => node.id);
  let depthNodeIds = [];
  nodes.forEach((node) => {
    if (startNodeIds.includes(node.id)) {
      depthNodeIds.push(...findDepth(edges, node.id));
    }
  });
  depthNodeIds = [...new Set(depthNodeIds.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));

  if (!depthNodeIds.length) return false;
  return nodes.map((item) => ({ ...item, data: { ...item.data, depth: depthNodeIds.find((v) => v.id === item.id).depth } }));
}

function combineArr(prevIds, nextIds) {
  if (!prevIds.length)
    return nextIds.map((nextId) => ({
      prevId: null,
      nextId,
    }));

  if (!nextIds.length)
    return prevIds.map((prevId) => ({
      prevId,
      nextId: null,
    }));

  return prevIds.flatMap((prevId) => nextIds.map((nextId) => ({ prevId, nextId })));
}

export function findDuplicateNodes(nodes) {
  const seen = {};
  return nodes.filter((node) => {
    const key = `${node.data.currentId}_${node.data.depth}`;
    const isDuplicate = node.data.scriptType === 'Q' && seen[key];
    seen[key] = true;
    return isDuplicate;
  });
}
