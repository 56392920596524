import React, { Component } from "react";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import axios from "axios";
import { action, computed, makeObservable, observable, values } from "mobx";
import ModalBase from "../../components/modal/ModalBase";
import Styles from "../team/Team.module.scss";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import AsyncSelect from "react-select/async";
import Avatar from "../../components/avatar/Avatar";
import appStore from "../../store/AppStore";

class TeamAgentDialogStore {
  @observable
  show = false;

  agent;

  teamId;

  onSave;

  @observable
  selectedRank;

  @observable
  isSaving = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setSelectedRank(rank) {
    this.selectedRank = rank;
  }

  @action.bound
  open({ agent, teamId, rank, onSave }) {
    this.agent = agent;
    this.teamId = teamId;
    this.selectedRank = rank ? { label: rank.label, value: rank.id } : null;
    this.onSave = onSave;
    this.show = true;
  }

  @action.bound
  close() {
    this.show = false;
    this.agent = null;
    this.teamId = null;
    this.onSave = null;
  }

  agentAdd = () => {
    if (!this.selectedRank) {
      NotificationManager.warning("직급을 선택해야 합니다.");
      return;
    }
    axios
      .post("/team/agent/add", {
        teamId: this.teamId,
        agentId: this.agent.id,
        rankId: this.selectedRank.value,
      })
      .then((res) => {
        this.onSave && this.onSave();
      })
      .finally(() => {
        this.close();
        this.isSaving = false;
      });
  };

  rankUpdate = () => {
    if (!this.selectedRank) {
      NotificationManager.warning("직급을 선택해야 합니다.");
      return;
    }
    axios
      .post("/agent/rank/update", {
        id: this.agent.id,
        rank: {
          id: this.selectedRank.value,
        },
      })
      .then((res) => {
        this.onSave && this.onSave();
      })
      .finally(() => {
        this.close();
        this.isSaving = false;
      });
  };
}

const teamAgentDialogStore = new TeamAgentDialogStore();
@observer
class MemberDialog extends Component {
  @observable
  rankMap = new Map();

  @computed
  get ranks() {
    return values(this.rankMap);
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  handleClose() {
    teamAgentDialogStore.close();
  }

  rankSearch = debounce((inputValue, callback) => {
    const filter =
      appStore.type === "SUPER"
        ? {
            teamId: teamAgentDialogStore?.agent?.team?.id,
          }
        : {};
    axios.post(`/rank/search`, filter).then((res) => {
      res.data &&
        callback(res.data.map((ps) => ({ label: ps.label, value: ps.id })));
    });
  }, 200);
  render() {
    const { show } = teamAgentDialogStore;
    return (
      <ModalBase show={show} size={"md"} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <div className={Styles.Header}>
            {teamAgentDialogStore.teamId ? "팀원 추가" : "직급 변경"}
          </div>
        </Modal.Header>
        <Modal.Body className={Styles.Body}>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>상담사</Form.Label>
            </Col>
            <Col md={10} className={"d-flex align-items-center"}>
              <div className={"me-1"} style={{ width: "3rem" }}>
                <Avatar
                  type={"agent"}
                  seed={teamAgentDialogStore?.agent?.id}
                  src={teamAgentDialogStore?.agent?.avatar?.fullUrl}
                />
              </div>
              <div className={Styles.Name}>
                {teamAgentDialogStore?.agent?.name}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className={`pb-3`}>
            <Col md={2}>
              <Form.Label style={{ whiteSpace: "pre-wrap" }}>직급</Form.Label>
            </Col>
            <Col md={10}>
              <AsyncSelect
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: "9999",
                  }),
                }}
                menuPortalTarget={document.body}
                cacheOptions
                loadOptions={this.rankSearch}
                value={teamAgentDialogStore.selectedRank}
                defaultOptions
                onChange={(e) => teamAgentDialogStore.setSelectedRank(e)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={teamAgentDialogStore.close}
          >
            닫기
          </Button>
          <Button
            variant="outline-primary"
            onClick={
              teamAgentDialogStore.teamId
                ? teamAgentDialogStore.agentAdd
                : teamAgentDialogStore.rankUpdate
            }
            disabled={teamAgentDialogStore.isSaving}
          >
            {teamAgentDialogStore.isSaving ? (
              <Spinner />
            ) : teamAgentDialogStore.teamId ? (
              "추가"
            ) : (
              "변경"
            )}
          </Button>
        </Modal.Footer>
      </ModalBase>
    );
  }
}

export { MemberDialog as default, teamAgentDialogStore };
