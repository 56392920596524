import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from "react-router-dom";
import BotMessageTemplateManageDetailView from "./BotMessageTemplateManageDetailView";
import BotMessageTemplateMangeListView from "./BotMessageTemplateManageListView";
import Frame from "../../components/layout/Frame";

@observer
class BotMessageTemplateManageFrame extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {location} = this.props;
        return (
            <Frame>
                <div className={`${location.pathname === '/main/bot/message/template/list' ? 'd-block' : 'd-none'} h-100`}>
                    <BotMessageTemplateMangeListView/>
                </div>
                <Switch>
                    <Route path="/main/bot/message/template/new" component={BotMessageTemplateManageDetailView}/>
                    <Route path="/main/bot/message/template/detail/:id" component={BotMessageTemplateManageDetailView}/>
                    <Redirect exact from="/main/bot/message/template/" to="/main/bot/message/template/list"/>
                </Switch>
            </Frame>
        );
    }
}

export default BotMessageTemplateManageFrame;
