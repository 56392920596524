import * as React from "react";

const SVGComponent = (props) => {
    const {size = 24, parentColorClassName, color=''} = props;
    return <svg
        id="ShopTalk"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        style={{
            enableBackground: "new 0 0 24 24",
            width: size,
            height: 'auto',
            fill:color,
            className: parentColorClassName,  //이부분의 스타일을 적용함 fill등..
        }}
        xmlSpace="preserve"
        {...props}
    >

        {/*--여기까지는 기본값--*/}

        <g>
            {/*여기아래 붙여넣기*/}
            <g>
                <path
                    d="M14.7,22.7H9.3c-4.9,0-7-2.1-7-7v-4.5c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.5c0,4.1,1.4,5.5,5.5,5.5h5.4 c4.1,0,5.5-1.5,5.5-5.5v-4.5c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.5C21.8,20.6,19.7,22.7,14.7,22.7z"
                />
                <path
                    d="M12,12.8c-1.1,0-2.1-0.4-2.8-1.2s-1-1.8-0.9-2.9L9,1.9c0-0.4,0.4-0.7,0.8-0.7h4.7c0.4,0,0.7,0.3,0.8,0.7 l0.7,6.7c0.1,1.1-0.2,2.1-0.9,2.9C14.1,12.3,13.1,12.8,12,12.8z M10.4,2.8l-0.6,6c-0.1,0.7,0.1,1.3,0.6,1.8c0.9,0.9,2.6,0.9,3.4,0 c0.4-0.5,0.6-1.1,0.6-1.8l-0.6-6C13.7,2.8,10.4,2.8,10.4,2.8z"
                />
                <path
                    d="M18.3,12.8c-2,0-3.8-1.6-4.1-3.7l-0.7-7c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.2,0.6-0.2h3.1 c2.9,0,4.3,1.2,4.7,4.2l0.3,2.8c0.1,1.2-0.2,2.3-1,3.2S19.5,12.8,18.3,12.8z M15.2,2.8l0.6,6.2c0.1,1.2,1.3,2.3,2.6,2.3 c0.8,0,1.4-0.3,1.9-0.8c0.5-0.5,0.7-1.2,0.6-2l-0.3-2.8c-0.3-2.3-1-2.9-3.2-2.9C17.4,2.8,15.2,2.8,15.2,2.8z M5.7,12.8 c-1.2,0-2.3-0.5-3-1.3c-0.8-0.9-1.1-2-1-3.1l0.3-2.8c0.4-3,1.8-4.3,4.7-4.3h3.1c0.2,0,0.4,0.1,0.6,0.2c0.1,0.2,0.2,0.4,0.2,0.6 l-0.7,7C9.5,11.1,7.7,12.8,5.7,12.8z M6.6,2.8c-2.2,0-2.9,0.7-3.2,2.9L3.1,8.4c-0.1,0.8,0.2,1.5,0.6,2s1.2,0.8,1.9,0.8 c1.3,0,2.4-1.1,2.5-2.3l0.6-6.2C8.8,2.8,6.6,2.8,6.6,2.8z"
                />
            </g>
            <g>
                <path
                    d="M8,15.4c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2C9.2,15.9,8.7,15.4,8,15.4z"
                />
                <path
                    d="M12,15.4c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2C13.2,15.9,12.7,15.4,12,15.4z"
                />
                <path
                    d="M16,15.4c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2C17.2,15.9,16.7,15.4,16,15.4z"
                />
            </g>
        </g>
    </svg>
};
export default SVGComponent;