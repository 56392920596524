import { Header } from '@qbot-chat/qbot-uikit';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { Input } from '../components/validation';
import SelectBox from '../components/input/SelectBox';

export function headerWidthPrint(min = 50, current = 100, max = 200) {
  return {
    min,
    current,
    max,
  };
}

export function headerPrint(data) {
  return data
    .flat()
    .filter((v) => !!v.headerProps)
    .sort((a, b) => (a.headerProps.sort ?? 0) - (b.headerProps.sort ?? 0))
    .map((v) => {
      return <Header key={v.name} id={v.name} name={v.label} {...v.headerProps} />;
    });
}

//TODO 컴포넌트로 이동 및 컴포넌트 명 변경
export function FormPrint({ form, data }) {
  if (!form || !data) return <></>;
  return data.map((row, i) => (
    <FormGroup as={Row} className='mb-3' key={i}>
      {(!Array.isArray(row) ? [row] : row).map((col) => {
        if (col.hidden) return;
        return (
          <Col key={col.name}>
            <FormLabel>{col.label}</FormLabel>
            {!col.options ? <Input store={form} name={col.name} as={col.as} validOnBlur {...col.props} /> : <SelectBox store={form} name={col.name} options={col.options} placeholder='' {...col.props} />}
          </Col>
        );
      })}
    </FormGroup>
  ));
}
