import React, {Component} from 'react';
import {AsyncPaginate} from "react-select-async-paginate";
import {components} from "react-select";
import {Image} from "react-bootstrap";
import RandomColor from "../avatar/RandomColor";
import DefaultAvatar from "../avatar/DefaultAvatar";
import axios from "axios";
import {observer} from "mobx-react";
import {computed, makeObservable, observable, values} from "mobx";
import Avatar from "../avatar/Avatar";

const AgentInfo = (props) => {
    return <div className={'d-flex flex-row m-2'}>
        <Avatar type={'agent'} src={props.agent?.avatar?.fullUrl} seed={props.agent.id} width={'1.5rem'}/>
        <div className={'ms-2 align-self-center'}> {props.agent.name} </div>
    </div>;
}
const AgentOption = (props) => {
    return (
        <components.Option {...props}  >
            <AgentInfo
                agent={props.data.object}
            />
        </components.Option>
    );
};
@observer
class AgentSelect extends Component {

    @observable
    agentMap = new Map();

    @computed
    get agents() {
        return values(this.agentMap)
    }

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentWillUnmount() {
        this.agentMap.clear();
    }
    agentSearch = async(search, loadedOptions) =>  {
        const {types, isNotTeamAgent} = this.props;
        const res = await axios.post(
            `/agent/infinite/list`,
            {
                keyword: search,
                lastRow: this.agents.length,
                types,
                isNotTeamAgent
            }
        )
        res.data.items.map(agent => this.agentMap.set(agent.id, agent));
        let options = this.agents.filter(ps => !Array.from(loadedOptions).find(x => x.value == ps.id))
            .map(ps => {
                return ({label: ps.name, value: ps.id, object: ps })
            });

        if(search) {
            options = res.data.items.map(ps => ({label: ps.name, value: ps.id, object: ps }));
        }
        return {
            options: options,
            hasMore: this.agents.length < res.data.count,
        };
    }

    render() {
        const {onChange} = this.props;
        return (
            <AsyncPaginate
                isClearable={true}
                components={{
                    Option : AgentOption,
                        ...this.props.otherComponents
                }}
                loadOptions={this.agentSearch}
                onInputChange={(e) => {
                    this.agentMap.clear();
                }}
                onChange={onChange}
                {...this.props}
            />
        );
    }
}

export default AgentSelect;