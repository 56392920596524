import React, {useEffect, useState} from 'react';
import { useCountUp } from 'react-countup';
import Styles from './Chart.module.scss'
const CountChart = ({data, title, size=36, decimals = 0, suffix=''}) => {

    useEffect(()=>{
        start()
    },[data]);

    const countUpRef = React.useRef(null);
    const [startCount, setStartCount] = useState(0);
    const { start } = useCountUp({
        ref: countUpRef,
        start:startCount,
        end:data,
        duration: 2,
        decimals,
        onEnd: ({ pauseResume }) => {
            setStartCount(data)
        },
    });
    return (
        <div className={Styles.Count}>
            <div className={Styles.Name}>
                {title}
            </div>
            <div className={`d-flex align-items-end justify-content-end`}>
                <div ref={countUpRef} style={{fontSize:size}}/>
                <span style={{fontSize:size-10}}>{suffix}</span>
            </div>
        </div>
    );
};

export {CountChart as default}
