import React from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";
import {InfoCircle} from "iconsax-react";

const InfoReport = (props) => {
    const {title, summary} = props;
    return (
        <div className={`d-flex`}>
            {title}
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props)=>
                    <Popover {...props}>
                        <Popover.Body>
                            {summary}
                        </Popover.Body>
                    </Popover>
                }
            >
                <div className={`d-flex align-items-center`}>
                    <InfoCircle size={16} color={`var(--bs-gray-500)`} style={{marginLeft: "1rem"}}/>
                </div>
            </OverlayTrigger>
        </div>
    );
};

export default InfoReport;