import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Panel from "../../components/layout/Panel";
import ResponsiveGridLayoutView from "../../components/layout/ResponsiveGridLayoutView";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import DoughnutChart from "../../components/chart/DoughnutChart";
import {computed, makeObservable, observable, toJS} from "mobx";
import axios from "axios";
import CountChart from "../../components/chart/CountChart";
import {Button, Col, Container, FormGroup, ProgressBar} from "react-bootstrap";
import {FaUser} from 'react-icons/fa'
import {ForwardItem, Lock, Lock1, LockSlash, MessageTick, Ticket, Ticket2, TicketExpired, User} from "iconsax-react";
import {DragItem} from "../../components/stats/DragItem";
import AgentStatus from "../../components/stats/AgentStatus";
import CountStatus from "../../components/stats/CountStatus";
import SubCountChart from "../../components/chart/SubCountChart";
import TimeCountChart from "../../components/chart/TimeCountChart";
import SubTimeCountChart from "../../components/chart/SubTimeCountChart";
import moment from 'moment';
import Styles from './DashboardFrame.module.scss';
import {DEFAULT_REAL_LAYOUTS} from "../../const/AppConst";
import TitlePanel from "../../components/layout/TitlePanel";
import TitlePanelLabel from "../../components/label/TitlePanelLabel";
import {navigate} from "../../helper/HistoryHelper";
import FrameWrap from "../../components/layout/FrameWrap";

ChartJS.register(ArcElement, Tooltip, Legend);

@observer
class RealTimeView extends Component {
    constructor(props) {
        super(props);
        makeObservable(this);
        this.load();
        let layouts = window.localStorage.getItem("REAL_LAYOUTS");
        let isLock = window.localStorage.getItem("REAL_LOCK");
        if(layouts != undefined && layouts != 'undefined'){
            this.layouts = JSON.parse(layouts);
        }else{
            this.layouts = DEFAULT_REAL_LAYOUTS;
        }
        if(isLock){
            if(isLock == 'true'){
                this.isLock = true;
            }else{
                this.isLock = false;
            }
        }else{
            this.isLock = false;
        }
    }

    componentDidMount() {
        this.intenrval = setInterval(()=> {
            this.load()
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.intenrval)
    }

    load(){
        axios.post(
            `/stats/real`
            ,{now:moment().startOf('day')}
        ).then(r=>{
            this.countStats = r.data.ticketCount;
            this.totalCount = r.data.ticketCount.total;
            this.agentStats = r.data.agentCount;
            this.respondSecond = r.data.respondSecond;
            this.botConsultingSecond = r.data.botConsultingSecond;
            this.agentConsultingSecond = r.data.agentConsultingSecond;
            this.waitSecond = r.data.waitSecond;
        })
    }

    @observable
    totalCount = 0;

    @observable
    respondSecond = {average : 0, min : 0, max : 0};

    @observable
    botConsultingSecond = 0;

    @observable
    agentConsultingSecond = {average : 0, min : 0, max : 0};

    @observable
    waitSecond = {average : 0, min : 0, max : 0};

    @observable
    countStats = {botConsulting : 0, agentConsulting : 0, closed : 0, wait : 0, botTotal:0, agentTotal:0, total:0, botClosed:0, agentClosed:0, consultingTotal:0};

    @observable
    agentStats = {total: 0, active : 0, offline : 0, away : 0, maxCapacity: 0};

    @computed
    get countData(){
        let data = this?.countStats;
        let {botConsulting = 0, agentConsulting = 0, closed = 0, wait = 0} = data;
        return ([
            {
                label: `상담중인 티켓 - 봇`,
                value: botConsulting
            },
            {
                label: `상담중인 티켓 - 상담원`,
                value: agentConsulting
            },
            {
                label: `완료된 티켓`,
                value: closed
            },
            {
                label: `대기중 티켓`,
                value: wait
            }
        ]);
    }

    @observable
    breakPoint = 'lg';

    @observable
    isLock = false;

    @observable
    layouts;

    setLayouts(layouts){
        this.layouts = layouts;
        window.localStorage.setItem("REAL_LAYOUTS", JSON.stringify(layouts));
    }

    toggleLock(){
        this.isLock = !this.isLock;
        window.localStorage.setItem("REAL_LOCK", this.isLock.toString());
    }

    render() {
        let layoutsObject = this.layouts;
        let layouts = layoutsObject[this.breakPoint];
        let isLock = this.isLock;
        return (
            <FrameWrap className={`d-flex flex-column flex-default overflow-y-auto user-select-none`}>
                <TitlePanel>
                    <FormGroup>
                        <Col className={`justify-content-between align-items-center d-flex`}>
                            <TitlePanelLabel label={'대시보드 - 실시간'}/>
                            <div>
                                {appStore.now.format('LLLL')}
                            </div>
                        </Col>
                    </FormGroup>
                </TitlePanel>
                <Panel className={'d-flex justify-content-end pb-0'}>
                    <div className={'d-flex flex-row gap-2'}>
                        <Button variant={`outline-secondary`} disabled={isLock} onClick={()=>this.setLayouts(DEFAULT_REAL_LAYOUTS)}><span className={`d-flex align-items-center`}><ForwardItem size={16} className={`me-2`}/>레이아웃 초기화</span></Button>
                        <Button variant={`outline-primary`} onClick={()=>this.toggleLock()}><span className={`d-flex align-items-center`}>{isLock?<LockSlash size={16} className={`me-2`}/>:<Lock1 size={16} className={`me-2`}/>}{isLock ? `변경 잠금 해제`:`변경 잠금`}</span></Button>
                    </div>
                </Panel>
                <Panel>

                    <ResponsiveGridLayoutView layouts={layoutsObject}
                                              margin={[16,16]}
                                              containerPadding={[0,0]}
                                              isBounded={true}
                                              preventCollision={true}
                                              allowOverlap={true}
                                              isDraggable={!isLock}
                                              isResizable={!isLock}
                                              isLock={isLock}
                                              onLayoutChange={(layouts, responsiveLayouts) => {
                                                  this.setLayouts(responsiveLayouts)
                                              }}
                                              onBreakpointChange={(breakPoint, size)=>{
                                                  this.breakPoint = breakPoint;
                                              }}
                    >
                        <DragItem className={Styles.Border} key={'count'} id={'count'} layouts={layouts} isBounded={true} >
                            <DoughnutChart title={`실시간 상담 현황`} id={`count`}
                                           total={this.totalCount}
                                           legendContainer={
                                                <div className={`d-flex flex-column justify-content-around h-100`}>
                                                    <div className={Styles.ChartBigCount}>
                                                        <div className={Styles.Count}>
                                                            {
                                                                this.countStats.consultingTotal > 0 ?
                                                                    Math.round(this.countStats.agentConsulting/this.countStats.consultingTotal*100)
                                                                    :
                                                                    0

                                                            }
                                                            %
                                                        </div>
                                                        <div className={Styles.Description}>
                                                            실시간 상담 전환률
                                                        </div>
                                                    </div>
                                                    <div id={'count'} className={`mb-3`}>

                                                    </div>
                                                </div>
                                           }
                                           data={this.countData}
                            />
                        </DragItem>
                        <DragItem className={Styles.Border} key={'agent'} id={'count'} layouts={layouts} isBounded={true} >
                            <AgentStatus active={this.agentStats.active}
                                         offline={this.agentStats.offline}
                                         away={this.agentStats.away}
                                         total={this.agentStats.total}
                            />
                        </DragItem>
                        <DragItem key={`agentTotal`} id={'agentTotal'} layouts={layouts}>
                            <CountStatus icon={<Ticket size={32}/>}>
                                <CountChart title={`최대 수용 티켓 - 상담원`} data={this.agentStats.maxCapacity}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem key={`agentConsulting`} id={'agentConsulting'} layouts={layouts}>
                            <CountStatus icon={<MessageTick size={32}/>} variant={'info'}>
                                <CountChart title={`사용중 티켓 - 상담원`} data={this.countStats.agentConsulting}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem key={`agentClosed`} id={'agentClosed'} layouts={layouts}>
                            <CountStatus icon={<TicketExpired size={32}/>} variant={'success'}>
                                <CountChart title={`완료된 티켓 - 상담원`} data={this.countStats.agentClosed}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem key={`botClosed`} id={'botClosed'} layouts={layouts}>
                            <CountStatus icon={<Ticket2 size={32}/>} variant={'warning'}>
                                <CountChart title={`완료된 티켓 - 봇`} data={this.countStats.botClosed}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem className={Styles.Border} key={`changeRate`} id={'changeRate'} layouts={layouts}>
                            <CountStatus icon={<Ticket2 size={32}/>} variant={'primary'} isReverse={true}>
                                <CountChart title={`상담 전환률 - 완료티켓`} data={(this.countStats.agentClosed/this.countStats.closed*100).toFixed(1)} decimals={1} suffix={'%'}/>
                                <SubCountChart title={`상담원`} data={this.countStats.agentClosed} size={16}/>
                                <SubCountChart title={`봇`} data={this.countStats.botClosed} size={16}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem className={Styles.Border} key={`agentConsultingTime`} id={'agentConsultingTime'} layouts={layouts}>
                            <CountStatus icon={<Ticket2 size={32}/>} variant={'info'} isReverse={true}>
                                <TimeCountChart title={`평균 상담 시간 - 상담사`} data={this.agentConsultingSecond.average}/>
                                <SubTimeCountChart title={`최소`} data={this.agentConsultingSecond.min} size={16}/>
                                <SubTimeCountChart title={`최대`} data={this.agentConsultingSecond.max} size={16}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem className={Styles.Border} key={`respondTime`} id={'respondTime'} layouts={layouts}>
                            <CountStatus icon={<Ticket2 size={32}/>} variant={'success'} isReverse={true}>
                                <TimeCountChart title={`평균 상담원 반응 시간`} data={this.respondSecond.average}/>
                                <SubTimeCountChart title={`최소`} data={this.respondSecond.min} size={16}/>
                                <SubTimeCountChart title={`최대`} data={this.respondSecond.max} size={16}/>
                            </CountStatus>
                        </DragItem>
                        <DragItem className={Styles.Border} key={`assignmentWaitTime`} id={'assignmentWaitTime'} layouts={layouts}>
                            <CountStatus icon={<Ticket2 size={32}/>} variant={'warning'} isReverse={true}>
                                <TimeCountChart title={`평균 고객 대기 시간`} data={this.waitSecond.average}/>
                                <SubTimeCountChart title={`최소`} data={this.waitSecond.min} size={16}/>
                                <SubTimeCountChart title={`최대`} data={this.waitSecond.max} size={16}/>
                            </CountStatus>
                        </DragItem>
                        {/*<DragItem key={`botConsultingTime`} id={'count'} layouts={layouts}><CountChart title={`평균 상담 시간 - 봇`} data={this.botConsultingSecond}/></DragItem>*/}
                    </ResponsiveGridLayoutView>
                </Panel>
            </FrameWrap>
        );
    }
}

export default RealTimeView;
