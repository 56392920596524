import React from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import StatusBar from '../components/layout/StatusBar';
import SideBar from '../components/layout/menu/SideBar';
import appStore from '../store/AppStore';
import { HISTORY } from '../helper/HistoryHelper';
import ChatView from './ChatView';
import { ChannelDropDown, Chat, USER_TYPE } from '@qbot-chat/qbot-uikit/chat';
import { channelStore, chatStore } from '../store/AdminChatStore';
//import { onMessageListener, requestForToken } from "../firebase";
import axios from 'axios';
import Styles from './MainViewFrame.module.scss';
import DragLayerPopup from '../components/layout/DragLayerPopup';
import QuickMsgModal from './quickmsg/modal/QuickMsgModal';
import UploadDialog, { uploadStore } from '../components/dialog/UploadDialog';
import { ACCESS } from '../const/AppConst';
import TicketManageFrame from './ticket/TicketManageFrame';
import AgentInfoChangeView from './AgentInfoChangeView';
import AgentPasswordChangeView from './AgentPasswordChangeView';
import QuickMsgFrame from './quickmsg/QuickMsgFrame';
import RoadShowView from './roadshow/RoadShowView';
import BranchMemoView from './memo/BranchMemoView';
import DashboardFrame from './dashboard/DashboardFrame';
import ReportView from './report/ReportView';
import Monitor from './monitor/Monitor';
import AgentMangeFrame from './agent/AgentManageFrame';
import TeamManageFrame from './team/TeamManageFrame';
import SettingManageFrame from './setting/SettingManageFrame';
import BotMessageTemplateManageFrame from './message_template/BotMessageTemplateManageFrame';
import AdminManageFrame from './admin/AdminManageFrame';
import { CustomHeader } from '../components/chat/CustomHeader';
import MemberManageFrame from './member/MemberManageFrame';
import CompanyFrame from './company/CompanyFrame';
import ScenarioFrame from './scenario/ScenarioFrame';
import CallFrame from './call/CallFrame';
import ObSettingFrame from './ob_setting/ObSettingFrame';
import Frame from '../components/layout/Frame';
import FrameWrap from '../components/layout/FrameWrap';
import TitlePanel from '../components/layout/TitlePanel';
import TitlePanelLabel from '../components/label/TitlePanelLabel';
import StandardScenarioFrame from './standard_scenario/StandardScenarioFrame';
import StandardChatFrame from './standard_chat/StandardChatFrame';
import AnnotationFrame from './annotation/AnnotationFrame';
import NoticeFrame from "./notice/NoticeFrame";

const modalRoot = document.getElementById('modal');
const DragLayerPortal = ({ children }) => ReactDOM.createPortal(children, modalRoot);

@observer
class MainViewFrame extends React.Component {
  componentDidMount() {
    let store = this;
    // if (
    //   appStore.isLogin &&
    //   [USER_TYPE.AGENT, USER_TYPE.AGENT_LEADER].includes(appStore.type)
    // ) {
    //   requestForToken &&
    //     requestForToken()
    //       .then((currentToken) => {
    //         if (currentToken) {
    //           console.log("current token for client: ", currentToken);
    //           axios.post("/user/registration-push-token", {
    //             token: currentToken,
    //             type: "WEB",
    //           });
    //         } else {
    //           console.log(
    //             "No registration token available. Request permission to generate one."
    //           );
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("An error occurred while retrieving token. ", err);
    //       });
    //   onMessageListener &&
    //     onMessageListener()
    //       .then((payload) => {})
    //       .catch((err) => console.log("failed: ", err));
    // }

    appStore.loadBranches();
    // appStore.loadOrgTree();
    // appStore.loadCategories();
    appStore.loadBranches();
    appStore.loadBrands();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (appStore.currentPath != HISTORY.location.pathname) {
      appStore.currentPath = HISTORY.location.pathname;
    }
  }

  componentWillUnmount() {}

  MENU_ROUTE = {
    COMPANY: <Route path='/main/company' component={CompanyFrame} />,
    CHAT: <Route path='/main/chat' component={ChatView} />,
    TICKET: <Route path='/main/ticket' component={TicketManageFrame} />,
    PROFILE: <Route path='/main/profile' component={AgentInfoChangeView} />,
    PASSWORD: <Route path='/main/password' component={AgentPasswordChangeView} />,
    QUICK: <Route path='/main/quickmsg' component={QuickMsgFrame} />,
    ROAD: <Route path='/main/roadshow' component={RoadShowView} />,
    BRANCH: <Route path='/main/branch-memo' component={BranchMemoView} />,
    DASH: <Route path='/main/dashboard' component={DashboardFrame} />,
    REPORT: <Route path='/main/report' component={ReportView} />,
    MONITOR: <Route path='/main/monitor' component={Monitor} />,
    TEAM_AGENT: <Route path='/main/member' component={MemberManageFrame} />,
    AGENT: <Route path='/main/agent' component={AgentMangeFrame} />,
    TEAM: <Route path='/main/team' component={TeamManageFrame} />,
    SETTING: <Route path='/main/setting' component={SettingManageFrame} />,
    BOT_MESSAGE: <Route path='/main/bot/message/template/' component={BotMessageTemplateManageFrame} />,
    ADMIN: <Route path='/main/admin' component={AdminManageFrame} />,
    SCENARIO: <Route path='/main/scenario' component={ScenarioFrame} />,
    OUTBOUND_CALL: <Route path='/main/call' component={CallFrame} />,
    OUTBOUND_SETTING: <Route path='/main/ob-setting' component={ObSettingFrame} />,
    STANDARD_SCENARIO: <Route path='/main/standard-scenario' component={StandardScenarioFrame} />,
    STANDARD_CHAT: <Route path='/main/standard-chat' component={StandardChatFrame} />,
    ANNOTATION: <Route path='/main/annotation' component={AnnotationFrame} />,
    NOTICE: <Route path="/main/notice" component={NoticeFrame} />,
  };
  renderMainMenu(type) {
    return (
      <Switch>
        {ACCESS[type].map((menu) => this.MENU_ROUTE[menu])}
        {
          {
            [USER_TYPE.AGENT]: <Redirect exact from='/main' to='/main/chat' />,
            [USER_TYPE.AGENT_LEADER]: <Redirect exact from='/main' to='/main/chat' />,
            [USER_TYPE.ADMIN]: <Redirect exact from='/main' to='/main/dashboard' />,
            [USER_TYPE.SUPER]: <Redirect exact from='/main' to='/main/company' />,
          }[type]
        }
      </Switch>
    );
  }

  render() {
    return (
      <div className={`d-flex flex-default flex-row`}>
        {appStore.isSessionChecked && !appStore.isLogin && <Redirect to={`/`} />}
        <SideBar />
        <div className='d-flex flex-default flex-column'>
          <StatusBar />
          <div className={`d-flex flex-default ${Styles.Content}`}>{appStore?.type && this.renderMainMenu(appStore?.type)}</div>
        </div>

        <ChannelDropDown channelStore={channelStore} chatStore={chatStore} />
        <DragLayerPortal>
          {chatStore.channelId && !appStore.currentPath.startsWith(`/main/chat`) && (
            <DragLayerPopup
              title={'진행중인 상담'}
              maxHeight={700}
              maxWidth={400}
              onClose={() => {
                chatStore.setChannel(null);
              }}
            >
              <Chat
                store={chatStore}
                ref={chatStore.chatRef}
                isImageSendAble={true}
                isImageSendMsg={true}
                renderCustomHeader={(store) => <CustomHeader store={store} />}
                onCloseCallback={() => {
                  chatStore.setChannel(null);
                }}
              />
            </DragLayerPopup>
          )}
        </DragLayerPortal>
        {appStore.chatReady && <QuickMsgModal store={chatStore} />}
        <UploadDialog
          store={uploadStore}
          /*avatarPreview={(setAvatarPreview)=>{

                    }}*/
        />
      </div>
    );
  }
}

export default MainViewFrame;
