import React, {Component} from 'react';
import Styles from './Menu.module.scss';
import PropTypes from 'prop-types';
import {ArrowRight2} from 'iconsax-react';
import {MdKeyboardArrowRight} from "react-icons/md";
import {navigate} from '../../../helper/HistoryHelper';
import {observer} from 'mobx-react';
import _ from "lodash";

@observer
class Menu extends Component {
    render() {
        const {subMenu = false, collapsed, icon, label, to, className} = this.props;
        const isActive = to === '/' ? appStore.currentPath === '/' : appStore.currentPath.startsWith(to);

        return (
            <div className={`${className ? className : ''} ${Styles.Menu} ${isActive ? Styles.Active: ''} ${collapsed? Styles.Collapsed:''}`}
                 {..._.omit(this.props, [`className`])}

                 onClick={()=>{
                     appStore.currentPath = to;
                     navigate(to)
                 }}
            >
                <div>
                    {icon}
                </div>
                <div className={Styles.MenuTitle}>
                    {label}
                </div>
                <div className={subMenu?'d-felx' :'d-none'}>
                    <MdKeyboardArrowRight size={'1rem'}/>
                </div>
            </div>
        );
    }
}

Menu.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string,
};

export default Menu;
