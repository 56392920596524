import React from 'react';
import {observer} from "mobx-react";
import Styles from '../ContentEditor.module.scss'
import {Button, Col, Form, FormControl, FormGroup, InputGroup, Row} from "react-bootstrap";
import Input from "../../validation/Input";
import {IoCloseCircleOutline} from "react-icons/io5";
import axios from "axios";
import {APP_CONTEXT_PATH} from "../../../const/AppConst";
import {CONTENT_TYPE} from "@qbot-chat/qbot-uikit";

@observer
class FileEditor extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            isError: false,
            isUrl: false,
        };
    }

    render() {
        let {type, value, idx, onChangeItem} = this.props;
        let {name, size, url} = value;
        return(
           <div>
               <input className={`d-none`} type={`file`}
                      ref={ref=>this.ref=ref}
                      onChange={(e)=>{
                          let file = e.target.files[0];
                          if(file){
                              const formData = new FormData();
                              formData.append("file", file)
                              axios.post(`/upload/draft`,
                                  formData
                              ).then((res) => {
                                  let {url, name, size} = res.data;
                                  this.ref.value = '';
                                  onChangeItem && onChangeItem({type, value: {name, size, url, fullUrl:`${APP_CONTEXT_PATH}/upload/${url}/download`}}, idx);
                              }).catch(e=>{
                                  this.ref.value='';
                              })
                          }
                      }}
               />
               <div className={`d-flex justify-content-between`}>
                   <div className={`d-flex flex-grow-1`}>
                       <InputGroup size={'sm'} className={`justify-content-end align-items-center`}>
                           <FormControl placeholder={``} value={name}
                                        disabled={true}
                                        onChange={(e)=>{
                                            let url = e.target.value;
                                            let name = e.target.value;
                                            onChangeItem && onChangeItem({type, value: {name, url}, isInvalid: !url}, idx)
                                        }}
                                        isInvalid={!url}
                           />
                           {
                               url &&
                               <div className={`d-flex position-absolute me-2`}
                                    style={{cursor:'pointer', zIndex:3}}
                                    onClick={()=>{
                                        onChangeItem && onChangeItem({type, value: {name:'', size:'', url:'', fullUrl:''}, isInvalid :true}, idx);
                                    }}
                               >
                                   <IoCloseCircleOutline/>
                               </div>
                           }
                           <Form.Control.Feedback type="invalid" className={`text-left`}>
                               파일을 등록해 주세요.
                           </Form.Control.Feedback>
                       </InputGroup>
                   </div>
                   <div className={`ms-3`}>
                       <Button variant={"secondary"} size={`sm`} onClick={()=>{this.ref.click()}}>파일열기</Button>
                   </div>
               </div>
           </div>
        );
    }
}

export default FileEditor;