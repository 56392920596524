import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Frame from '../../components/layout/Frame';
import StandardScenarioList from './StandardScenarioList';

const StandardScenarioFrame = (props) => {
  return (
    <Frame>
      <Switch>
        <Route path='/main/standard-scenario/list' component={StandardScenarioList} />
        <Redirect exact from='/main/standard-scenario' to='/main/standard-scenario/list' />
      </Switch>
    </Frame>
  );
};

export default StandardScenarioFrame;
