import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from '../SettingManageView.module.scss';
import {
    Alert,
    Button,
    Col,
    Row,
    Collapse,
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Nav,
    OverlayTrigger,
    Tooltip, Popover
} from "react-bootstrap";
import {FormStore} from "../../../components/validation";
import axios from "axios";
import {CONTENT_TYPE} from "@qbot-chat/qbot-uikit/chat";
import {NotificationManager} from "react-notifications";
import {TimePicker} from 'antd';
import dayjs from 'dayjs';
import {InfoCircle} from "iconsax-react";
import {MentionInput} from "@qbot-chat/qbot-uikit/component";

const duration = require('dayjs/plugin/duration')
dayjs.extend(duration);

const format = 'mm분ss초';

@observer
class TicketEventEditor extends Component {

    constructor(props) {
        super(props);
        let {ticketEvent} = this.props;
        let value = ticketEvent?.content?.value;
        let duration = ticketEvent?.duration;

        this.state = {
            value,
            duration,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(this.props.ticketEvent, prevProps.ticketEvent)){
            let {ticketEvent} = this.props;
            let value = ticketEvent?.content?.value;
            let duration = ticketEvent?.duration;
            this.setState({
                value, duration
            })
        }
    }

    render() {
        let {ticketEvent, fetchTicketEvent, timeLabel = `대기시간`, textLabel = `메시지`, title = '제목', badge, isTime = true, options = []} = this.props;
        let value = ticketEvent?.content?.value;
        let duration = ticketEvent?.duration;
        let isSame = (value == this.state?.value) && (duration == this.state?.duration);
        return (
            <div className={Styles.TicketEventEditor}>
                <div className={`${Styles.Title} ${Styles.Label}`}>
                    <FormGroup as={Row} className={`w-100`}>
                        <Col md={8}>
                            <div className={`d-flex`}>
                                {title}
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}

                                    overlay={(props)=>
                                        <Popover {...props}>
                                            <Popover.Body>
                                                <InfoCircle color={`var(--bs-info)`} className={`me-2`}/>{ticketEvent?.summary}
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <div className={`d-flex align-items-center`}>
                                        <InfoCircle size={16} color={`var(--bs-gray-500)`} className={Styles.Info} />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </Col>
                        <Col md={4}>
                            {
                                badge
                            }
                        </Col>
                    </FormGroup>
                </div>
                <div className={Styles.EditorContainer}>
                    {
                        isTime &&
                        <FormGroup className={Styles.SubBorder} as={Row}>
                            <Col md={4} >
                                <FormLabel className={Styles.Label}>
                                    시간 설정
                                </FormLabel>
                            </Col>
                            <Col md={8}>
                                <TimePicker format={format} showNow={false}
                                            value={this.state.duration ? dayjs(dayjs.duration(this.state.duration)?.format('mm:ss'), format):null}
                                            defaultValue={dayjs(dayjs.duration(duration)?.format('mm:ss'), format)}
                                            onChange={(time, string)=>{
                                                if(time){
                                                    this.setState({duration:dayjs.duration({minutes:time.minute(), seconds:time.second()}).toISOString()})
                                                }else{
                                                    this.setState({duration:null})
                                                }
                                            }}
                                            placeholder={'대기 시간'}

                                />
                            </Col>
                        </FormGroup>
                    }
                    <FormGroup as={Row}>
                        <Col md={4}>
                            <FormLabel className={Styles.Label}>
                                메시지 설정
                            </FormLabel>
                        </Col>
                        <Col md={8}>

                            <MentionInput value={this.state?.value}
                                          onChange={(value)=>{this.setState({value})}}
                                          options={options}
                                          renderHighlight={(id, display)=>{
                                              return (options.find(option=>option.id === id))?.label || ''
                                          }}
                            />

                            {
                                !this.state.value &&
                                    <div className={Styles.FeedbackMessage}>
                                        메시지를 입력하세요.
                                    </div>
                            }
                        </Col>
                    </FormGroup>
                </div>
                <Collapse in={!isSame}>
                    <div className={`${Styles.ButtonContainer}`}>
                        <Button variant={"outline-secondary"} className={Styles.Button} onClick={()=>this.setState({value, duration})}>취소</Button>
                        <Button variant={"outline-primary"}  className={Styles.Button} disabled={!this.state.value}
                                onClick={()=>{
                                    axios.post(
                                        `/ticket/event/update`,
                                        {
                                            ...ticketEvent,
                                            content:{
                                                type:CONTENT_TYPE.TEXT,
                                                value:this.state.value
                                            },
                                            duration:this.state.duration
                                        }
                                    ).then(r=>{
                                        fetchTicketEvent();
                                        NotificationManager.success(`업데이트 완료`);
                                    })
                                }}
                        >
                            저장
                        </Button>
                    </div>
                </Collapse>
            </div>
        );
    }
}

export default TicketEventEditor;